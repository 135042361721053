import React, { useEffect, useMemo, useState } from "react";
import "./tracking.css";
import { useParams } from "react-router";
import { getTrackById } from "../../Services/orderService";
import { t } from "i18next";

const Traking = () => {
  const [orderData, setOrderData] = useState();
  const { trackingId } = useParams();
  const [collapseRemitente, setCollapseRemitente] = useState(true);
  const [collapseRecipiente, setCollapseRecipiente] = useState(true);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const order = await getTrackById(trackingId);
        console.log(order.data);
        setOrderData(order.data);
        console.log(order.data)
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchOrder();
  }, []);

  function formatearFecha(fechaString) {
    if (fechaString !== undefined) {
      // Verificar si la cadena de fecha incluye información de hora
      const tieneHora = fechaString.includes("T");

      // Crear un objeto de fecha a partir de la cadena de fecha
      const fecha = tieneHora
        ? new Date(fechaString)
        : new Date(fechaString + "T00:00:00Z");

      // Obtener las partes de la fecha en formato local
      const nombreMeses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      const nombreMes = nombreMeses[fecha.getMonth()];
      const dia = fecha.getDate();
      const año = fecha.getFullYear();

      // Construir la fecha formateada
      const fechaFormateada = `${nombreMes} ${dia}, ${año}`;

      return fechaFormateada;
    }
  }
  function formatearFechaYHora(fechaString) {
    if (fechaString !== undefined ) {
      const fecha = new Date(fechaString);
      const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      
      const dia = fecha.getUTCDate();
      const mes = meses[fecha.getUTCMonth()];
      const año = fecha.getUTCFullYear();
      const hora = fecha.getUTCHours();
      const minutos = fecha.getUTCMinutes();
      const ampm = hora >= 12 ? 'pm' : 'am';
      const hora12 = hora % 12 || 12;

      const horaFormateada = `${hora12}:${minutos < 10 ? '0' : ''}${minutos} ${ampm}`;

      return `${mes} ${dia}, ${año} ${horaFormateada}`;
    }
  }
  const totalWeight = useMemo(() => {
    if (orderData) {
      const total = orderData?.boxes?.reduce((total, item) => {
        return total + parseFloat(item.box_weight ?? 0);
      }, 0);

      if (total === 0) {
        return "Sin datos";
      } else {
        return `${total} Kg`;
      }
    } else {
      return "Sin datos";
    }
  }, [orderData]);
  const lastStatus = useMemo(() => {
    if (orderData) {
      const array = orderData?.history_status;
      const last = array[array.length - 1];
      return last?.status?.status_name;
    } else {
      return "Sin datos";
    }
  }, [orderData]);
  return (
    <div className="trackinPage">
      <div className="container">
        <div className="container-tracking">
          <div className="rastreo">
            <small>{t('tracking.title')}:</small>
            <span>{" "}{trackingId}</span>
          </div>
          <div className="rastreo-info row pt-3">
            {orderData ? (
              <>
                <div className="col-md-7 pl-4">
                  {false && (
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                            className={`accordion-button ${
                              collapseRemitente ? "collapsed" : ""
                            }`}
                            onClick={() =>
                              setCollapseRemitente(!collapseRemitente)
                            }
                          >
                            Remitente
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          className={`accordion-collapse ${
                            collapseRemitente ? "collapse" : "visible"
                          }`}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <table className="table table-striped table-bordered table-remitente">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Fecha de arribo</b>
                                  </td>
                                  <td>
                                    {orderData?.arrival_date
                                      ? formatearFecha(orderData?.arrival_date)
                                      : "Sin datos"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Fecha estimada de entrega</b>
                                  </td>
                                  <td>
                                    {orderData?.delivery_date
                                      ? formatearFecha(orderData?.delivery_date)
                                      : "Sin datos"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Número de guía</b>
                                  </td>
                                  <td>{orderData?.bl_guia}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Carrier</b>
                                  </td>
                                  <td>{orderData?.carrier}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Referencia del cliente</b>
                                  </td>
                                  <td>{orderData?.client_reference}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>No. de bultos</b>
                                  </td>
                                  <td>--</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Peso</b>
                                  </td>
                                  <td>
                                    {isNaN(+orderData?.boxes[0]?.box_weight)
                                      ? 0
                                      : +orderData?.boxes[0]?.box_weight}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Largo x Ancho x Alto</b>
                                  </td>
                                  <td>
                                    {isNaN(+orderData?.boxes[0]?.box_length)
                                      ? 0
                                      : +orderData?.boxes[0]?.box_length}{" "}
                                    x{" "}
                                    {isNaN(+orderData?.boxes[0]?.box_width)
                                      ? 0
                                      : +orderData?.boxes[0]?.box_width}{" "}
                                    x{" "}
                                    {isNaN(+orderData?.boxes[0]?.box_height)
                                      ? 0
                                      : +orderData?.boxes[0]?.box_height}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                            className={`accordion-button ${
                              collapseRecipiente ? "collapsed" : ""
                            }`}
                            onClick={() =>
                              setCollapseRecipiente(!collapseRecipiente)
                            }
                          >
                            Recipiente
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          className={`accordion-collapse ${
                            collapseRecipiente ? "collapse" : "visible"
                          }`}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body"></div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <table className="table table-striped table-bordered table-remitente">
                      <tbody>
                        <tr>
                          <td>
                            <b>{t('tracking.table.arrival')}</b>
                          </td>
                          <td>
                            {orderData?.arrival_date
                              ? formatearFecha(orderData?.arrival_date)
                              : "Sin datos"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>{t('tracking.table.guia')}</b>
                          </td>
                          <td>{orderData?.bl_guia || "Sin datos"}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>{t('tracking.table.carrier')}</b>
                          </td>
                          <td>{orderData?.carrier || "Sin datos"}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>{t('tracking.table.proforma')}</b>
                          </td>
                          <td>{orderData?.proforma || "Sin datos"}</td>
                        </tr>

                        <tr>
                          <td>
                            <b>{t('tracking.table.reference')}</b>
                          </td>
                          <td>{orderData?.client_reference || "Sin datos"}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>{t('tracking.table.number')}</b>
                          </td>
                          <td>{orderData?.boxes?.length}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>{t('tracking.table.total')}</b>
                          </td>
                          <td>
                            <p className="pt-1 mb-0 pb-0">{totalWeight}</p>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <b>{t('tracking.table.invoice')}</b>
                          </td>
                          <td>
                            {orderData?.invoices?.file_galleries?.map(
                              (file, index) => (
                                <p>
                                  <a
                                    href={file.url}
                                    target="blank"
                                    style={{
                                      textDecoration: "none",
                                      color: "#1A1449",
                                    }}
                                  >
                                    <b>{t('tracking.table.download')}</b>
                                  </a>{" "}
                                  {file.name || "Factura"}
                                </p>
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>{t('tracking.table.file')}</b>
                          </td>
                          <td>
                            {orderData?.documents?.file_galleries?.map(
                              (file, index) => (
                                <p>
                                  <a
                                    href={file.url}
                                    target="blank"
                                    style={{
                                      textDecoration: "none",
                                      color: "#1A1449",
                                    }}
                                  >
                                    <b>{t('tracking.table.download')}</b>
                                  </a>{" "}
                                  {file.name || "Documento"}
                                </p>
                              )
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-5 pb-2">
                  <span className="titulo-estados">
                  {t('tracking.table.state')}
                  </span>
                  <div className="card ">
                    <div className="card-body">
                      <span className="estado-fecha">
                      {t('tracking.table.arrivalDate')}{" "}
                        {orderData?.delivery_date
                          ? formatearFecha(orderData?.delivery_date)
                          : "Sin datos"}
                      </span>
                      <div className="group-status">
                        {orderData?.history_status?.map(
                          (estado, index, array) => {
                            return (
                              <div className="status">
                                <div>
                                  <i className="fa-solid fa-circle-check">
                                    <span
                                      className={index === 0 ? "" : "linea-sup"}
                                    ></span>
                                    <span
                                      className={
                                        index === array.length - 1
                                          ? ""
                                          : "linea"
                                      }
                                    ></span>
                                  </i>
                                </div>
                                <div className="detail-status">
                                  <small>
                                    {formatearFechaYHora(estado?.created_at)}
                                  </small>
                                  <span>{estado?.status?.status_name}</span>
                                  <small className="status-note">{estado?.note}</small>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <span className="w-100 text-center p-5">
                {t('tracking.noResult')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Traking;
