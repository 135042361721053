import React from 'react'
import './politicas.css'
import { t } from 'i18next'

const PoliticaDeDevolucionesYReembolsos = () => {
  return (
    <div className="politicsPage">
    <div className="slider">
    <h1>{t('refund.title')}</h1>
      <span>{t('refund.breadcrumb.prev')} {'>'} {t('refund.breadcrumb.section')} {'>'} {t('refund.breadcrumb.name')}</span>
    </div>
    <div className="politicas">
        <div className="container">
        <div className="politicas-contenido" dangerouslySetInnerHTML={{ __html: t('refund.content') }} />
        </div>
    </div>
  </div>
  )
}

export default PoliticaDeDevolucionesYReembolsos