import React from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Card from "./Card";
const CardSlider = () => {
  const testimonios = [
    {
      comentario: "Atención eficaz y oportuna, excelente servicio al cliente.",
      imagen: "",
      nombre: "FHERCAH",
      cargo: "Marketer",
    },
    {
      comentario:
        "Tiempos de entrega oportunos, así como perfecto trato a la mercancía.",
      imagen: "",
      nombre: "J.M.Maquilatext",
      cargo: "Marketer",
    },
    {
      comentario:
        "Especial trato de mercancía perecedera desde la productora hasta el lugar de entrega, unidades refrigeradas en perfecto estado.",
      imagen: "",
      nombre: "Productora de Mangos Ojo de Agua",
      cargo: "Marketer",
    },
  ];
  return (
    <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={{
          nextEl: ".js-accomodations-next",
          prevEl: ".js-accomodations-prev",
        }}
        modules={[Navigation, Pagination]}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        breakpoints={{
          500: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
          1024: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 3,
          },
        }}
      >
        {testimonios.map((item, index) => (
          <SwiperSlide key={index}>
            <Card item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-btn">
        <button className="section-slider-nav  -prev flex-center button  shadow-1 w-12 h-12 rounded-full sm:d-none js-accomodations-prev">
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <button className="section-slider-nav -next flex-center button  shadow-1 w-12 h-12 rounded-full sm:d-none js-accomodations-next">
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </>
  );
};

export default CardSlider;
