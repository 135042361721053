import { api } from './api';

export const getCountries = async (sessionToken) => {
    try {
      const response = await api.get('/countrys', {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Error fetching countries data');
    }
  };

export const getCitiesByCountry = async (countryId,sessionToken) => {
  try {
    const response = await api.get(`/states/${countryId}`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
   
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching cities data for country ID ${countryId}`);
  }
};