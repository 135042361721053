import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Dashboard/Layout/Layout";
import authServiceInstance from "../../../../Services/authService";
import List from "./List";
import Create from "./Create";
import { getListClient } from "../../../../Services/clientsService";

const Clientes = ({ toggleStatus }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [client, setClient] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState(0); // Estado por defecto 0
  const token = authServiceInstance.getSessionToken();

  const changeStatus = () => {
    setIsCreate((prev) => !prev);
  };

  const fetchClientList = async () => {
    setIsLoading(true);
    try {
      const clientData = await getListClient(token, status, currentPage, pageSize);
      setClient(clientData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching clients:", error);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    fetchClientList();
  }, [currentPage, pageSize, status]); // Actualizar lista cuando cambian currentPage, pageSize o status

  return (
    <div className="container-report" style={{ backgroundColor: "#F4F6F8" }}>
      <div className={toggleStatus ? "orders " : "orders active"}>
        <Layout>
          {!isCreate ? (
            <List
              changeStatus={changeStatus}
              clientsData={client}
              fetchClientList={fetchClientList}
              isLoading={isLoading}
              currentPage={currentPage}
              pageSize={pageSize}
              setPageSize = {setPageSize}
              setStatus={setStatus}
              setCurrentPage= {setCurrentPage}
              onPageChange={handlePageChange}
            />
          ) : (
            <Create changeTap={changeStatus} fetchClientList={fetchClientList} />
          )}
        </Layout>
      </div>
    </div>
  );
};

export default Clientes;
