import React, { useState } from 'react'
import Content from './components/Content';
import Sidebar from './components/Sidebar';
import './perfil.css'
const Perfil = ({handleLogout}) => {
    const [selectedItem, setSelectedItem] = useState(null);

  const handleItemSelected = (item) => {
    setSelectedItem(item);
  };
  return (
    <div className="perfil-admin">
        <Sidebar onItemSelected={handleItemSelected} handleLogout={handleLogout} selectedItem={selectedItem}/>
        <main className='main-perfil'>
        <Content onItemSelected={handleItemSelected} handleLogout={handleLogout} selectedItem={selectedItem} />
        </main>
  </div>
  )
}

export default Perfil