import { api } from "./api";
import { jwtDecode } from "jwt-decode";

class AuthService {
  async login(credentials) {
    try {
      const response = await api.post("/client/login", credentials);

      if (response.data.success) {
        // Almacena el token de sesión en localStorage
        localStorage.setItem("sessionToken", response.data.data.session_token);
        localStorage.setItem("user", JSON.stringify(response.data.data));

        return { success: true, userData: response.data.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error("Error al procesar la solicitud:", error);
      let errorMessage = "Error al procesar la solicitud";
      errorMessage = error?.response?.data?.message;
      return { success: false, message: errorMessage };
    }
  }

  async loginAdmin(credentials) {
    try {
      const response = await api.post("/admin/login", credentials);
      localStorage.setItem("sessionToken", response.data.data.session_token);
      localStorage.setItem("admin", JSON.stringify(response.data.data));
      return { success: true, userData: response.data.data };
    } catch (error) {
      console.error("Error al procesar la solicitud:", error);
      let errorMessage = "Error al procesar la solicitud";
      errorMessage = error?.response?.data?.message;
      return { success: false, message: errorMessage };
    }
  }

  logout() {
    // Elimina el token de sesión al cerrar sesión
    localStorage.removeItem("sessionToken");

    return { success: true };
  }

  // Método para obtener el token de sesión almacenado
  getSessionToken() {
    return localStorage.getItem("sessionToken");
  }

  getSessionAdmin() {
    return JSON.parse(localStorage.getItem("admin"));
  }

  getSessionUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  // Método para verificar si el usuario está autenticado basado en el token almacenado
  isAuthenticatedAdmin() {
    //check if token is expired

    const token = this.getSessionToken();
    if (!token) {
      return false;
    }
    const decoded = jwtDecode(token);
    
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.log("Token expired");
      localStorage.removeItem("user");
      return false;
    }
    if (decoded.typeUser !== "admin") {
      return false;
    }
    return true;

    // return this.getSessionToken() !== null;
  }
  isAuthenticated() {
    //check if token is expired
    const token = this.getSessionToken();
    if (!token) {
      return false;
    }
    const decoded = jwtDecode(token);
    
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.log("Token expired");
      localStorage.removeItem("user");
      return false;
    }
    if (decoded.typeUser !== "client") {
      return false;
    }
    return true;

    // return this.getSessionToken() !== null;
  }
}

const authServiceInstance = new AuthService();

export default authServiceInstance;
