import React, { useEffect, useState } from "react";
import "./fileinput.css";
import { useRef } from "react";

const FileInput = ({ value, setValue, name }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Si hay archivos en el valor, establecerlos como archivos seleccionados
    if (value && value[name] && value[name].length > 0) {
      setSelectedFiles(value[name]);
    }
  }, [value, name]);

  useEffect(() => {
    if (value?.other_documents?.file_galleries && name === "other_documents") {
      setSelectedFiles(
        value?.other_documents?.file_galleries.map((item, index) => {
          return {
            name: "documento" + (index + 1),
            url: item.url,
          };
        })
      );
    } else if (
      value?.invoice_photo?.file_galleries &&
      name === "invoice_photo"
    ) {
      setSelectedFiles(
        value?.invoice_photo?.file_galleries.map((item, index) => {
          return {
            name: "factura" + (index + 1),
            url: item.url,
          };
        })
      );
    }
  }, [value]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedFiles = [...selectedFiles, ...files];
    setSelectedFiles(updatedFiles);
    setValue({
      ...value,
      [name]: updatedFiles,
    });
  };

  const handleRemoveFile = (fileName) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(updatedFiles);
    setValue({
      ...value,
      [name]: updatedFiles,
    });
  };

  const handleUploadClick = () => {
    // Simula el clic en el input file
    fileInputRef.current.click();
  };

  const handleViewFile = (file) => {
    const fileURL = value?.other_documents?.file_galleries
      ? file.url
      : URL.createObjectURL(file);

    window.open(fileURL, "_blank");
  };

  const handleDownloadFile = (file) => {
    // Implementa la lógica para descargar el archivo
    if (value?.other_documents?.file_galleries) {
      const a = document.createElement("a");
      a.href = file.url;
      a.download = file.name;
      a.target = "_blank"; // Agrega este atributo
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <div className="file-input-container">
      <div className="file-input">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
          multiple
        />
        {
          <label>
            <div className="file-input-label">
              <p>
                Explore sus archivos y cargue sus{" "}
                {name === "other_documents" ? "documentos" : "facturas"}
              </p>
            </div>

            <button
              type="button"
              className="upload-button"
              onClick={handleUploadClick}
            >
              SUBIR
            </button>
          </label>
        }
      </div>

      {selectedFiles.length > 0 && (
        <div className="file-list">
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>
                {file.name}
                <div>
                  <a onClick={() => handleRemoveFile(file.name)}>Eliminar</a>
                  <a onClick={() => handleViewFile(file)}>Ver</a>
                  <a onClick={() => handleDownloadFile(file)}>Descargar</a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileInput;
