import React, { useEffect } from "react";
import Routes from "./Routes/AppRouter.jsx";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { useDispatch } from "react-redux";
import { loginSuccess } from "./redux/actions/authActions.js";


const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#5966BA",
    },
    secondary: {
      main: "#747476",
    },
  },
});

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Verificar si hay un usuario almacenado en localStorage
    const storedUser = JSON.parse(localStorage.getItem("user"));

    if (storedUser) {
      // Si hay un usuario almacenado, despachar la acción de inicio de sesión exitoso
      dispatch(loginSuccess(storedUser));
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
};

export default App;
