import React from "react";
import { FiAlertOctagon } from "react-icons/fi";
import { LoadingData } from "../../../Components/loaders/LoadingData";

const DeleteStatusModal = ({
  openModalDelete,
  setOpenModalDelete,
  action,
  isLoading,
}) => {
  const handleCloseModal = () => {
    setOpenModalDelete(false);
  };
  return (
    <div>
      {openModalDelete && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                <div className="modal-cabecera">
                  <div className="modal-icon pb-4">
                    <span className="icon-container">
                      <FiAlertOctagon />
                    </span>
                  </div>
                  <div className="modal-question">
                    <span>Eliminar permanentemente</span>
                    <small>
                      Recuerda que esta acción es irreversible.¿Estás seguro de
                      eliminar a este estado?
                    </small>
                  </div>
                </div>
                {isLoading && (
                  <div className="my-3 mx-auto">
                    <LoadingData />
                  </div>
                )}
                {!isLoading && (
                  <div className="modal-footer p-0 pt-3">
                    <a className="btn-cancel m-0" onClick={handleCloseModal}>
                      Cancelar
                    </a>
                    <a className="btn-acept m-0" onClick={action}>
                      Aceptar
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteStatusModal;
