import Select from "react-select";
import "./selects.css";
import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import {
  getClientById,
  getClientByName,
  getClientData,
} from "../../../Services/clientService";
import authServiceInstance from "../../../Services/authService";

const ClientSelect = ({
  valueData,
  setValueData,
  order,
  client,
  setClient,
}) => {
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const token = authServiceInstance.getSessionToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clients = await getClientData(token);

        if (clients?.success && clients?.data) {
          const dataClients = clients?.data;

          setClientData(dataClients);
        } else {
          setClientData([]);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const clientOptions = useMemo(() => {
    if (clientData.length > 0) {
      const mapped = clientData.map((client) => ({
        value: client?.user?.id_user,
        label: `${client?.user?.name} ${client?.user?.last_name}`,
      }));
      return mapped;
    } else {
      return [];
    }
  }, [clientData]);

  const fetchClient = useCallback(
    async (id) => {
      try {
        const client = await getClientById(id, token);

        if (client) {
          setValueData((prevValue) => ({
            ...prevValue,
            id_user: client.data[0].user.id_user,
            name: client.data[0].client.name,
            lastName: client.data[0].client.last_name,
            position: client.data[0].client.position,
            email: client.data[0].client.email,
            officePhone: client.data[0].client.number_phone_office,
            cellPhone: client.data[0].client.number_phone,
            //compañía
            companyName: client.data[0].company?.company_name ?? "",
            rfc: client.data[0].company?.rfc ?? "",
            address: client.data[0].company?.fiscal_address ?? "",
            businessActivity: client.data[0].company?.economic_activity ?? "",
            taxRegime: client.data[0].company?.tax_regime ?? "",
            observations: client.data[0].company?.observations ?? "",
          }));
          setClient(client.data[0].user.id_user);
        } else {
          setValueData({
            ...valueData,
            id_user: "",
            name: "",
            lastName: "",
            position: "",
            email: "",
            officePhone: "",
            cellPhone: "",
            //compañía
            companyName: "",
            rfc: "",
            address: "",
            businessActivity: "",
            taxRegime: "",
            observations: "",
          });
          setClient(null);
        }
      } catch (error) {
        setSelectedOption(null);
        console.error("Error:", error.message);
      }
    },
    [token]
  );

  const handleSelectChange = useCallback(
    (selectedOption) => {
      if (selectedOption) {
        const filtered = clientOptions.filter(
          (option) => option.value === selectedOption.value
        );
        fetchClient(selectedOption.value);
        setSelectedOption(filtered);
      } else {
        fetchClient(null);
        setSelectedOption(null);
      }
    },
    [fetchClient, clientOptions]
  );

  const selected = useMemo(() => {
    if (order?.id_service_order) {
      const option = {
        value: order.id_user,
        label: `${order.user.name} ${order.user.last_name}`,
      };
      setSelectedOption(option);
      return option;
    } else {
      return null;
    }
  }, [order]);

  useEffect(() => {
    if (selectedOption?.value) {
      fetchClient(selectedOption?.value);
    }
  }, [selectedOption, fetchClient]);

  return (
    <div className="client-select">
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Ingrese el nombre"
        isClearable={true}
        value={selectedOption}
        isSearchable={true}
        name="color"
        options={clientOptions}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default ClientSelect;
