import React from 'react';
import Desktop from './Contenido/Desktop';
import Embarques from './Contenido/Embarques';
import Presupuestos from './Contenido/Presupuestos';
import Cuenta from './Contenido/Cuenta';
import Direccion from './Contenido/Direccion';

const Content = ({ onItemSelected, handleLogout,selectedItem }) => {
  return (
    <div>
      {selectedItem === 'home' && <Desktop onItemSelected={onItemSelected} handleLogout={handleLogout} />}
      {selectedItem === 'embarques' && <Embarques/>}
      {selectedItem === 'cuenta' && <Cuenta />}
      {selectedItem === 'direccion' && <Direccion />}
      

      {selectedItem !== 'home' && selectedItem !== 'embarques' && selectedItem !== 'cuenta' && selectedItem !== 'direccion' && selectedItem !== 'presupuestos' && (
        <Desktop onItemSelected={onItemSelected} handleLogout={handleLogout} />
      )}
    </div>
  );
};

export default Content;