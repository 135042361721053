import React, { useEffect, useState } from "react";
import "./login.css";
import { useDispatch } from "react-redux";
import {
  checkAuthenticationAdmin,
  loginAdmin,
} from "../../../redux/actions/authActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import store from "../../../redux/store";

const AdminLogin = ({ toggleStatus }) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const [inputError, setInputError] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const { user, error } = authState;
  const [showPassword, setShowPassword] = useState(false);
  const validateInputs = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validar formato de correo electrónico
    if (!emailRegex.test(credentials.email)) {
      setInputError((prev) => ({
        ...prev,
        email: "Formato de email invalido",
      }));
      return false;
    } else if (emailRegex.test(credentials.email)) {
      setInputError((prev) => ({ ...prev, email: "" }));
    }

    // Validar formato de contraseña
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{}[\]:;<>,.?/\\])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    if (!passwordRegex.test(credentials.password)) {
      setInputError((prev) => ({
        ...prev,
        password:
          "Formato de contraseña invalido, mínimo 8 caracteres, una mayúscula,un numero y un carácter especial",
      }));
      return false;
    } else {
      setInputError((prev) => ({ ...prev, password: "" }));
    }

    return true;
  };

  const dispatch = useDispatch();

  const handleLogin = (e) => {
    setIsloading(true);
    e.preventDefault();

    setTimeout(() => {
      if (validateInputs()) {
        setInputError({
          email: false,
          password: false,
        });
        dispatch(loginAdmin(credentials, navigate));
        setIsloading(false);
      } else {
        setIsloading(false);
      }
    }, 2000);
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    store.dispatch(checkAuthenticationAdmin());
    if (user) {
      navigate("/admin-itc");
    }
  }, []);

  return (
    <div className="background-login">
      <div className="d-flex justify-content-start p-4">
        <img
          src="/assets/img/logo-white.svg"
          alt="logo"
          className="logo-login"
          width={200}
        />
      </div>
      <div className="login-card-container">
        <div className="login-card">
          <h3>Iniciar sesión</h3>
          <form action="" onSubmit={handleLogin} className="w-100 p-0">
            <div className="form-group w-100">
              <label htmlFor="email">Correo electrónico</label>
              <input
                type="email"
                className={`form-control ${
                  inputError.email !== "" ? "error-form" : ""
                }`}
                onChange={handleChange}
                name="email"
                id="email"
                value={credentials.email}
                required
                placeholder="Ingresa tu correo electrónico"
                onBlur={validateInputs}
                maxLength={100}
              />
              {inputError.email !== "" && (
                <small className="text-danger">{inputError.email}</small>
              )}
            </div>
            <div className="form-group form-group-password">
              <label htmlFor="password">Contraseña</label>
              <input
                required
                type={showPassword ? "text" : "password"}
                className={`form-control ${
                  inputError.password !== "" ? "error-form" : ""
                }`}
                id="password"
                onChange={handleChange}
                name="password"
                value={credentials.password}
                placeholder="Ingresa tu contraseña"
                onBlur={validateInputs}
                maxLength={100}
              />
              {showPassword ? (
                <i
                  className="fa-regular fa-eye-slash password-icon"
                  onClick={togglePasswordVisibility}
                ></i>
              ) : (
                <i
                  className="fa-regular fa-eye password-icon"
                  onClick={togglePasswordVisibility}
                ></i>
              )}
              {inputError.password !== "" && (
                <small className="text-danger">{inputError.password}</small>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className=" btn btn-login"
                disabled={isLoading}
              >
                Ingresar{" "}
              </button>
            </div>
          </form>
        </div>
        {error && (
          <div className="alert alert-danger mt-3" role="alert">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
