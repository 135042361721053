import React, { useEffect, useMemo, useState } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { OtherData, OtherDataAdmin, SidebarData } from "../Sidebar/SidebarData";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/authActions";
import authServiceInstance from "../../../Services/authService";
import { Avatar, useMediaQuery } from "@mui/material";
import { Person } from "@mui/icons-material";

const Navbar = ({ changeStatus, currentPath }) => {
  const [sidebar, setSidebar] = useState(true);
  const [isRotated, setIsRotated] = useState(false);
  const [submenuVisibility, setSubmenuVisibility] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const admin = authServiceInstance.getSessionAdmin();
  console.log(admin);

  useEffect(() => {
    if (admin?.role?.role_id === 1) {
      setIsAdmin(true);
    }
  }, [admin]);

  console.log(isAdmin);

  const getTitleFromPath = (path) => {
    const pathMap = {
      "/admin-itc": "Dashboard",
      "/admin-itc/ordenes": "Órdenes",
      "/admin-itc/reportes": "Reportes",
      "/admin-itc/clientes": "Clientes",
      "/admin-itc/administracion": "Administración",
      "/admin-itc/perfil": "Actualizar Perfil",
      "/admin-itc/estado-pedidos": "Estados de Pedido",
      "/admin-itc/carrier": "Carriers",
    };
    const orderPattern = /^\/admin-itc\/order\/(\d+)$/;
    const adminPattern = /^\/admin-itc\/administracion\/(\d+)$/;
    const clientPattern = /^\/admin-itc\/clientes\/(\d+)$/;
    const carrierPattern = /^\/admin-itc\/carrier\/(\d+)$/;
    const statusPattern = /^\/admin-itc\/estado-pedidos\/(\d+)$/;
    const orderMatch = path.match(orderPattern);
    const adminMatch = path.match(adminPattern);
    const clientMatch = path.match(clientPattern);
    const carrierMatch = path.match(carrierPattern);
    const statusMatch = path.match(statusPattern);
    if (orderMatch) {
      return `Detalle de la orden`;
    }
    if (adminMatch) {
      return `Detalles del Administrador`;
    }
    if (clientMatch) {
      return `Detalles del Cliente`;
    }
    if (carrierMatch) {
      return `Detalles del Carrier`;
    }
    if (statusMatch) {
      return `Detalles del Estado de Pedido`;
    }
    return pathMap[path] || "Otro";
  };

  const currentTitle = getTitleFromPath(currentPath);

  const toggleSubmenu = (index) => {
    setSubmenuVisibility((prevVisibility) => ({
      ...prevVisibility,
      [index]: !prevVisibility[index],
    }));
  };
  const rotateIcon = () => {
    setIsRotated(!isRotated);
  };
  const showSidebar = () => {
    setSidebar(!sidebar);
    changeStatus();
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  const sideDataWithPermissions = useMemo(() => {
    if (!isAdmin) {
      const filteredSidebarData = SidebarData.filter(
        (item) => item.title !== "Usuarios"
      );

      return filteredSidebarData;
    } else {
      return SidebarData;
    }
  }, [isAdmin]);

  const sideOtherDataWithPermissions = useMemo(() => {
    if (!isAdmin) {
      const filteredSidebarData = OtherData.filter(
        (item) => item.title !== "Perfil" && item.title !== "Configuración"
      );

      return filteredSidebarData;
    } else {
      return OtherDataAdmin;
    }
  }, [isAdmin]);
  return (
    <>
      <div className={sidebar ? "navbar active" : "navbar"}>
        <div className={sidebar ? "menu active block  " : "menu"}>
          <div className="flex justify-between ">
            <div>
              <Link to="#" className="menu-bars ">
                <i
                  className="fa-solid fa-bars-staggered"
                  onClick={showSidebar}
                ></i>
              </Link>
              <span className="pl-4 text-lg md:text-2xl font-bold">
                {!isMobile && sidebar && currentTitle}
                {isMobile && !sidebar && currentTitle}
                {!isMobile && !sidebar && currentTitle}
              </span>
            </div>
            <div className="md:flex flex-row px-4 gap-2 items-center hidden">
              {admin?.profile_picture_url ? (
                <Avatar
                  alt={admin?.name ?? "Avatar"}
                  src={admin?.profile_picture_url}
                />
              ) : (
                <Avatar>
                  <Person />
                </Avatar>
              )}
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {admin?.name ?? "Usuario"}
                </span>
                <span className=" text-xs font-light">
                  {admin?.role?.role_name ?? "Rol"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <div className="nav-menu-title">
          <div className="brand">
            <img src="/assets/img/logo_alt.png" alt="logo-alt" />
          </div>
        </div>
        <ul className="nav-menu-items p-0 overflow-y-auto">
          <hr className="m-3 text-white" />
          {sideDataWithPermissions.map((item, index) => {
            return (
              <li key={index} className={`${item.cName}`}>
                {item.submenus ? (
                  <div className="container-submenu">
                    <a
                      className={`sub-menu nav-link`}
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse-${index}`}
                      onClick={rotateIcon}
                    >
                      {item.icon}
                      <span>
                        {item.title}{" "}
                        <i
                          className={`fa-solid fa-angle-right drop ${
                            isRotated ? " rotate" : ""
                          }`}
                        ></i>
                      </span>
                    </a>
                    <div
                      className={` ${
                        submenuVisibility[index] ? " show" : "visible"
                      }`}
                      id={`collapse-${index}`}
                    >
                      <ul className="nav">
                        {item.submenus.map((submenu, submenuIndex) => (
                          <li key={submenuIndex} className={submenu.cName}>
                            <Link
                              to={submenu.path}
                              className={`hover:text-white ${
                                currentPath === submenu.path
                                  ? "bg-[#1a83ff] text-white"
                                  : ""
                              }`}
                              onClick={isMobile ? showSidebar : () => {}}
                            >
                              <span>{submenu.title}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <Link
                    to={item.path}
                    className={`hover:text-white ${
                      currentPath === item.path ? "bg-[#1a83ff] text-white" : ""
                    }`}
                    onClick={isMobile ? showSidebar : () => {}}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                )}
              </li>
            );
          })}
          <hr className="m-3 text-white" />

          {sideOtherDataWithPermissions.map((item, index) => {
            return (
              <li key={index} className={`${item.cName}`}>
                {item.submenus ? (
                  <div className="container-submenu">
                    <a
                      className={`sub-menu nav-link`}
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse-${index}`}
                      onClick={rotateIcon}
                    >
                      {item.icon}
                      <span>
                        {item.title}{" "}
                        <i
                          className={`fa-solid fa-angle-right drop ${
                            isRotated ? " rotate" : ""
                          }`}
                        ></i>
                      </span>
                    </a>
                    <div
                      className={` ${
                        submenuVisibility[index] ? " show" : "visible"
                      }`}
                      id={`collapse-${index}`}
                    >
                      <ul className="nav">
                        {item.submenus.map((submenu, submenuIndex) => (
                          <li key={submenuIndex} className={submenu.cName}>
                            <Link
                              to={submenu.path}
                              className={`hover:text-white ${
                                currentPath === submenu.path
                                  ? "bg-[#1a83ff] text-white"
                                  : ""
                              }`}
                              onClick={isMobile ? showSidebar : () => {}}
                            >
                              <span>{submenu.title}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : item.title === "Cerrar sesión" ? (
                  <span
                    style={{
                      textDecoration: "none",
                      border: "none",
                      color: "#f5f5f5",
                      width: "95%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={handleLogout}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </span>
                ) : (
                  <Link
                    to={item.path}
                    className={`hover:text-white ${
                      currentPath === item.path ? "bg-[#1a83ff] text-white" : ""
                    }`}
                    onClick={isMobile ? showSidebar : () => {}}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
