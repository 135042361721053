import React from 'react'
import './politicas.css'
import { t } from 'i18next'
const OtrasPoliticas = () => {
  return (
    <div className="politicsPage">
    <div className="slider">
    <h1>{t('other.title')}</h1>
      <span>{t('other.breadcrumb.prev')} {'>'} {t('other.breadcrumb.section')} {'>'} {t('other.breadcrumb.name')}</span>
    </div>
    <div className="politicas">
        <div className="container">
        <div className="politicas-contenido" dangerouslySetInnerHTML={{ __html: t('other.content') }} />
        </div>
    </div>
  </div>
  )
}

export default OtrasPoliticas