import TawkMessengerReactUmd from '@tawk.to/tawk-messenger-react'
import React from 'react'

const TawkToChat = () => {
  return (
    <div className="App">
            <TawkMessengerReactUmd
                propertyId="6583488070c9f2407f81f683"
                widgetId="1hi4d6e7f"/>
        </div>
  )
}

export default TawkToChat