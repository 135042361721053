import { t } from "i18next";
import React, { useState } from "react";
import Spinner from "../../Components/loaders/Spinner";
import { sendFormContact } from "../../Services/contactService";
import ErrorContact from "../Dashboard/Modales/ErrorContact";
import SuccessContact from "../Dashboard/Modales/SuccessContact";
import "./contact.css";
const Contact = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone_number: "",
    company: "",
    message: "",
  });
  const [loader, setLoader] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Formulario enviado:", formState);
    sendForm();
  };

  const sendForm = async () => {
    try {
      const response = await sendFormContact(formState);

      setLoader(false);
      setOpenSuccess(true);
      setOpenError(false);
    } catch (error) {
      setLoader(false);

      console.error("Error al enviar el mensaje:", error.message);
    }
  };
  return (
    <>
      {loader && <Spinner />}
      <SuccessContact
        openModalSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
      />
      <ErrorContact openModalError={openError} setOpenError={setOpenError} />
      <div className="contactPage">
        <div className="slider">
          <h1>{t('contact.title')}</h1>
          <span>{t('contact.breadcrumb.prev')} {">"} {t('contact.breadcrumb.name')}</span>
        </div>
        <div className="container">
          <div className=" contacto-container">
            <div className="row">
              <div className="col-4 contact-group">
                <span className="contact-icon">
                  <i className="fa-solid fa-phone"></i>
                </span>
                <div className="contact-info">
                  <span>{t('contact.phone')}</span>
                  <small>CDMX: (55) 4630 8060</small>
                  <small>GDL: (33) 5980 1274</small>
                </div>
              </div>
              <div className="col-4 contact-group">
                <span className="contact-icon">
                  <i className="fa-regular fa-envelope"></i>
                </span>
                <div className="contact-info">
                  <span>{t('contact.email')}</span>
                  <small>sales@itcconsultancy.com</small>
                  <small>monica.perez@itcconsultancy.com</small>
                  <small>carlos.quiroz@itcconsultancy.com</small>
                </div>
              </div>
              <div className="col-4 contact-group">
                <span className="contact-icon">
                  <i className="fa-regular fa-clock"></i>
                </span>
                <div className="contact-info">
                  <span>{t('contact.hours')}</span>
                  <small>{t('contact.hoursRange')}</small>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 col-md-5 contactanos">
                <span>{t('contact.section.subTitle')}</span>
                <h2>{t('contact.section.title')}</h2>
                <p>{t('contact.section.description')}</p>
                <div className="contactanos-countries ">
                  <div
                    className="navigate-contact nav-pills"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className="active"
                      href=""
                      id="pills-prev-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-prev"
                      type="button"
                      role="tab"
                      aria-controls="pills-prev"
                      aria-selected="true"
                    >
                      <i className="fa-solid fa-chevron-left"></i>
                    </a>
                    <a
                      href=""
                      id="pills-next-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-next"
                      type="button"
                      role="tab"
                      aria-controls="pills-next"
                      aria-selected="false"
                    >
                      <i className="fa-solid fa-chevron-right"></i>
                    </a>
                  </div>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-prev"
                      role="tabpanel"
                      aria-labelledby="pills-prev-tab"
                      tabIndex="0"
                    >
                      <h3>Guadalajara</h3>
                      <div className="formulario-info">
                        <div className="info-item">
                          <span>
                            <i className="fa-solid fa-location-dot"></i>
                          </span>
                          <p>
                            Av Acueducto 6075-A, Local 3, Puerta de Hierro, 45116, Zapopan,
                            Jalisco
                          </p>
                        </div>
                      </div>
                      <h3>CDMX</h3>
                      <div className="formulario-info">
                        <div className="info-item">
                          <span>
                            <i className="fa-solid fa-location-dot"></i>
                          </span>
                          <p>
                            Av. Taumalipas #141, 3er piso, Col Condesa C.P06140, Cd de México
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-next"
                      role="tabpanel"
                      aria-labelledby="pills-next-tab"
                      tabIndex="1"
                    >
                      <h3>Bodega LRD</h3>
                      <div className="formulario-info">
                        <div className="info-item">
                          <span>
                            <i className="fa-solid fa-location-dot"></i>
                          </span>
                          <p>
                            10218 Crossroads Loop
                          </p>
                          <p>
                            Crossroads Bussiness Park Laredo, TX 78045-9441
                          </p>
                        </div>
                      </div>
                      <h3>Bodega CDMX</h3>
                      <div className="formulario-info">
                        <div className="info-item">
                          <span>
                            <i className="fa-solid fa-location-dot"></i>
                          </span>
                          <p>
                            Av. Ferrocarril Industrial Num. 211 Col. Moctezuma 2da Secc. C.P. 15520 Cd. de México
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7 form-contact">
                <h3 className="pl-4">{t('contact.form.title')}</h3>
                <form onSubmit={handleSubmit} className="row">
                  <div className="col-6 p-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`${t('contact.form.name')}`}
                      name="name"
                      value={formState.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-6 p-2">
                    <input
                      type="email"
                      className="form-control"
                      placeholder={`${t('contact.form.email')}`}
                      name="email"
                      value={formState.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-6 p-2">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder={`${t('contact.form.phone')}`}
                      name="phone_number"
                      value={formState.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-6 p-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`${t('contact.form.company')}`}
                      name="company"
                      value={formState.company}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 p-2">
                    <textarea
                      className="form-control"
                      placeholder={`${t('contact.form.message')}`}
                      name="message"
                      value={formState.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn">
                  {`${t('contact.form.button')}`}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
