import React from "react";
import { useState } from "react";

import { useNavigate, useParams } from "react-router";
import UpdateModal from "../../Modales/UpdateModal";
import authServiceInstance from "../../../../Services/authService";
import Spinner from "../../../../Components/loaders/Spinner";
import ImageUploader from "../../../../Components/Dashboard/Inputs/Image/ImageUploader";
import { createAdmin } from "../../../../Services/admin";
import CreateClientModal from "../../Modales/CreateClientModal";
import { createClient } from "../../../../Services/clientsService";

const Create = ({ changeTap, fetchClientList }) => {
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [positionError, setPositionError] = useState(null);
  const [cellError, setCellError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);

  const [companyNameError, setCompanyNameError] = useState(null);
  const [companyRFCError, setCompanyRFCError] = useState(null);
  const [companyFiscalAddressError, setCompanyFiscalAddressError] =
    useState(null);
  const [companyEconomicActivityError, setCompanyEconomicActivityError] =
    useState(null);
  const [companyTaxRegime, setCompanyTaxRegimeError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    position: "",
    email: "",
    phone: "",
    cell: "",
    password: "",
    // profile_picture_url: "",
    //company
    company: {
      companyName: "",
      companyRFC: "",
      companyFiscalAddress: "",
      companyEconomicActivity: "",
      companyTaxRegime: "",
      companyObservations: "",
    },
  });
  const token = authServiceInstance.getSessionToken();

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Verificar si el nombre contiene la palabra 'company'
    if (name.includes("company")) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        company: {
          ...prevFormData.company,
          [name]: value,
        },
      }));
    } else {
      // Actualizar en el nivel superior de formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const returnList = () => {
    changeTap();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{}[\]:;<>,.?/\\])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    if (formData?.name === "" || formData?.name?.length < 2) {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }
    if (formData.last_name === "" || formData?.last_name?.length < 2) {
      setLastNameError(true);
      return;
    } else {
      setLastNameError(false);
    }
    if (formData.position === "" || formData?.position?.length < 2) {
      setPositionError(true);
      return;
    } else {
      setPositionError(false);
    }
    if (!emailRegex.test(formData.email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }
    if (formData.phone === "" || formData?.phone?.length < 2) {
      setPhoneError(true);
      return;
    } else {
      setPhoneError(false);
    }
    if (formData.cell === "" || formData?.cell?.length < 2) {
      setCellError(true);
      return;
    } else {
      setCellError(false);
    }
    if (!passwordRegex.test(formData.password)) {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    if (
      formData.company.companyName === "" ||
      formData?.company.companyName?.length < 2
    ) {
      setCompanyNameError(true);
      return;
    } else {
      setCompanyNameError(false);
    }
    if (
      formData.company.companyRFC === "" ||
      formData?.company.companyRFC?.length < 2
    ) {
      setCompanyRFCError(true);
      return;
    } else {
      setCompanyRFCError(false);
    }
    if (
      formData.company.companyFiscalAddress === "" ||
      formData?.company.companyFiscalAddress?.length < 2
    ) {
      setCompanyFiscalAddressError(true);
      return;
    } else {
      setCompanyFiscalAddressError(false);
    }
    if (
      formData.company.companyEconomicActivity === "" ||
      formData?.company.companyEconomicActivity?.length < 2
    ) {
      setCompanyEconomicActivityError(true);
      return;
    } else {
      setCompanyEconomicActivityError(false);
    }
    if (
      formData.company.companyTaxRegime === "" ||
      formData?.company.companyTaxRegime?.length < 2
    ) {
      setCompanyTaxRegimeError(true);
      return;
    } else {
      setCompanyTaxRegimeError(false);
    }

    crearUser();
  };

  const crearUser = async () => {
    const nuevosDatosDeClient = formData;

    const newData = {
      ...formData,
      profile_picture_url: imageProfile,
    };

    setLoader(true);
    try {
      await createClient(newData, token);

      setOpenModalUpdate(true);
      setLoader(false);
      fetchClientList();
    } catch (error) {
      console.error("Error al crear un nuevo client:", error.message);
      setLoader(false);
    }
  };

  const handleChangeImageProfile = (img) => {
    if (img) {
      setImageProfile(img);
    } else {
      setImageProfile(null);
    }
  };
  return (
    <>
      {loader && <Spinner />}
      <>
        <div className="container-step">
          <form className="p-0 m-0 w-100">
            <section className="section-step">
              <h3>Cliente</h3>
            </section>
            <div className="section row m-0">
              <div className="mb-3 col-md-5 d-flex row m-0 p-0">
                <div className="col-md-4 col-lg-3">
                  <ImageUploader
                    dataImg={imageProfile}
                    setValor={handleChangeImageProfile}
                  />
                </div>
                <div className="col-md-8 col-lg-9 col-12">
                  <label htmlFor="name" className="form-label">
                    Nombre
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    className={`form-control ${
                      nameError ? "invalid-input" : ""
                    }`}
                    id="name"
                    name="name"
                    aria-describedby="name"
                    placeholder="Ingresa nombre"
                  />
                </div>
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="last_name" className="form-label">
                  Apellido
                </label>
                <input
                  type="text"
                  value={formData.last_name}
                  onChange={handleChange}
                  className={`form-control ${
                    lastNameError ? "invalid-input" : ""
                  }`}
                  id="last_name"
                  name="last_name"
                  aria-describedby="last_name"
                  placeholder="Ingresa apellido"
                />
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="position" className="form-label">
                  Puesto
                </label>
                <input
                  type="text"
                  value={formData.position}
                  onChange={handleChange}
                  className={`form-control ${
                    positionError ? "invalid-input" : ""
                  }`}
                  id="position"
                  name="position"
                  aria-describedby="position"
                  placeholder="Puesto en la empresa"
                />
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="email" className="form-label">
                  Correo electrónico
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`form-control ${
                    emailError ? "invalid-input" : ""
                  }`}
                  id="email"
                  name="email"
                  aria-describedby="email"
                  placeholder="Ingresa correo"
                />
                {emailError && <small>Formato de correo inválido </small>}
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="phone" className="form-label">
                  Tel. Oficina
                </label>
                <input
                  type="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className={`form-control ${
                    phoneError ? "invalid-input" : ""
                  }`}
                  id="phone"
                  name="phone"
                  aria-describedby="phone"
                  placeholder="Teléfono de oficina"
                />
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="cell" className="form-label">
                  Tel. Celular
                </label>
                <input
                  type="phone"
                  value={formData.cell}
                  onChange={handleChange}
                  className={`form-control ${cellError ? "invalid-input" : ""}`}
                  id="cell"
                  name="cell"
                  aria-describedby="cell"
                  placeholder="Teléfono de celular"
                />
              </div>
              <div className=" mb-3 col-md-3 form-group form-group-password password-admin">
                <label htmlFor="password" className="form-label">
                  Asignar contraseña
                </label>
                <input
                  required
                  type={showPassword ? "text" : "password"}
                  className={`form-control ${
                    passwordError ? "invalid-input" : ""
                  }`}
                  id="password"
                  onChange={handleChange}
                  name="password"
                  value={formData.password}
                  placeholder="Contraseña"
                  maxLength={100}
                />
                {showPassword ? (
                  <i
                    className="fa-regular fa-eye-slash password-icon"
                    onClick={togglePasswordVisibility}
                  ></i>
                ) : (
                  <i
                    className="fa-regular fa-eye password-icon"
                    onClick={togglePasswordVisibility}
                  ></i>
                )}
                {passwordError && (
                  <small>
                    Formato inválido, mínimo 8 caracteres, una mayúscula, un
                    numero y un carácter especial
                  </small>
                )}
              </div>
            </div>

            <section className="section-step">
              <h3>Compañía</h3>
            </section>
            <div className="section row m-0">
              <div className="mb-3 col-md-4">
                <label htmlFor="companyName" className="form-label">
                  Compañía
                </label>
                <input
                  type="text"
                  value={formData.company.companyName}
                  onChange={handleChange}
                  className={`form-control ${
                    companyNameError ? "invalid-input" : ""
                  }`}
                  id="companyName"
                  name="companyName"
                  aria-describedby="companyName"
                  placeholder="Ingresa nombre de la compañía"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="companyRFC" className="form-label">
                  RFC
                </label>
                <input
                  type="text"
                  value={formData.company.companyRFC}
                  onChange={handleChange}
                  className={`form-control ${
                    companyRFCError ? "invalid-input" : ""
                  }`}
                  id="companyRFC"
                  name="companyRFC"
                  aria-describedby="companyRFC"
                  placeholder="Ingresa RFC"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="companyFiscalAddress" className="form-label">
                  Dirección Fiscal
                </label>
                <input
                  type="text"
                  value={formData.company.companyFiscalAddress}
                  onChange={handleChange}
                  className={`form-control ${
                    companyFiscalAddressError ? "invalid-input" : ""
                  }`}
                  id="companyFiscalAddress"
                  name="companyFiscalAddress"
                  aria-describedby="companyFiscalAddress"
                  placeholder="Dirección"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="companyEconomicActivity" className="form-label">
                  Actividad económica
                </label>
                <input
                  type="text"
                  value={formData.company.companyEconomicActivity}
                  onChange={handleChange}
                  className={`form-control ${
                    companyEconomicActivityError ? "invalid-input" : ""
                  }`}
                  id="companyEconomicActivity"
                  name="companyEconomicActivity"
                  aria-describedby="companyEconomicActivity"
                  placeholder="Actividad"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="companyTaxRegime" className="form-label">
                  Régimen fiscal
                </label>
                <input
                  type="text"
                  value={formData.company.companyTaxRegime}
                  onChange={handleChange}
                  className={`form-control ${
                    companyTaxRegime ? "invalid-input" : ""
                  }`}
                  id="companyTaxRegime"
                  name="companyTaxRegime"
                  aria-describedby="companyTaxRegime"
                  placeholder="Régimen fiscal"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="companyObservations" className="form-label">
                  Observaciones
                </label>
                <input
                  type="text"
                  value={formData.company.companyObservations}
                  onChange={handleChange}
                  className={`form-control `}
                  id="companyObservations"
                  name="companyObservations"
                  aria-describedby="companyObservations"
                  placeholder="Observaciones"
                />
              </div>
            </div>

            <div className="section-button pb-2 gap-2">
              <button
                type="button"
                className=" btn-cancelar ms-auto"
                onClick={returnList}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn-next mr-1"
                onClick={handleSubmit}
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
        <CreateClientModal
          openModalUpdate={openModalUpdate}
          setOpenModalUpdate={setOpenModalUpdate}
          type={"admin-create"}
          returnList={returnList}
        />
      </>
    </>
  );
};

export default Create;
