import React from 'react'
import './index.css'
import { t } from 'i18next'
const index = () => {
  return (
    <div className="aboutPage">
        <div className="slider">
            <h1>{t('about.title')}</h1>
            <span>{t('about.breadcrumb.prev')} {'>'} {t('about.breadcrumb.name')}</span>
        </div>
        <div className="nosotros">
            <div className="container">
                <div className="nosotros-contenido">
                    
                    <span className='sup-nosotros'>{t('about.section.about.subTitle')}</span>
                    <h2>{t('about.section.about.title')}</h2>
                    <p>{t('about.section.about.description')}</p>
                    <span>
                    <h3>{t('about.section.about.mission.title')}</h3>
                    <p>{t('about.section.about.mission.description')}</p>
                    </span>
                    <span>
                    <h3>{t('about.section.about.vision.title')}</h3>
                    <p className='width-vision'>{t('about.section.about.vision.description')}</p>
                    </span>
                </div>
                <div className="nosotros-imagen">
                    <img src="/assets/img/image2.png" alt="imagen-nosotros" />
                </div>
            </div>
        </div>
        <div className="experiencia">
            <div className="container">
                <div className="container-tittle">
                    <span>{t('about.experience.subTitle')}</span>
                    <h2>{t('about.experience.title')}</h2>
                </div>
                <div className="experiencia-estadisticas">
                    <div className="stat-item">
                        <span>25</span>
                        <small>{t('about.experience.items.item1')}</small>
                    </div>
                    <div className="stat-item">
                        <span>5k</span>
                        <small>{t('about.experience.items.item2')}</small>
                    </div>
                    <div className="stat-item">
                        <span>450</span>
                        <small>{t('about.experience.items.item3')}</small>
                    </div>
                </div>
            </div>
        </div>
        <div className="video-nosotros">
            <div className="container"></div>
        </div>
        <div className="valores">
            <div className="container">
                <div className="container-tittle">
                    <span>{t('about.values.subTitle')}</span>
                    <h2>{t('about.values.title')}</h2>
                </div>
                <div className="container-valores">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-honestidad-tab" data-bs-toggle="pill" data-bs-target="#pills-honestidad" type="button" role="tab" aria-controls="pills-honestidad" aria-selected="true">{t('about.values.values.value1.name')}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-trabajo-tab" data-bs-toggle="pill" data-bs-target="#pills-trabajo" type="button" role="tab" aria-controls="pills-trabajo" aria-selected="false">{t('about.values.values.value2.name')}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-confianza-tab" data-bs-toggle="pill" data-bs-target="#pills-confianza" type="button" role="tab" aria-controls="pills-confianza" aria-selected="false">{t('about.values.values.value3.name')}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-innovacion-tab" data-bs-toggle="pill" data-bs-target="#pills-innovacion" type="button" role="tab" aria-controls="pills-innovacion" aria-selected="false" >{t('about.values.values.value4.name')}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-constancia-tab" data-bs-toggle="pill" data-bs-target="#pills-constancia" type="button" role="tab" aria-controls="pills-constancia" aria-selected="false" >{t('about.values.values.value5.name')}</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-honestidad" role="tabpanel" aria-labelledby="pills-honestidad-tab" tabIndex="0">{t('about.values.values.value1.description')}</div>
                        <div className="tab-pane fade" id="pills-trabajo" role="tabpanel" aria-labelledby="pills-trabajo-tab" tabIndex="0">{t('about.values.values.value2.description')}</div>
                        <div className="tab-pane fade" id="pills-confianza" role="tabpanel" aria-labelledby="pills-confianza-tab" tabIndex="0">{t('about.values.values.value3.description')}</div>
                        <div className="tab-pane fade" id="pills-innovacion" role="tabpanel" aria-labelledby="pills-innovacion-tab" tabIndex="0">{t('about.values.values.value4.description')}</div>
                        <div className="tab-pane fade" id="pills-constancia" role="tabpanel" aria-labelledby="pills-constancia-tab" tabIndex="0">{t('about.values.values.value5.description')}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default index