import React from 'react'
import { FiAlertOctagon } from "react-icons/fi";
import './modals.css'
const CancelModal = ({changeTap, openModalCancel, setOpenModalCancel}) => {

    const handleCloseModal = () => {
        setOpenModalCancel(false);
    };
    const handleReturnOrderList = ()=>{
        changeTap()
    }
  return (
    <div>
      

      {openModalCancel && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                  <div className="modal-cabecera">
                    <div className="modal-icon pb-4">
                        <span className='icon-container'><FiAlertOctagon /></span>
                    </div>
                    <div className="modal-question">
                        <span>Cancelar orden</span>
                        <small>¿Estás seguro de que quieres cancelar?</small>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a className='btn-cancel'  onClick={handleCloseModal}>Cancelar</a>
                    <a className='btn-acept' onClick={handleReturnOrderList}>Aceptar</a>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CancelModal