import React from "react";
import "./buttons.css";

const ButtonPrimary = ({ change, text, icon }) => {
  return (
    <div className="container-button">
      <button className="btn-dashboard-primary" onClick={change}>
        {icon ? icon : ""} {text}
      </button>
    </div>
  );
};

export default ButtonPrimary;
