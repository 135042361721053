import { api } from "./api";

export const getListOrdersReports = async (
  sessionToken,
  filters,
  page,
  lim
) => {
  const { startDate, finishDate, carrierId, statusId } = filters;

  try {
    const response = await api.get(
      `/orders-report/report-all-orders?startDate=${startDate}&endDate=${finishDate}&carrier_id=${carrierId}&id_status=${statusId}&page=${page}&limit=${lim}`,
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Error fetching list orders");
  }
};
