import React from "react";

function CardDash({ icon, title, ammount, percent, classN, sign }) {
  const percentString = sign === "positive" ? `+${percent}%` : `${percent}%`;
  return (
    <div
      className={`flex flex-col relative overflow-hidden height-auto w-full bg-white rounded-xl p-3 container-layout ${classN}`}
    >
      <div className="flex flex-row gap-2">
        <div className="bg-secondaryC w-4 h-4 flex items-center justify-center rounded-[4px]">
          {icon}
        </div>
        <span className="uppercase text-[12px]">{title}</span>
      </div>
      <div className="py-3 font-bold">
        <h1 className="text-3xl font-black">{ammount}</h1>
      </div>
      <div>
        <div className="flex gap-1 text-sm">
          {sign === "positive" && (
            <i className="fa-solid fa-arrow-trend-up text-base text-textSuccess"></i>
          )}
          <span
            className={`font-semibold ${
              sign === "positive" ? "text-textSuccess" : "text-textError"
            }`}
          >
            {percentString}
          </span>
          <span className="font-extralight pl-2">mensual</span>
        </div>
      </div>
    </div>
  );
}

export default CardDash;
