import React, { useState } from "react";
import "./index.css";
import CardSlider from "../../Components/card-swiper/CardSlider";
import { t } from "i18next";
const Index = () => {
  const [collapseOne, setCollapseOne] = useState(true);
  const [collapseTwo, setCollapseTwo] = useState(true);
  const [collapseThree, setCollapseThree] = useState(true);
  const [collapseFour, setCollapseFour] = useState(true);
  return (
    <div className="homePage">
      <div className="slider">
        <video id="video-fondo" loop="true" autoPlay muted>
          <source src="/assets/video/banner-principal.mp4" type="video/mp4" />
          Tu navegador no soporta la etiqueta de video.
        </video>

        <div className="contenido-slider">
          <span className="sup-h1">
          {t('home.subTitle')}
            
          </span>
          <h1>{t('home.title')}</h1>
          <div className="container-cards">
            <div className="item-slider">
              <span>{t('home.items.contact.subTitle')}</span>
              <h3>{t('home.items.contact.title')}</h3>
              <p>
              {t('home.items.contact.description')}
              </p>
              <a href="/nosotros">
                <button>{t('home.items.contact.button')}</button>
              </a>
            </div>
            <div className="item-slider">
              <span>{t('home.items.about.subTitle')}</span>
              <h3>{t('home.items.about.title')}</h3>
              <p>
              {t('home.items.about.description')}
              </p>
              <a href="/nosotros">
                <button>{t('home.items.about.button')}</button>
              </a>
            </div>
            <div className="item-slider">
              <span>{t('home.items.transport.subTitle')}</span>
              <h3>{t('home.items.transport.title')}</h3>
              <p>
              {t('home.items.transport.description')}
              </p>
              <a href="/nosotros">
                <button>{t('home.items.transport.button')}</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="nosotros">
        <div className="container">
          <div className="nosotros-contenido">
            <span className="sup-nosotros">{t('home.section.about.subTitle')}</span>
            <h2>{t('home.section.about.title')}</h2>
            <span>
              <h3>{t('home.section.about.mission.title')}</h3>
              <p>
              {t('home.section.about.mission.description')}
              </p>
            </span>
            <span>
              <h3>{t('home.section.about.vision.title')}</h3>
              <p>
              {t('home.section.about.vision.description')}
              </p>
            </span>
            <div className="nosotros-btn">
              <a href="/contacto">
                <button className="btn-escribenos">{t('home.section.about.buttonalt')}</button>
              </a>
              <a href="/nosotros">
                <button className="btn-leer-mas">{t('home.section.about.button')}</button>
              </a>
            </div>
          </div>
          <div className="nosotros-imagen">
            <img src="/assets/img/image2.png" alt="imagen-nosotros" />
          </div>
        </div>
      </div>
      <div className="contacto">
        <div className="container">
          <div className="row contenedor-contacto">
            <div className="col-md-9">
              <span>{t('home.contactUs.question')}</span>
              <h2>{t('home.contactUs.title')}</h2>
              <small>
                {t('home.contactUs.subTitle')}
              </small>
            </div>
            <div className="col-md-3">
              <a href="/contacto">
                <button>{t('home.contactUs.button')}</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="oferta" id="faqs">
        <div className="container">
          <div className="oferta-titulo">
            <span>{t('home.faq.subTitle')}</span>
            <h2>{t('home.faq.title')}</h2>
          </div>
          <div className="card shadow">
            <div className="accordion" id="accordionOferta">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      collapseOne ? "collapsed" : ""
                    }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    onClick={() => setCollapseOne(!collapseOne)}
                  >
                    {t('home.faq.questions.question1.title')}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className={`accordion-collapse ${
                    collapseOne ? "collapse" : "visible"
                  }`}
                  data-bs-parent="#accordionOferta"
                >
                  <div className="accordion-body">
                  {t('home.faq.questions.question1.description')}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      collapseTwo ? "collapsed" : ""
                    }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    onClick={() => setCollapseTwo(!collapseTwo)}
                  >
                    {t('home.faq.questions.question2.title')}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse ${
                    collapseTwo ? "collapse" : "visible"
                  }`}
                  data-bs-parent="#accordionOferta"
                >
                  <div className="accordion-body">
                  {t('home.faq.questions.question2.description.title')}
                    <ol>
                      <li>{t('home.faq.questions.question2.description.items.item1')}</li>
                      <li>
                      {t('home.faq.questions.question2.description.items.item2')}
                      </li>
                      <li>{t('home.faq.questions.question2.description.items.item3')}</li>
                      <li>{t('home.faq.questions.question2.description.items.item4')}</li>
                      <li>{t('home.faq.questions.question2.description.items.item5')}</li>
                      <li>
                      {t('home.faq.questions.question2.description.items.item6')}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      collapseThree ? "collapsed" : ""
                    }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    onClick={() => setCollapseThree(!collapseThree)}
                  >
                    {t('home.faq.questions.question3.title')}
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className={`accordion-collapse ${
                    collapseThree ? "collapse" : "visible"
                  }`}
                  data-bs-parent="#accordionOferta"
                >
                  <div className="accordion-body">
                  {t('home.faq.questions.question3.description')}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      collapseFour ? "collapsed" : ""
                    }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    onClick={() => setCollapseFour(!collapseFour)}
                  >
                    {t('home.faq.questions.question4.title')}
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className={`accordion-collapse ${
                    collapseFour ? "collapse" : "visible"
                  }`}
                  data-bs-parent="#accordionOferta"
                >
                  <div className="accordion-body">
                    <ol>
                      <li>{t('home.faq.questions.question4.description.items.item1')}</li>
                      <li>{t('home.faq.questions.question4.description.items.item2')}</li>
                      <li>{t('home.faq.questions.question4.description.items.item3')}</li>
                      <li>{t('home.faq.questions.question4.description.items.item4')}</li>
                      <li>
                      {t('home.faq.questions.question4.description.items.item5')}
                      </li>
                      <li>{t('home.faq.questions.question4.description.items.item6')}</li>
                      <li>{t('home.faq.questions.question4.description.items.item7')}</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonios">
        <div className="container">
          <div className="testimonios-titulo">
            <span>{t('home.testimonials.subTitle')}</span>
            <h2>{t('home.testimonials.title')}</h2>
          </div>
          <CardSlider />
        </div>
      </div>
    </div>
  );
};

export default Index;
