import React, { useCallback, useEffect, useState } from "react";
import InputSearch from "../../../Components/Dashboard/Inputs/InputSearch/InputSearch";
import ButtonPrimary from "../../../Components/Dashboard/Buttons/ButtonPrimary";
import DataTable from "react-data-table-component";
import { LoadingData } from "../../../Components/loaders/LoadingData";
import authServiceInstance from "../../../Services/authService";
import { useNavigate } from "react-router";
import { deleteStatus, getStatus } from "../../../Services/status";
import DeleteStatusModal from "../Modales/DeleteStatusModal";

const paginationComponentOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,

  selectAllRowsItemText: "Todos",
};

const customStyles = {
  headCells: {
    style: {
      fontSize: "14px",
      color: "#6B6B6B",
      fontWeight: 600,
      fontFamily: "Manrope",
      with: "auto",
    },
  },
  cells: {
    style: {
      fontSize: "15px",
      color: "#001222",
      fontWeight: 500,
      fontFamily: "Manrope",
    },
  },
};

const List = ({ changeStatus }) => {
  let navigate = useNavigate();
  const [searchValue, setSearchValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusAux, setStatusAux] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const token = authServiceInstance.getSessionToken();
  const fetchStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const statusData = await getStatus(token);

      const orderedOrderStatus = statusData.data.sort((a, b) => {
        const nombreA = a.status_name.toUpperCase();
        const nombreB = b.status_name.toUpperCase();

        if (nombreA < nombreB) {
          return -1;
        }
        if (nombreA > nombreB) {
          return 1;
        }

        return 0;
      });
      setStatus(orderedOrderStatus);
      setStatusAux(orderedOrderStatus);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching status:", error);
    }
  }, [token]);
  const handleFilter = (event) => {
    setSearchValue(event.target.value);
    const newData = status.filter((row) => {
      return row.status_name
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setStatusAux(newData);
  };
  const handleMenuClick = (status, type) => {
    if (type === "edit") {
      navigate(`/admin-itc/estado-pedidos/${status.id_status}`, {
        state: { data: status },
      });
    }
    if (type === "delete") {
      setCurrentId(status.id_status);
      setOpenModalDelete(true);
    }
  };
  const customCell = (row) => (
    <div className="dropdown drop-menu">
      <button
        className="btn btn-secondary dropdown-toggle action-menu"
        type="button"
        id={`dropdownMenuButton${row.id}`}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </button>
      <ul
        className="dropdown-menu list-menu p-0"
        aria-labelledby={`dropdownMenuButton${row.id}`}
      >
        <li>
          <a
            className="dropdown-item"
            href="#"
            onClick={() => handleMenuClick(row, "edit")}
          >
            Ver estado{" "}
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            href="#"
            onClick={() => handleMenuClick(row, "delete")}
          >
            Eliminar estado
          </a>
        </li>
      </ul>
    </div>
  );

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.status_name,
      sortable: true,
    },
    {
      name: "Acciones",
      cell: customCell,
    },
  ];

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  const handleDelete = useCallback(
    async (currentId) => {
      setDeleteLoading(true);
      try {
        await deleteStatus(currentId, token);
        setOpenModalDelete(false);
        setDeleteLoading(false);
        setCurrentId(0);
        fetchStatus();
      } catch (error) {
        console.error("Error al eliminar el estado:", error.message);
        setDeleteLoading(false);
      }
    },
    [token, fetchStatus]
  );
  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-8 col-md-10 mt-1">
          <InputSearch
            value={searchValue}
            setValue={handleFilter}
            filterLabel={"por Nombre"}
          />
        </div>
        <div
          className="col-12 col-sm-4 col-md-2 mt-1"
          style={{ minHeight: "35px" }}
        >
          <ButtonPrimary change={changeStatus} text={"Añadir Estado"} />
        </div>
        <div className="col-md-12">
          <DataTable
            progressPending={isLoading}
            pagination
            columns={columns}
            noDataComponent={"No hay registros para mostrar"}
            paginationComponentOptions={paginationComponentOptions}
            data={statusAux}
            customStyles={customStyles}
            persistTableHead
            progressComponent={
              <div className="my-6 mx-auto">
                <p className="mb-3">Cargando Datos...</p>
                <LoadingData />
              </div>
            }
          />
        </div>
      </div>
      <DeleteStatusModal
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        action={() => handleDelete(currentId)}
        isLoading={deleteLoading}
      />
    </>
  );
};

export default List;
