import React, { useEffect, useRef, useState } from "react";
import "./NavBar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import Login from "./Login/Login";
import { useLocation, useNavigate } from "react-router";
import TrackingComponent from "../Tracking/TrackingComponent";
import { Tooltip, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { t } from "i18next";


const NavBar = ({changeLanguage}) => {
  const isAuthenticated = useSelector((state) => state.auth.user !== null);
  const [modalLogin, setModalLogin] = useState(false);
  const [countryImg, setCountryImg] = useState('/assets/img/spain.png')
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  const [windowSize, setWindowSize] = useState(undefined);
  const [showTrackingComponent, setShowTrackingComponent] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const [trackingComponentPosition, setTrackingComponentPosition] = useState({
    top: 0,
    left: 0,
  });
 
  const changeCountry = (country)=>{
    if(country === 'en'){
      setCountryImg("/assets/img/united-kingdom.png")
    }else{
      setCountryImg("/assets/img/spain.png")
    }
  }
  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    changeCountry(storedLanguage)
  }, [countryImg])
  

  const handleTrackingButtonClick = () => {
    const trackingButton = document.getElementById("tracking-button");

    if (trackingButton) {
      const rect = trackingButton.getBoundingClientRect();
      setTrackingComponentPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX + rect.width / 2 - 20, // Posición centrada bajo el botón
      });
      setShowTrackingComponent((prevValue) => !prevValue);
    }
  };

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  const openModalLogin = () => {
    if (currentUrl === "/" || currentUrl === "/inicio") {
      if (windowSize < 1024) {
        navRef.current.classList.toggle("responsive_nav");
      }

      setModalLogin(true);
    } else {
      if(currentUrl === "/mi-cuenta"){
        window.location.reload();
      }else{
        navigate("/mi-cuenta");
      }
    }
  };

  const closeModalLogin = () => {
    setModalLogin(false);
  };

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
  }, [windowSize]);

  useEffect(() => {
    if (!isMobile) {
      setShowServices(false);
    }
  }, [isMobile]);
  return (
    <header className="my-2">
      <a className="navbar-brand " href="/" aria-label="itc-home">
        <img src="/assets/img/Logo.png" alt="logo-itc" />
      </a>
      <nav ref={navRef} className="ml-auto p-3">
        <a href="/inicio" className="item-inicio">
        {t('navigate.home')}
        </a>
        <a href="/nosotros">{t('navigate.aboutUs')}</a>
        <a
          className="dropdown-toggle dd-servicio"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t('navigate.services.title')}
        </a>
        <a
          data-bs-toggle="collapse"
          href="#collapseService"
          role="button"
          aria-expanded="false"
          aria-controls="collapseService"
          className={`collapse-servicio ${showServices}`}
          onClick={isMobile ? () => setShowServices(!showServices) : () => {}}
        >
         {t('navigate.services.title')}
        </a>
        <ul className={`dropdown-menu servicio-menu `}>
          <li>
            <a
              className="dropdown-item servicio-item"
              href="/servicios#terrestre"
            >
              {t('navigate.services.service1')}
            </a>
          </li>
          <li>
            <a
              className="dropdown-item servicio-item"
              href="/servicios#maritimo"
            >
              {t('navigate.services.service2')}
            </a>
          </li>
          <li>
            <a className="dropdown-item servicio-item" href="/servicios#aereo">
            {t('navigate.services.service3')}
            </a>
          </li>
          <li>
            <a
              className="dropdown-item servicio-item"
              href="/servicios#almacenaje"
            >
              {t('navigate.services.service4')}
            </a>
          </li>
        </ul>
        <div
          className={` w-100 transition-all ${
            showServices ? "visible" : "collapse"
          }`}
          id="collapseService"
        >
          <ul className="servicio-menu ">
            <li>
              <a
                className="dropdown-item servicio-item"
                href="/servicios#terrestre"
              >
                {t('navigate.services.service1')}
              </a>
            </li>
            <li>
              <a
                className="dropdown-item servicio-item"
                href="/servicios#maritimo"
              >
                {t('navigate.services.service2')}
              </a>
            </li>
            <li>
              <a
                className="dropdown-item servicio-item"
                href="/servicios#aereo"
              >
                {t('navigate.services.service3')}
              </a>
            </li>
            <li>
              <a
                className="dropdown-item servicio-item"
                href="/servicios#almacenaje"
              >
                {t('navigate.services.service4')}
              </a>
            </li>
          </ul>
        </div>

        <a href="/contacto">{t('navigate.contact')}</a>
        {isAuthenticated ? 
         <a href="/mi-cuenta" className="fs-4">
         {" "}
         <i className="fa-solid fa-circle-user"></i>
        </a> 
          :
          <a href="#" className="btn login-btn" onClick={openModalLogin}>
          {" "}
          {t('navigate.login')}
        </a> 
        }
       
        <div className="track-div" style={{ positiion: "absolute" }}>
          <a
            className="btn track-btn"
            id="tracking-button"
            onClick={handleTrackingButtonClick}
          >
           {t('navigate.track')}
          </a>
          {/* Renderizar el componente de rastreo */}
          {showTrackingComponent && (
            <TrackingComponent
              handleOption={handleTrackingButtonClick}
              stateButton={setShowTrackingComponent}
            />
          )}
        </div>
         {/*<Tooltip title="Cambiar Idioma">
          <a
            href=""
            className="toggle btn-language border border-black p-2 rounded-full"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src={countryImg} width={20} alt="spain" />
          </a>
        </Tooltip> 
        <ul className="dropdown-menu flag-menu mt-2">
          <li>
            <a className="dropdown-item country-flag servicio-item" 
            href="#" 
            onClick={() => {
              changeLanguage('es')
              changeCountry('es')
            }}
            >
              <img src="/assets/img/spain.png" width={20} alt="spain" />
              SPANISH
            </a>
          </li>
          <li>
            <a className="dropdown-item country-flag servicio-item" 
            href="#" 
            onClick={() => {
              changeLanguage('en')
              changeCountry('en')
            }}
            >
              <img
                src="/assets/img/united-kingdom.png"
                width={20}
                alt="spain"
              />
              ENGLISH
            </a>
          </li>
          </ul>*/}
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn menu-btn" onClick={showNavbar}>
        <FaBars />
      </button>
      <Login closeModalLogin={closeModalLogin} status={modalLogin} />
    </header>
  );
};

export default NavBar;
