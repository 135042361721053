import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/actions/authActions";
import { useNavigate } from "react-router";
import { t } from "i18next";

const Login = ({ closeModalLogin, status }) => {
  const [isLoading, setIsloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.user !== null);
  const authState = useSelector((state) => state.auth);
  const { user, error } = authState;
  const navigate = useNavigate();
  const [inputError, setInputError] = useState({
    email: false,
    password: false,
  });

  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };
  const validateInputs = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validar formato de correo electrónico
    if (!emailRegex.test(credentials.email)) {
      setInputError((prev) => ({
        ...prev,
        email: `${t('modals.login.errorUser')}`,
      }));
      return false;
    } else if (emailRegex.test(credentials.email)) {
      setInputError((prev) => ({ ...prev, email: "" }));
    }

    // Validar formato de contraseña
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{}[\]:;<>,.?/\\])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    if (!passwordRegex.test(credentials.password)) {
      setInputError((prev) => ({
        ...prev,
        password:
        `${t('modals.login.errorPassword')}`,
      }));
      return false;
    } else {
      setInputError((prev) => ({ ...prev, password: "" }));
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validar inputs antes de enviar la solicitud
    if (!validateInputs()) {
      return;
    }
    // Restablecer errores de input si la validación es exitosa
    setInputError({ email: false, password: false });

    try {
      const response = await dispatch(login(credentials));
      
      if (response) {
        closeModalLogin();
        navigate("/mi-cuenta");
        
      } else {

        console.error("Error en el inicio de sesión:", response.message);
      }
    } catch (error) {
      console.error("Error al procesar la solicitud:", error);

    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  /*useEffect(() => {
    if (isAuthenticated) {
      closeModalLogin();
      navigate("/mi-cuenta");
    }
  }, [isAuthenticated]);*/

  useEffect(() => {
    console.log(error);
  }, [error]);

  const handleResetPassowrd = ()=>{
    closeModalLogin();
    navigate("/mi-cuenta", { state: { type: 'resetPassword' } });
  }
  return (
    <div className={`login-modal modal-background ${status ? "active" : ""}`}>
      {/* Mensaje */}
      <div
        className={`modal-content traslate d-flex`}
        style={{ transform: status ? "none" : "translate3d(100%, 0, 0)" }}
      >
        <div>
          <h3 className="mt-5">{t('modals.login.title')}</h3>
          {/* Agrega aquí el contenido del formulario de inicio de sesión u otros elementos necesarios */}
          <button onClick={closeModalLogin} style={{ fontSize: "24px" }}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>

        <form action="" className="row m-0 p-0 mt-5" onSubmit={handleSubmit}>
          <div className="col-12 p-2 m-0 d-flex flex-column">
            <input
              required
              className={`form-control ${inputError.email ? "error-form" : ""}`}
              value={credentials.email}
              placeholder={`${t('modals.login.user')}`}
              name="email"
              onChange={handleChange}
              onBlur={validateInputs}
              maxLength="100"
            ></input>
            {inputError.email !== "" && (
              <small className="text-danger">{inputError.email}</small>
            )}
          </div>
          <div className="col-12 p-2 m-0 d-flex align-items-center ">
            <input
              required
              className={`form-control ${
                inputError.password ? "error-form" : ""
              }`}
              value={credentials.password}
              placeholder={`${t('modals.login.password')}`}
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={handleChange}
              maxLength="100"
              onBlur={validateInputs}
            />
            {showPassword ? (
              <i
                className="fa-regular fa-eye-slash"
                style={{ marginLeft: "-30px", cursor: "pointer" }}
                onClick={togglePasswordVisibility}
              ></i>
            ) : (
              <i
                className="fa-regular fa-eye"
                style={{ marginLeft: "-30px", cursor: "pointer" }}
                onClick={togglePasswordVisibility}
              ></i>
            )}
          </div>
          {inputError.password !== "" && (
            <small className="text-danger">{inputError.password}</small>
          )}
          {error && (
            <p style={{ color: "red", fontSize: "12px", fontWeight: "600" }}>
              {error}
            </p>
          )}
          <button className="btn" type="submit" disabled={isLoading}>
            {isLoading ? (
              <ThreeDots
                height="10"
                width="400"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              `${t('modals.login.button')}`
            )}
          </button>
          <a href="#" onClick={handleResetPassowrd}>{t('modals.login.recover')}</a>
        </form>
        <div className="d-flex flex-column mt-5">
          <span style={{ fontSize: "36px" }}>
            <i className="fa-regular fa-user"></i>
          </span>
          <span>{t('modals.login.question')}</span>
          <a href="/mi-cuenta">{t('modals.login.newUser')}</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
