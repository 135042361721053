import React from "react";
import "../inputs.css";

const InputSearch = ({ value, setValue, filterLabel = "por palabra" }) => {
  return (
    <div className="container-search">
      <i className="fa-solid fa-magnifying-glass"></i>
      <input
        className="input-search"
        placeholder={`Buscar ${filterLabel}`}
        value={value}
        onChange={setValue}
      ></input>
    </div>
  );
};

export default InputSearch;
