import React, { useEffect, useState } from 'react';

import './modals.css';
import { getTiposCambio } from '../../../Services/perfilService';
import { t } from 'i18next';


const ModalTipoCambio = ({ openModalTipoCambio, setOpenModalTipoCambio }) => {
    const [exchangeRates, setExchangeRates] = useState({});
  const [baseCurrency, setBaseCurrency] = useState("MXN");
  const [displayLimit, setDisplayLimit] = useState(10);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const data = await getTiposCambio(); 
        setExchangeRates(data);
      } catch (error) {
        console.error("Error fetching exchange rates from API:", error);
      }
    };

    fetchExchangeRates();
  }, [displayLimit]);

  const handleBaseCurrencyChange = (e) => {
    setBaseCurrency(e.target.value);
  };

  const handleDisplayLimitChange = (e) => {
    setDisplayLimit(parseInt(e.target.value, 10));
    console.log(e.target.value)
  };


  const handleCloseModal = () => {
    setOpenModalTipoCambio(false);
  };


  return (
    <div>
      {openModalTipoCambio && (
        <div className='tipo-cambios-modal'>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                <div className="modal-cabecera type-save pt-1">
                  {/*<div className="modal-icon icon-save">
                    <span className='icon-container icon-check'><FiCheck /></span>
                  </div>*/}
                  <div className="header-tipo-cambio">
                    <span>{t('modals.rates.title')}</span>
                  </div>
                </div>
                <div className='tipo-cambios-body'>
                    <ul>
                        <li> <b  style={{width:'20%', color:'rgb(0, 111, 232)'}}>{t('modals.rates.currency')}</b> <span className="d-flex justify-center" style={{width:'55%'}}></span> <i> <svg  xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 28 20"><path fill="#fff" d="M0 0h28v20H0V0z"/><path fill="#E3283E" d="M18.667 0H28v20h-9.333V0z"/><path fill="#128A60" fill-rule="evenodd" d="M0 20h9.333V0H0v20z" clip-rule="evenodd"/><path fill="#8C9157" d="M12 9.333a.667.667 0 00-1.333 0H12zm.329 2.885a.668.668 0 10.67-1.153l-.67 1.153zm2.747-1.198a.667.667 0 00.718 1.123l-.718-1.123zm2.257-1.687a.667.667 0 10-1.333 0h1.333zm-6.666 0c0 1.233.67 2.31 1.662 2.885l.67-1.153A2 2 0 0112 9.333h-1.333zm5.127 2.81a3.332 3.332 0 001.54-2.81H16a2 2 0 01-.924 1.687l.718 1.123z"/><path fill="#C59262" fill-rule="evenodd" d="M14 10.667c.736 0 1.333-.896 1.333-2 0-1.105-.597-2-1.333-2s-1.333.895-1.333 2c0 1.104.597 2 1.333 2z" clip-rule="evenodd"/></svg></i></li>
                        {Object.entries(exchangeRates).slice(0, displayLimit).map(([currency, rate]) => (
                        <li key={currency}>
                            <span style={{width:'20%', color:'rgb(0, 111, 232)'}}>1 {currency} </span> <span className="d-flex justify-center" style={{width:'55%'}}><i className="fa-solid fa-money-bill-transfer"></i> </span> <b style={{width:'25%'}}>{parseFloat(rate).toFixed(3)}  {baseCurrency} </b>
                        </li>
                        ))}
                    </ul>
                    </div>
                    <label className='limites'>
                        
                        <select value={displayLimit} onChange={handleDisplayLimitChange}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        </select>
                    </label>
                <div className="modal-footer footer-save pb-0">
                  <a className='btn-acept' onClick={handleCloseModal}>{t('modals.rates.close')}</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalTipoCambio;
