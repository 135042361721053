import React from "react";
import { FiCheck } from "react-icons/fi";
import { useNavigate } from "react-router";

const CreateStatusModal = ({
  openModalUpdate,
  setOpenModalUpdate,
  type,
  returnList,
}) => {
  const navigate = useNavigate();
  const handleCloseModal = () => {
    setOpenModalUpdate(false);
  };
  const handleReturnOrderList = () => {
    if (type === "status") {
      navigate("/admin-itc/estado-pedidos");
    } else if (type === "status-create") {
      returnList();
    } else {
      navigate("/admin-itc/estado-pedidos");
    }
  };
  return (
    <div>
      {openModalUpdate && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                <div className="modal-cabecera type-save pt-1">
                  <div className="modal-icon icon-save ">
                    <span className="icon-container icon-check">
                      <FiCheck />
                    </span>
                  </div>
                  <div className="modal-question">
                    <span>
                      {type === "status"
                        ? "Estado actualizado"
                        : type === "status-create"
                        ? "Estado creado"
                        : "Estado Actualizado"}
                    </span>
                    <small>
                      Se {type === "status-create" ? "ha creado" : "actualizó"}{" "}
                      {type === "status" || type === "status-create"
                        ? "el estado"
                        : "el estado"}{" "}
                      de forma exitosa
                    </small>
                  </div>
                </div>
                <div className="modal-footer footer-save pb-0">
                  <a className="btn-acept" onClick={handleReturnOrderList}>
                    Aceptar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateStatusModal;
