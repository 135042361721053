import React, { useCallback, useEffect, useState } from "react";
import authServiceInstance from "../../../Services/authService";
import { useNavigate, useParams } from "react-router";
import { getStatus, updateStatus } from "../../../Services/status";
import { LoadingData } from "../../../Components/loaders/LoadingData";
import CreateStatusModal from "../Modales/CreateStatusModal";

const EditStatus = ({ changeTap }) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);

  const admin = authServiceInstance.getSessionAdmin();
  const token = authServiceInstance.getSessionToken();
  const [currentStatus, setCurrentStatus] = useState(null);
  const { idstatus } = useParams();
  const navigate = useNavigate();
  const returnList = () => {
    navigate("/admin-itc/estado-pedidos");
  };

  const fetchStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const statusData = await getStatus(token);

      if (statusData.success) {
        const filtered = statusData.data.find(
          (status) => status.id_status === Number(idstatus)
        );
        setName(filtered.status_name);
        setCurrentStatus(filtered);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching status:", error);
    }
  }, [token, idstatus]);

  useEffect(() => {
    if (idstatus) {
      fetchStatus();
    }
  }, [fetchStatus, idstatus]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || name?.length < 2) {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }
    const data = {
      status_name: name,
      id_user: admin?.id,
      flag_status: true,
    };
    handleUpdateStatus(data);
  };

  const handleUpdateStatus = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        await updateStatus(idstatus, data, token);
        setOpenModalUpdate(true);
        setIsLoading(false);
      } catch (error) {
        console.error("Error al actualizar el estado:", error.message);
        setIsLoading(false);
      }
    },
    [token, idstatus]
  );
  return (
    <>
      <div className="container-step">
        {isLoading && (
          <div className="my-4 mx-auto text-center">
            <p className="mb-4 text-muted"> Cargando datos...</p>
            <LoadingData />
          </div>
        )}
        {!isLoading && (
          <form className="p-0 m-0 w-100 max-w-lg mx-auto">
            <section className="section-step">
              <h3>Estado</h3>
            </section>
            <div className="section row m-0">
              <div className="mb-3 d-flex row m-0 p-0">
                <div className=" col-12">
                  <label htmlFor="name" className="form-label">
                    Nombre
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={`form-control ${
                      nameError ? "invalid-input" : ""
                    }`}
                    id="name"
                    name="name"
                    aria-describedby="name"
                    placeholder="Ingresa el nombre"
                  />
                  {nameError && (
                    <span className="text-xs text-textError">
                      El nombre es requerido
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="section-button pb-2 gap-2">
              <button
                type="button"
                className=" btn-cancelar ms-auto"
                onClick={returnList}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn-next mr-1"
                onClick={handleSubmit}
              >
                Guardar Cambios
              </button>
            </div>
          </form>
        )}
      </div>
      <CreateStatusModal
        openModalUpdate={openModalUpdate}
        setOpenModalUpdate={setOpenModalUpdate}
        type={"status"}
        returnList={returnList}
      />
    </>
  );
};

export default EditStatus;
