import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router";
import Home from "../Pages/Dashboard/Dashboard.jsx";
import Navbar from "../Components/Dashboard/Navbar/Navbar";
import Order from "../Pages/Dashboard/Orders/Order";
import Report from "../Pages/Dashboard/Reports/Report";
import User from "../Pages/Dashboard/Users/index.jsx";
import EditOrder from "../Pages/Dashboard/Orders/EditOrder.jsx";
import store from "../redux/store/index.js";
import { checkAuthenticationAdmin } from "../redux/actions/authActions.js";
import Administracion from "../Pages/Dashboard/Users/Administracion/Administracion.jsx";
import EditAdmin from "../Pages/Dashboard/Users/Administracion/EditAdmin.jsx";
import Clientes from "../Pages/Dashboard/Users/Clients/Clientes.jsx";
import EditClient from "../Pages/Dashboard/Users/Clients/EditClient.jsx";
import Setting from "../Pages/Dashboard/Setting/Setting.jsx";
import Carrier from "../Pages/Dashboard/Carrier/Carrier.jsx";
import Edit from "../Pages/Dashboard/Carrier/Edit.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Status from "../Pages/Dashboard/Status/Status.jsx";
import EditStatus from "../Pages/Dashboard/Status/EditStatus.jsx";
const DashboardRouter = () => {
  const [toggleStatus, setToggleStatus] = useState(false);
  const location = useLocation();
  const changeStatus = () => {
    setToggleStatus((prev) => !prev);
  };
  useEffect(() => {
    store.dispatch(checkAuthenticationAdmin());
  }, []);
  return (
    <>
      <Navbar changeStatus={changeStatus} currentPath={location.pathname} />
      <Routes>
        <Route exact path="/" element={<Home {...{ toggleStatus }} />} />
        <Route
          exact
          path="/ordenes"
          element={<Order {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/order/:idorder"
          element={<EditOrder {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/reportes"
          element={<Report {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/usuarios"
          element={<User {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/administracion"
          element={<Administracion {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/administracion/:idadmin"
          element={<EditAdmin {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/clientes"
          element={<Clientes {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/clientes/:idclient"
          element={<EditClient {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/perfil"
          element={<Setting {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/carrier"
          element={<Carrier {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/carrier/:idcarrier"
          element={<Edit {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/estado-pedidos"
          element={<Status {...{ toggleStatus }} />}
        />
        <Route
          exact
          path="/estado-pedidos/:idstatus"
          element={<EditStatus {...{ toggleStatus }} />}
        />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default DashboardRouter;
