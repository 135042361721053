import { api } from "./api";

export const getStatus = async (sessionToken) => {
  try {
    const response = await api.get("/order-statusv2", {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching status data");
  }
};

export const createStatus = async (data, sessionToken) => {
  try {
    const response = await api.post("/order-statusv2", data, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error creating new client");
  }
};

export const updateStatus = async (id, data, sessionToken) => {
  try {
    const response = await api.put(`/order-statusv2/${id}`, data, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating admin with id ${id}`);
  }
};

export const deleteStatus = async (id, sessionToken) => {
  try {
    const response = await api.delete(`/order-statusv2/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(`Error updating admin with id ${id}`);
  }
};
