import React from "react";
import InputSearch from "../../../../Components/Dashboard/Inputs/InputSearch/InputSearch";
import ButtonPrimary from "../../../../Components/Dashboard/Buttons/ButtonPrimary";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import "./clientes.css";
import StatusModal from "../../Modales/StatusModal";
import DeleteModal from "../../Modales/DeleteModal";
import { updateOrderStatus } from "../../../../Services/orderService";
import { useNavigate } from "react-router";
import authServiceInstance from "../../../../Services/authService";
import SuspendAdmin from "../../Modales/SuspendAdmin";
import SuspendUser from "../../Modales/SuspendUser";
import { updateAdminstatus } from "../../../../Services/clientsService";
import { LoadingData } from "../../../../Components/loaders/LoadingData";

const List = (
  { changeStatus, 
    clientsData, 
    fetchClientList, 
    isLoading,
    currentPage,
    pageSize,
    setCurrentPage,
    setStatus,
    setPageSize, 
    onPageChange  }) => {
  const [orders, setOrders] = useState();
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idUser, setIdUser] = useState(0);
  const [ordersAux, setOrdersAux] = useState();
  const [searchValue, setSearchValue] = useState();
  const [filterAll, setFilterAll] = useState(true);
  const [filterActive, setFilterActive] = useState(false);
  const [filterDeleted, setFilterDeleted] = useState(false);
  const [statusData, setStatusData] = useState({
    status: 0,
    commentary: "",
  });
  
  const token = authServiceInstance.getSessionToken();
  const [totalCount, setTotalCount] = useState();
  const [activeCount, setActiveCount] = useState();
  const [deletedCount, setDeletedCount] = useState();
  let navigate = useNavigate();

  const handleFilter = (event) => {
    setSearchValue(event.target.value);
    const newData = orders.filter((row) => {
      return (
        row.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.email.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setOrdersAux(newData);
  };
  const handleCheckboxChange = (filterType) => {
    setFilterAll(filterType === "all");
    setFilterActive(filterType === "active");
    setFilterDeleted(filterType === "deleted");
  };

  const applyFilters = () => {
    
    if (filterAll) {
      setOrdersAux(orders);
      setStatus(0)
    } else if (filterActive) {
      setStatus(1)
      const activeOrders = orders.filter((row) => row.user?.status !== 2);
      setOrdersAux(activeOrders);
    } else if (filterDeleted) {
      setStatus(2)
      const deletedOrders = orders.filter((row) => row.user?.status === 2);
      setOrdersAux(deletedOrders);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [filterAll, filterActive, filterDeleted, orders]);

  const handleDelete = async () => {
    try {
      await updateOrderStatus(idUser, statusData, token);
      setIdUser(0);
      setStatusData({ id_status: 0, commentary: "" });
      setOpenModalDelete(false);
      fetchClientList();
    } catch (error) {
      console.error("Error deleting order:", error.message);
    }
  };

  const handleStatus = async () => {
    try {
      await updateAdminstatus(idUser, statusData, token);

      setIdUser(0);
      setStatusData({ status: 0, commentary: "" });
      setOpenModalStatus(false);
      fetchClientList();
    } catch (error) {
      console.error("Error deleting order:", error.message);
    }
  };

  useEffect(() => {
    setOrders(clientsData?.data?.reverse());
    setOrdersAux(clientsData?.data);
    setTotalCount(clientsData?.totalCount);
    setActiveCount(clientsData?.data?.filter((row) => row.user?.status !== 2).length);
    setDeletedCount(clientsData?.data?.filter((row) => row.user?.status === 2).length);
    console.log("LISTADO",clientsData)
  }, [clientsData]);

  useEffect(() => {
    console.log(currentPage)
    console.log(pageSize)
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    setOrdersAux(orders?.slice(0, end));
    console.log(start, end)
    console.log("Ordenes",orders)
  }, [currentPage, pageSize, orders]);

  const customCell = (row) => {
    return (
      <div className="dropdown drop-menu">
        <button
          className="btn btn-secondary dropdown-toggle action-menu"
          type="button"
          id={`dropdownMenuButton${row.id}`}
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </button>
        <ul
          className="dropdown-menu list-menu p-0"
          aria-labelledby={`dropdownMenuButton${row.id}`}
        >
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => handleMenuClick(row, "edit")}
            >
              Ver cliente{" "}
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => handleMenuClick(row, "delete")}
            >
              {row.user.status === 1 ? "Suspender cliente" : "Activar cliente"}
            </a>
          </li>
        </ul>
      </div>
    );
  };

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.name + " " + row.last_name,
      sortable: true,
    },
    {
      name: "Teléfono",
      selector: (row) => row.number_phone,
      sortable: true,
    },
    {
      name: "Órdenes",
      selector: (row) => row.countOrder,
      sortable: true,
    },
    {
      name: "Registro",
      selector: (row) => (
        <div className="d-flex flex-column">
          <span>
            {new Date(row.updated_at).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.id_status,
      sortable: true,
      cell: (row) => (
        <div>
          {row.user?.status === 2 ? (
            <span className="status-delete">Suspendido</span>
          ) : (
            <span className="status-active">Activo</span>
          )}
        </div>
      ),
    },
    {
      name: "Acciones",
      cell: customCell,
    },
  ];

  const handleMenuClick = (user, type) => {
    // Lógica para manejar el clic en las opciones del menú
    if (type === "status") {
      setOpenModalStatus(true);
      setIdUser(user.user.id_user);
      setStatusData({
        status: user.status,
        commentary: "",
      });
    } else if (type === "delete") {
      setStatusData({
        status: 0,
        commentary: "",
      });
      setOpenModalStatus(true);
      setIdUser(user.user.id_user);
    } else if (type === "edit") {
      navigate(`/admin-itc/clientes/${user.user.id_user}`, {
        state: { data: user },
      });
    }
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        color: "#6B6B6B",
        fontWeight: 600,
        fontFamily: "Manrope",
      },
    },
    cells: {
      style: {
        fontSize: "15px",
        color: "#001222",
        fontWeight: 500,
        fontFamily: "Manrope",
      },
    },
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,

    selectAllRowsItemText: "Todos",
  };

  return (
    <>
      <SuspendUser
        openModalStatus={openModalStatus}
        setOpenModalStatus={setOpenModalStatus}
        action={handleStatus}
        setStatusData={setStatusData}
        statusData={statusData}
      />
      <DeleteModal
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        action={handleDelete}
        setStatusData={setStatusData}
        statusData={statusData}
      />
      <div className="row">
        <div className="col-12 col-sm-8 col-md-10 mt-1">
          <InputSearch
            value={searchValue}
            setValue={handleFilter}
            filterLabel="por Nombre o Correo electrónico"
          />
        </div>
        <div
          className="col-12 col-sm-4 col-md-2 mt-1"
          style={{ minHeight: "35px" }}
        >
          <ButtonPrimary change={changeStatus} text={"Añadir Cliente"} />
        </div>
        <div className="col-md-12 filters">
          <div className="item-filter">
            <span>TODOS</span>
            <input
              type="checkbox"
              className="mycheckbox"
              checked={filterAll}
              onChange={() => handleCheckboxChange("all")}
            />
            <span>({totalCount})</span>
          </div>
          <div className="item-filter">
            <span>ACTIVOS</span>
            <input
              type="checkbox"
              className="mycheckbox"
              checked={filterActive}
              onChange={() => handleCheckboxChange("active")}
            />
            <span>({clientsData.totalActives})</span>
          </div>
          <div className="item-filter">
            <span>SUSPENDIDOS</span>
            <input
              type="checkbox"
              className="mycheckbox"
              checked={filterDeleted}
              onChange={() => handleCheckboxChange("deleted")}
            />
            <span>({clientsData.totalDeleted})</span>
          </div>
        </div>
        <div className="col-md-12">
          <DataTable
            pagination
            paginationServer
            paginationTotalRows={totalCount}
            paginationPerPage={pageSize}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangePage={onPageChange}
            
            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
              setPageSize(currentRowsPerPage);
              setCurrentPage(currentPage);
            }}
            columns={columns}
            noDataComponent={"No hay registros para mostrar"}
            paginationComponentOptions={paginationComponentOptions}
            data={ordersAux}
            customStyles={customStyles}
            progressPending={isLoading}
            progressComponent={
              <div className="my-6 mx-auto">
                <p className="mb-3">Cargando Datos...</p>
                <LoadingData />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default List;
