export const getDate = (fechaIso, useTime = false) => {
  const fechaObjeto = new Date(fechaIso);
  const meses = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  const hora = fechaObjeto.getUTCHours();
  const minutos = fechaObjeto.getMinutes();

  const fechaFormateada =
    meses[fechaObjeto.getMonth()] +
    " " +
    fechaObjeto.getDate() +
    ", " +
    fechaObjeto.getFullYear();

  if (!useTime) {
    return fechaFormateada;
  } else {
    return (
      fechaFormateada +
      " " +
      (hora < 10 ? "0" : "") +
      hora +
      ":" +
      (minutos < 10 ? "0" : "") +
      minutos
    );
  }
};

export const getFormatTime = (fechaIso) => {
  const fechaObjeto = new Date(fechaIso);
  let horas = fechaObjeto.getUTCHours();
  let minutos = fechaObjeto.getUTCMinutes();
  const amPm = horas >= 12 ? "PM" : "AM";
  horas = horas % 12;
  horas = horas ? horas : 12;
  horas = horas < 10 ? "0" + horas : horas;
  minutos = minutos < 10 ? "0" + minutos : minutos;
  const horaFormateada = horas + ":" + minutos + " " + amPm;
  return horaFormateada;
};
