import React, { useEffect, useState } from 'react'
import { getPerfilOrders } from '../../../../Services/perfilService';
import { useSelector } from 'react-redux';
import Orden from './Orden';
import List from './List';
import { t } from 'i18next';

const Embarques = () => {
  const [orders, setOrders] = useState()
  const authState = useSelector((state) => state.auth);
  const { user, error } = authState;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchClientOrders = async () => {
      setIsLoading(true);
      try {
        const clientData = await getPerfilOrders(user.user_id);
        console.log(clientData.data)
        setOrders(clientData.data);
      } catch (error) {
  
        console.error("Error fetching countries:", error);
      }
      setIsLoading(false);
    };
    
    fetchClientOrders();
  }, []);
  return (
    <div>
        <h2>{t('myAccount.orders.title')}</h2>
        {/*
          orders?.map((order,index)=>(
            <Orden order={order}  key={index}/>
          ))*/
        }
        <List listOrders={orders} userId={user.user_id} isLoading={isLoading}/>
          
        
    </div>
  )
}

export default Embarques