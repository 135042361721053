import { t } from 'i18next'
import React from 'react'

const PoliticaDeGarantias = () => {
  return (
    <div className="politicsPage">
    <div className="slider">
    <h1>{t('guarantee.title')}</h1>
      <span>{t('guarantee.breadcrumb.prev')} {'>'} {t('guarantee.breadcrumb.section')} {'>'} {t('guarantee.breadcrumb.name')}</span>
    </div>
    <div className="politicas">
        <div className="container">
            
            <div className="politicas-contenido" dangerouslySetInnerHTML={{ __html: t('guarantee.content') }} />
            
        </div>
    </div>
  </div>
  )
}

export default PoliticaDeGarantias