import React, { useCallback, useEffect, useMemo, useState } from "react";
import CardDash from "./CardDash";
import authServiceInstance from "../../../Services/authService";

import { getTotalsKPI } from "../../../Services/dashboardService";

const CardsInfo = () => {
  const [infoCard, setInfoCard] = useState(null);
  const token = authServiceInstance.getSessionToken();

  const fetchKPI = useCallback(async () => {
    try {
      const info = await getTotalsKPI(token, "month");

      if (info.success) {
        setInfoCard(info.data);
      } else {
        setInfoCard(undefined);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  }, [token]);

  useEffect(() => {
    fetchKPI();
  }, [fetchKPI]);

  return (
    <div className="grid md:grid-cols-3 grid-cols-1 w-full px-3 md:px-5 gap-3">
      <CardDash
        icon={<i className="fa-solid fa-book text-[12px] text-white"></i>}
        title="Total de órdenes"
        ammount={infoCard?.orders.total ?? 0}
        percent={infoCard?.orders.percentageChange ?? 0}
        classN={"ml-0"}
        sign={infoCard?.orders.sign ?? "positive"}
      />
      <CardDash
        icon={<i className="fa-solid fa-user text-[12px] text-white"></i>}
        title="Total de clientes"
        ammount={infoCard?.clients.total ?? 0}
        percent={infoCard?.clients.percentageChange ?? 0}
        sign={infoCard?.clients.sign ?? "positive"}
      />
      <CardDash
        icon={<i className="fa-solid fa-book-open text-[12px] text-white"></i>}
        title="Total de órdenes completadas"
        ammount={infoCard?.completedOrders.total ?? 0}
        percent={infoCard?.completedOrders.percentageChange ?? 0}
        sign={infoCard?.completedOrders.sign ?? "positive"}
      />
    </div>
  );
};

export default CardsInfo;
