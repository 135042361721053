async function filesToBase64Array(files, callback) {
  // Verifica si el array de archivos es vacío
  if (files.length === 0) {
    console.error("El array de archivos está vacío.");
    callback([]);
    return;
  }

  // Crea un array para almacenar las cadenas base64
  var base64Array = [];

  // Contador para realizar un seguimiento de las lecturas de archivos completadas
  var filesRead = 0;

  // Función de devolución de llamada para manejar la lectura de archivos completada
  function handleFileRead(base64String, name) {
    base64Array.push({ url: base64String, name: name });
    filesRead++;

    // Verifica si se han leído todos los archivos
    if (filesRead === files.length) {
      // Llama a la devolución de llamada con el array de cadenas base64
      callback(base64Array);
    }
  }

  // Itera sobre cada elemento en el array
  for (var i = 0; i < files.length; i++) {
    var file = files[i];

    // Verifica si el elemento es una cadena
    if (typeof file.url === "string") {
      // Almacena la cadena directamente sin convertirla
      handleFileRead(file.url, file.name);
    } else if (file instanceof File) {
      const newName = file.name.split(" ").join("_");

      // Si es un objeto de tipo File, crea un lector de archivos
      let reader = new FileReader();

      // Configura la función que se ejecutará cuando la lectura se complete
      reader.onload = function (e) {
        // e.target.result contiene la representación base64 del archivo
        var base64String = e.target.result;

        // Llama a la función de devolución de llamada con la cadena base64
        handleFileRead(base64String, newName);
      };

      // Lee el archivo como una cadena base64
      reader.readAsDataURL(file);
    } else if (file.url instanceof Blob) {
      // Si es un Blob (archivo), crea un lector de archivos
      var reader = new FileReader();

      // Configura la función que se ejecutará cuando la lectura se complete
      reader.onload = function (e) {
        // e.target.result contiene la representación base64 del archivo
        var base64String = e.target.result;

        // Llama a la función de devolución de llamada con la cadena base64
        handleFileRead(base64String);
      };

      // Lee el archivo como una cadena base64
      reader.readAsDataURL(file.url);
    } else if (typeof file === "object" && file.url) {
      // Si el elemento es un objeto con una propiedad 'url'
      // Puedes manejarlo de la manera que desees, aquí simplemente agregamos la URL al array
      handleFileRead(file.url);
    } else if (typeof file === File) {
    } else {
      // Si no es ni cadena ni Blob ni objeto con 'url', muestra un mensaje de error
      console.error(
        "El elemento no es ni cadena ni Blob ni objeto con URL:",
        file
      );
    }
  }
}

export { filesToBase64Array };
