import { t } from 'i18next'
import React from 'react'
import './Footer.css'

const year = new Date().getFullYear()

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer ">
          <div className="resumen">
            <h3>ITC Consultancy</h3>
            <div className="row p-0">
              <div className="col-6 p-0 resumen-imagenes">
                <img src="/assets/img/img-1.png" alt="img-1" />
                <img src="/assets/img/img-2.png" alt="img-2" />
                <img src="/assets/img/img-3.png" alt="img-3" />
                <img src="/assets/img/img-4.png" alt="img-4" />
                <img src="/assets/img/img-5.png" alt="img-5" />
                <img src="/assets/img/img-6.png" alt="img-6" />
              </div>
              <div className="col-6 p-0">
                <div className='resumen-text'>
                {t('footer.description')}
                </div>
                <div className="resumen-icons">
                  <a href="https://www.facebook.com/profile.php?id=61551280761271" target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
                  <a href="https://www.instagram.com/itcconsultancy" target="_blank"><i className="fa-brands fa-instagram"></i></a>
                  <a href="https://www.linkedin.com/in/itc-international-trade-consultancy" target="_blank"><i className="fa-brands fa-linkedin-in"></i></a>
                  <a href="https://www.tiktok.com/@itcconsultancy" target="_blank"><i className="fa-brands fa-tiktok"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div className="servicios">
            <h3>{t('footer.services.title')}</h3>
            <ul>
              <li><a href="/servicios#terrestre">{t('footer.services.service1')}</a></li>
              <li><a href="/servicios#maritimo">{t('footer.services.service2')}</a></li>
              <li><a href="/servicios#aereo">{t('footer.services.service3')}</a></li>
              <li><a href="/servicios#almacenaje">{t('footer.services.service4')}</a></li>
            </ul>
          </div>
          <div className="contacto ">
            <h3>{t('footer.contact.title')}</h3>
            <div className='d-flex'>
              <div><span className='point'></span></div>
              <div>
              <span className='contacto-titulo'>Dirección: </span>
              <span>Av. Ferrocarril </span>
                Industrial 211 Col. Moctezuma 2da Secc. C.P. 15520, Cd. de México
              </div>
            </div>
            <div className='d-flex '>
              <div><span className='point'></span></div>
              <div>
              <span className='contacto-titulo'>Email: </span>
              <span>sales@itcconsultancy.com</span>
              </div>
            </div>
            <div className='d-flex'>
              <div><span className='point'></span></div>
              <div><span className='contacto-titulo'>Teléfono: </span>
              01 (55) 7157-6310</div>
            </div>
          </div>
          <div className="legal">
            <h3 style={{width:'200px'}}>{t('footer.legal.title')}</h3>
            <ul>
              <li><a href="/politicas/aviso-de-privacidad">{t('footer.legal.legal1')}</a></li>
              <li><a href="/politicas/terminos-y-condiciones">{t('footer.legal.legal2')}</a></li>
              <li><a href="/politicas/politica-de-garantia">{t('footer.legal.legal3')}</a></li>
            </ul>
          </div>
          <div className="social">
            <div className="resumen-icons">
                <a href=""><i className="fa-brands fa-facebook-f"></i></a>
                <a href=""><i className="fa-brands fa-twitter"></i></a>
                <a href=""><i className="fa-brands fa-linkedin-in"></i></a>
                <a href=""><i className="fa-brands fa-youtube"></i></a>
              </div>
          </div>
        </div>
        <div className="copy-right text-center">
          <span>© Copyright {year} ITC Consultancy</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer