import React from 'react'
import { Route, Routes } from 'react-router'
import AvisoDePrivacidad from '../Pages/Politicas/AvisoDePrivacidad'
import TerminosYCondiciones from '../Pages/Politicas/TerminosYCondiciones'
import PoliticaDeGarantias from '../Pages/Politicas/PoliticaDeGarantias'
import PoliticaDeDevolucionesYReembolsos from '../Pages/Politicas/PoliticaDeDevolucionesYReembolsos'
import OtrasPoliticas from '../Pages/Politicas/OtrasPoliticas'

const PoliticasRoutes = () => {
  return (
    <Routes>
    <Route path="aviso-de-privacidad" element={<AvisoDePrivacidad />} />
    <Route path="terminos-y-condiciones" element={<TerminosYCondiciones />} />
    <Route path="politica-de-garantia" element={<PoliticaDeGarantias />} />
    <Route path="politica-de-devoluciones-y-reembolsos" element={<PoliticaDeDevolucionesYReembolsos />} />
    <Route path="otras-politicas" element={<OtrasPoliticas />} />
  </Routes>
  )
}

export default PoliticasRoutes