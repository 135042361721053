import { api } from "./api";

export const getClientData = async (sessionToken) => {
  try {
    const response = await api.get("/clients", {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Error fetching client data");
  }
};
export const getClientByName = async (name, sessionToken) => {
  try {
    const response = await api.get(`/clients/${name}`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching client data for ${name}`);
  }
};

export const getClientById = async (id, sessionToken) => {
  try {
    const response = await api.get(`/clients-by-id/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(`Error fetching client data for ${id}`);
  }
};

export const createClient = async (data) => {
  try {
    const response = await api.post("/clients/register", data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      const { message, errors } = error.response.data;
      console.error(message);
      console.error(errors);
    }
    throw new Error( error.response.data.errors[0].msg);
  
  }
};

export const requestPasswordReset = async (data) => {
  try {
    const response = await api.post("/clients/request-password-reset", data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error( error.response.data.message);
    }
    throw new Error( 'Error Servidor ');
  }
};

export const resetPasswordClient = async (data) => {
  try {
    const response = await api.post("/clients/reset-password", data);
    return response.data;
  } catch (error) {
    throw new Error("Error reset password");
  }
};
