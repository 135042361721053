import React, { useState } from "react";
import { BiSolidDashboard } from "react-icons/bi";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/admin-itc",
    icon: <BiSolidDashboard />,
    cName: "nav-text",
  },
  {
    title: "Ordenes",
    path: "/admin-itc/ordenes",
    icon: <i className="fa-solid fa-clipboard"></i>,
    cName: "nav-text",
  },
  {
    title: "Usuarios",
    icon: <i className="fa-regular fa-circle-user"></i>,
    cName: "nav-text",
    submenus: [
      {
        title: "Clientes",
        path: "/admin-itc/clientes",
        cName: "nav-text-submenu",
      },
      {
        title: "Administración",
        path: "/admin-itc/administracion",
        cName: "nav-text-submenu",
      },
    ],
  },
  {
    title: "Reportes",
    path: "/admin-itc/reportes",
    icon: <i className="fa-solid fa-book-open"></i>,
    cName: "nav-text",
  },
];

export const OtherData = [
  {
    title: "Perfil",
    path: "/admin-itc/perfil",
    icon: <i className="fa-solid fa-user"></i>,
    cName: "nav-text",
  },
  {
    title: "Configuración",
    path: "/admin-itc/configuracion",
    icon: <i className="fa-solid fa-gear"></i>,
    cName: "nav-text",
    submenus: [
      {
        title: "Estado de Pedidos",
        path: "/admin-itc/estado-pedidos",
        cName: "nav-text-submenu",
      },
      {
        title: "Carrier",
        path: "/admin-itc/carrier",
        cName: "nav-text-submenu",
      },
    ],
  },
  {
    title: "Cerrar sesión",
    path: "/admin-itc/ordenes",
    icon: <i className="fa-solid fa-right-to-bracket"></i>,
    cName: "nav-text",
  },
];


export const OtherDataAdmin = [
  {
    title: "Configuración",
    path: "/admin-itc/configuracion",
    icon: <i className="fa-solid fa-gear"></i>,
    cName: "nav-text",
    submenus: [
      {
        title: "Estado de Pedidos",
        path: "/admin-itc/estado-pedidos",
        cName: "nav-text-submenu",
      },
      {
        title: "Carrier",
        path: "/admin-itc/carrier",
        cName: "nav-text-submenu",
      },
    ],
  },
  {
    title: "Cerrar sesión",
    path: "/admin-itc/ordenes",
    icon: <i className="fa-solid fa-right-to-bracket"></i>,
    cName: "nav-text",
  },
];
