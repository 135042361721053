import { api } from "./api";

export const getCarrier = async (sessionToken) => {
  try {
    const response = await api.get("/carrier", {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching carriers data");
  }
};

export const createCarrier = async (data, sessionToken) => {
  try {
    const response = await api.post("/carrier", data, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error creating new client");
  }
};

export const updateCarrier = async (id, data, sessionToken) => {
  try {
    const response = await api.put(`/carrier/${id}`, data, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating admin with id ${id}`);
  }
};

export const deleteCarrier = async (id, sessionToken) => {
  try {
    const response = await api.delete(`/carrier/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(`Error updating admin with id ${id}`);
  }
};
