import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Services from "../Pages/Services";
import Contact from "../Pages/Contact/Contact";
import Traking from "../Pages/Tracking/Traking";
import Micuenta from "../Pages/MiCuenta/Micuenta";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Login from "./Pages/Login/Login";

import store from "../redux/store";
import { checkAuthentication } from "../redux/actions/authActions";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import PoliticasRoutes from "./PoliticasRoutes";
import TawkToChat from "../Components/TawkTo/TawkToChat";
import i18n from "../Translate/i18n";
import { I18nextProvider, useTranslation } from "react-i18next";

const WebRouter = () => {
  const { i18n } = useTranslation();
  const [forceUpdate, setForceUpdate] = useState(0);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };
  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    const defaultLanguage = storedLanguage || 'es';
    i18n.changeLanguage(defaultLanguage);
    localStorage.setItem('language', defaultLanguage);
  
    store.dispatch(checkAuthentication());
  }, []);

  useEffect(() => {
    
    setForceUpdate((prev) => prev + 1);
  }, [i18n.language]);

  return (
    <>
    <I18nextProvider i18n={i18n}>
      <Header changeLanguage={changeLanguage}/>
      
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/inicio" element={<Home />} />
        <Route exact path="/nosotros" element={<About />} />
        <Route exact path="/servicios" element={<Services />}>
          <Route index element={<Navigate to="#almacenaje" />} />
        </Route>
        <Route path="/politicas/*" element={<PoliticasRoutes />} />
        <Route exact path="/contacto" element={<Contact />} />
        <Route exact path="/tracking/:trackingId" element={<Traking />} />
        <Route exact path="/reset-password/:resetToken" element={<ResetPassword />} />
        <Route exact path="/mi-cuenta" element={<Micuenta />} />
        <Route exact path="/login" element={<Login />} />
      </Routes>
      <TawkToChat/>
      <Footer />
      </I18nextProvider>
    </>
  );
};

export default WebRouter;
