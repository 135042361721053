import React, { useState, useEffect } from "react";
import Layout from "../../../Components/Dashboard/Layout/Layout";
import List from "./List";
import Create from "./Create";
import { getListOrders } from "../../../Services/orderService";
import authServiceInstance from "../../../Services/authService";

const Order = ({ toggleStatus }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); 
  const [searchTerm, setSearchTerm] = useState()
  const token = authServiceInstance.getSessionToken();

  const changeStatus = () => {
    setIsCreate((prev) => !prev);
  };

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const ordersData = await getListOrders(token, currentPage, pageSize, searchTerm);
      console.log(ordersData);
      setOrders(ordersData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching orders:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage, pageSize, searchTerm]); 

  return (
    <div className="container-report" style={{ backgroundColor: "#F4F6F8" }}>
      <div className={toggleStatus ? "orders" : "orders active"}>
        <Layout>
          {!isCreate ? (
            <List
              changeStatus={changeStatus}
              listOrders={orders}
              fetchOrders={fetchOrders}
              isLoading={isLoading}
              pageSize={pageSize}
              setPageSize = {setPageSize}
              searchTerm = {searchTerm}
              setSearchTerm = {setSearchTerm}
              currentPage={currentPage}
              setCurrentPage= {setCurrentPage}
              onPageChange={handlePageChange}
            />
          ) : (
            <Create changeTap={changeStatus} fetchOrders={fetchOrders} />
          )}
        </Layout>
      </div>
    </div>
  );
};

export default Order;