import React from 'react'
import { FiCheck } from "react-icons/fi";
import './modals.css'
import { useNavigate } from 'react-router';

const UpdateAddress = ({ openModalUpdate, setOpenModalUpdate, type,returnList}) => {
  const navigate = useNavigate();
  const handleCloseModal = () => {
      setOpenModalUpdate(false);
  };
  const handleReturnOrderList = ()=>{
    returnList()

  }
  return (
    <div>
      
      {openModalUpdate && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                  <div className="modal-cabecera type-save pt-1">
                    <div className="modal-icon icon-save ">
                        <span className='icon-container icon-check'><FiCheck /></span>
                    </div>
                    <div className="modal-question">
                        <span>Dirección Actualizada</span>
                        <small>Se actualizó la dirección de forma exitosa</small>
                    </div>
                  </div>
                  <div className="modal-footer footer-save pb-0">
                    <a className='btn-acept' onClick={handleReturnOrderList}>Aceptar</a>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
  </div>
  )
}

export default UpdateAddress