import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { getOrdersDashboard } from "../../../Services/dashboardService";
import authServiceInstance from "../../../Services/authService";
import { LoadingData } from "../../loaders/LoadingData";

const OrdersChart = () => {
  const [filter, setFilter] = useState("week");
  const [infoCard, setInfoCard] = useState(null);
  const token = authServiceInstance.getSessionToken();
  const [isLoading, setIsLoading] = useState(false);

  const initialStartDate = useMemo(() => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 3);
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    return `${currentYear}-${currentMonth}-${currentDay}`;
  }, []);

  const initialEndDate = useMemo(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    return `${currentYear}-${currentMonth}-${currentDay}`;
  }, []);
  const [from, setFrom] = useState(initialStartDate);
  const [to, setTo] = useState(initialEndDate);

  const fetchKPI = useCallback(async () => {
    setIsLoading(true);
    try {
      const info = await getOrdersDashboard(token, from, to);

      if (info.success) {
        setInfoCard(info.data);
        
        setTimeout(() => {
          setIsLoading(false);
        }, 400);
      } else {
        setInfoCard(null);
        setTimeout(() => {
          setIsLoading(false);
        }, 400);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  }, [token, from, to]);

  useEffect(() => {
    if (filter) {
      fetchKPI();
    }
  }, [fetchKPI, filter]);

  const dataChart = useMemo(() => {
    if (infoCard) {
      const ordersByTimeFrame = infoCard.ordersByTimeFrame;

      return ordersByTimeFrame;
    } else {
      return [];
    }
  }, [infoCard]);

  const dataMapping = useMemo(() => {
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    if (dataChart.length > 0) {
      const formattedData = dataChart.map((item) => {
        const dateParts = item.name.split("-");
        const year = dateParts[0];
        const month = months[parseInt(dateParts[1], 10) - 1];
        const day = dateParts[2];

        const formattedDate = `${month} ${day}, ${year}`;

        return { name: formattedDate, total: item.total };
      });
      return formattedData;
    }
    return [];
  }, [dataChart]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-secondaryC flex items-start flex-col rounded-xl shadow-md py-1 px-7">
          <p className="text-white mb-0"> {payload[0].value}</p>
        </div>
      );
    }

    return null;
  };
  const handleDateChange = (field, value) => {
    const currentDate = new Date();
    const selectedDate = new Date(value);

    if (isNaN(selectedDate.getTime())) {
      // Si la fecha ingresada manualmente es inválida, no actualices los estados
      return;
    }

    let newFromDate = from;
    let newToDate = to;

    // Almacena las fechas originales antes de realizar cualquier cambio
    const originalFromDate = new Date(from);
    const originalToDate = new Date(to);

    if (field === "from") {
      newFromDate = value;

      // Aplica el cambio solo si la fecha original está dentro del rango de 3 meses
      if (originalToDate - originalFromDate <= 3 * 30 * 24 * 60 * 60 * 1000) {
        const maxToDate = new Date(currentDate);
        maxToDate.setMonth(currentDate.getMonth() + 3);
        newToDate = maxToDate.toISOString().split("T")[0];
      }
    } else if (field === "to") {
      newToDate = value;

      // Aplica el cambio solo si la fecha original está dentro del rango de 3 meses
      if (originalToDate - originalFromDate <= 3 * 30 * 24 * 60 * 60 * 1000) {
        const minFromDate = new Date(currentDate);
        minFromDate.setMonth(currentDate.getMonth() - 3);
        newFromDate = minFromDate.toISOString().split("T")[0];
      }
    }

    setFrom(newFromDate);
    setTo(newToDate);
  };
  return (
    <div className="w-full bg-white rounded-xl px-6 py-3 container-layout h-auto flex flex-col mb-3">
      <div className="flex md:flex-row flex-col justify-between w-full items-center md:px-4 md:pr-[28px]">
        <span className="text-xl font-semibold">Órdenes de Servicio</span>
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full md:w-auto">
            <label htmlFor="from" className="text-xs text-muted">
              Desde:
            </label>
            <input
              type="date"
              id="from"
              name="from"
              aria-describedby="from"
              className="form-control"
              value={from}
              onChange={(e) => handleDateChange("from", e.target.value)}
              max={to}
            />
          </div>
          <div className="w-full md:w-auto">
            <label htmlFor="from" className="text-xs text-muted">
              Hasta:
            </label>
            <input
              type="date"
              className="form-control"
              id="to"
              name="to"
              aria-describedby="to"
              value={to}
              onChange={(e) => handleDateChange("to", e.target.value)}
              min={from}
            />
          </div>
          {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel>Filtrar por:</InputLabel>
            <Select
              placeholder="Semana"
              variant="filled"
              name="color"
              value={filter}
              onChange={(e) => onChangeSem(e.target.value)}
            >
              <MenuItem value={"year"}>Año</MenuItem>
              <MenuItem value={"month"}>Mes</MenuItem>
              <MenuItem value={"week"}>Semana</MenuItem>
            </Select>
          </FormControl> */}
        </div>
      </div>
      <div className=" h-60 md:h-[350px]">
        {isLoading && (
          <div className="mx-auto flex flex-col w-full text-center justify-center">
            <p className="mb-4">Cargando datos...</p>
            <LoadingData />{" "}
          </div>
        )}
        {!infoCard && (
          <span className="text-center text-muted">
            No tienes datos suficientes para mostrar el gráfico
          </span>
        )}
        {(infoCard !== null && infoCard?.ordersByTimeFrame?.length!==0) && !isLoading && (
          <ResponsiveContainer height="90%">
            <AreaChart
              width={800}
              height={300}
              data={dataMapping}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Brush dataKey="name" height={30} stroke="#1A1449" />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="total"
                stroke="#3FA8DF"
                fill="#3FA8DF"
              />
            </AreaChart>
          </ResponsiveContainer>
        ) 
      }
      {
        infoCard?.ordersByTimeFrame?.length===0 && !isLoading && (
           <span className="w-100 d-block text-center text-muted"> No se encontraron resultados</span>
        )
      }
      </div>
    </div>
  );
};

export default OrdersChart;
