import React from 'react'

import { FiAlertOctagon} from "react-icons/fi";
import { useNavigate } from 'react-router';

const ErrorContact = ({ openModalError, setOpenError,changeState}) => {
  const navigate = useNavigate();
    const handleCloseModal = () => {
        setOpenError(false);
    };
    const handleReturn = ()=>{
      navigate('/')
    }
  return (
    <div>

      {openModalError && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                  <div className="modal-cabecera type-save pt-1">
                    <div className="modal-icon icon-save ">
                        <span className='icon-container icon-check'><FiAlertOctagon /></span>
                    </div>
                    <div className="modal-question">
                        <span>ERROR</span>
                        <small>Hubo un error al enviar el mensaje.<br/> Por favor intentelo mas tarde.</small>
                    </div>
                  </div>
                  <div className="modal-footer footer-save pb-0">
                    <a className='btn-acept' onClick={handleReturn}>Aceptar</a>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ErrorContact