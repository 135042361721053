import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { useSelector } from 'react-redux';
import ImageUploader from '../../Dashboard/Inputs/Image/ImageUploader';
import ModalImgProfile from '../../../Pages/Dashboard/Modales/ModalImgProfile';
import Spinner from '../../loaders/Spinner';
import { updateClientImgProfile } from '../../../Services/perfilService';
import { t } from 'i18next';

const Sidebar = ({onItemSelected, handleLogout,selectedItem }) => {
    const authState = useSelector((state) => state.auth);
    const { user } = authState;
    const [dataProfile, setDataProfile] = useState({})
    const [openModalCreate, setOpenModalImgProfile] = useState(false);
    const [loader, setLoader] = useState(false);

    const UpdateImg = async (image) => {
      setLoader(true)
      try {
        const response = await updateClientImgProfile(user.user_id,image);

        actualizarLocalStorage(response.profile_picture_url)
      
      } catch (error) {
        console.error("Error updating address:", error.message);
      }
      setLoader(false)
      
    };
    useEffect(() => {
      
      obtenerUsuario()

    }, [])

    const obtenerUsuario = ()=>{
      const usuarioAlmacenado = JSON.parse(localStorage.getItem('user'));
      setDataProfile({
        user_name:usuarioAlmacenado.username,
        user_email:usuarioAlmacenado.email,
        user_photo:usuarioAlmacenado.profile_picture_url
      })
    }
    const actualizarLocalStorage = (image)=>{
      const usuarioAlmacenado = JSON.parse(localStorage.getItem('user'));
  
      if (usuarioAlmacenado) {
        usuarioAlmacenado.profile_picture_url = image;
        localStorage.setItem('user', JSON.stringify(usuarioAlmacenado));
        obtenerUsuario()
      } else {
        console.log('No se encontró el objeto en localStorage');
      }
    }
    
  return (
    <>
    <ModalImgProfile 
      openModalImgProfile={openModalCreate}
      setOpenModalImgProfile={setOpenModalImgProfile}
      action={UpdateImg} 
    />
    {loader && <Spinner />}
    <nav className='nav-perfil'>
        <div className="perfil-detail">
          <div className="img-perfil"
            style={{cursor:'pointer'}} 
            onClick={(e)=>{
              e.preventDefault();
              e.stopPropagation();
              setOpenModalImgProfile(true)
              }
              }>
            <ImageUploader dataImg={dataProfile?.user_photo} />
          </div>
          <div className='d-flex flex-column data-perfil'>
            <span><b>{dataProfile?.user_name}</b></span>
            <small>{dataProfile?.user_email}</small>
            <a href="" onClick={handleLogout}>
                {t('myAccount.logout')}
            </a>
          </div>
          <hr className='m-0'/>
        </div>
      <ul>
        <li onClick={() => onItemSelected('home')} className={selectedItem ==='home' && 'selected'}>
            <i className="fa-solid fa-house"></i> <span>{t('myAccount.options.desk')}</span>
        </li>
        <li onClick={() => onItemSelected('embarques')} className={selectedItem ==='embarques' && 'selected'}>
            <i className="fa-solid fa-file"></i> <span>{t('myAccount.options.orders')}</span>
        </li>
        <li onClick={() => onItemSelected('cuenta')} className={selectedItem ==='cuenta' && 'selected'}>
            <i className="fa-solid fa-user-pen"></i> <span>{t('myAccount.options.edit')}</span>
        </li>
        <li onClick={() => onItemSelected('direccion')} className={selectedItem ==='direccion' && 'selected'}>
            <i className="fa-solid fa-pen-to-square"></i> <span>{t('myAccount.options.address')}</span>
        </li>
        
      </ul>
    </nav>
    </>
  );
};

export default Sidebar;