import { api } from './api';

  export const getOrderStatus = async (sessionToken) => {
    try {
      const response = await api.get('/order-status', {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      
      return response.data;
    } catch (error) {
      throw new Error('Error fetching order status data');
    }
  };

  export const getListAdmin = async (sessionToken, pageNumber, pageSize, searchTerm, status) => {
    try {
      const response = await api.get('/admin', {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
        params: {
          page: pageNumber,
          pageSize: pageSize,
          search: searchTerm,
          status: status
        }
      });
      
      return response.data;
    } catch (error) {
      throw new Error('Error fetching list of admins');
    }
  };

  export const createAdmin = async (adminData,sessionToken) => {
    try {
      const response = await api.post('/admin', adminData, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Error creating new admin');
    }
  };

    export const updateAdminstatus = async (idUserAdmin, statusData,sessionToken) => {
      try {
        const response = await api.patch(`/admin/${idUserAdmin}`, statusData, {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        });

        
        return response.data;
      } catch (error) {
        throw new Error('Error updating admin status');
      }
    };
    
    export const getAdminById = async (idUserAdmin,sessionToken) => {
      try {
        const response = await api.get(`/admin/${idUserAdmin}`,{
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        });
        return response.data;
      } catch (error) {
        return [];
        //throw new Error('Error fetching order by ID');
      }
    };

    export const getTrackById = async (idServiceOrden) => {
      try {
        const response = await api.get(`/orders/tracking/${idServiceOrden}`);
        return response.data;
      } catch (error) {
        return [];
        //throw new Error('Error fetching order by ID');
      }
    };

    export const updateAdmin = async (idServiceOrden, updatedAdminData,sessionToken) => {
      try {
        const response = await api.put(`/admin/${idServiceOrden}`, updatedAdminData,{
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          }
        });
        return response.data;
      } catch (error) {
        throw new Error(`Error updating admin with id ${idServiceOrden}`);
      }
    };