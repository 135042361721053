import React, { useMemo } from "react";

import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";
import { getDate, getFormatTime } from "../../../../utils/dates";
import InputSearch from "../../../Dashboard/Inputs/InputSearch/InputSearch";
import ButtonPrimary from "../../../Dashboard/Buttons/ButtonPrimary";
import { LoadingData } from "../../../loaders/LoadingData";
import { t } from "i18next";
import { api } from "../../../../Services/api";
import authServiceInstance from "../../../../Services/authService";




const List = ({ userId, listOrders, isLoading }) => {
  const [orders, setOrders] = useState();
  const token = authServiceInstance.getSessionToken();
  const [ordersAux, setOrdersAux] = useState();
  const [searchValue, setSearchValue] = useState();
  const admin = authServiceInstance.getSessionAdmin();

  let navigate = useNavigate();

  const handleFilter = (event) => {
    setSearchValue(event.target.value);
    const newData = orders?.filter((row) => {
      return (
        row.order_number
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
    });
    setOrdersAux(newData);
  };
 

  useEffect(() => {
   
  }, [orders]);

  useEffect(() => {
    setOrders(listOrders);
    setOrdersAux(listOrders);

  }, [listOrders]);
  const handledTracking = (number)=>{
    navigate(`/tracking/${number}`)
  }

  const downloadExcelReport = async () => {
    
    console.log("parametros:",userId,token)
    await api
      .get(
        `/orders-report/download-excel-report-tracking?startDate=${from}&endDate=${to}&carrier_id=0&id_user=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "ReporteDeOrdenes.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Hubo un problema con la solicitud de descarga:", error);
      });
  };

  const initialStartDate = useMemo(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() - 1;
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    return `${currentYear}-${currentMonth}-${currentDay}`;
  }, []);
  const initialEndDate = useMemo(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    return `${currentYear}-${currentMonth}-${currentDay}`;
  }, []);
  const [from, setFrom] = useState(initialStartDate);
  const [to, setTo] = useState(initialEndDate);

  const columns = [
    {
      name: `${t('myAccount.orders.columns.column1')}`,
      selector: (row) => "#" + row.order_number,
      sortable: true,
    },
    {
      name: `${t('myAccount.orders.columns.column2')}`,
      sortable: true,
      selector: (row) => (
        <div className="d-flex flex-column">
          <span>{getDate(row.created_at)}</span>
          <small>{getFormatTime(row.created_at)}</small>
        </div>
      ),
    },
    
    {
      name: `${t('myAccount.orders.columns.column3')}`,
      selector: (row) => row.id_status,
      sortable: true,
      cell: (row) => (
        <div className="">
          {row.id_status === 39 ? (
            <span className="status-delete">Eliminado</span>
          ) : row.id_status === 16 ? (
            <span className="text-ellipsis inline-block max-w-[220px] border-cancel bg-[#F4FFFF] overflow-hidden whitespace-nowrap text-sm text-textError py-1.5 px-3 rounded-[10px]">
              Cerrado
            </span>
          ) : (
            <span className="status-active status-tracking">{row.status.status_name}</span>
          )}
        </div>
      ),
    },
    {
        name: `${t('myAccount.orders.columns.column4')}`,
        sortable: true,
        selector: (row) => (
          <div className="d-flex flex-column ml-2">
            <span className="list-verMas" onClick={()=>handledTracking(row.order_number)}><i className="fa-solid fa-magnifying-glass-plus"></i></span>
   
          </div>
        ),
      },

  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        color: "#6B6B6B",
        fontWeight: 600,
        fontFamily: "Manrope",
      },
    },
    cells: {
      style: {
        fontSize: "15px",
        color: "#001222",
        fontWeight: 500,
        fontFamily: "Manrope",
      },
    },
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,

    selectAllRowsItemText: "Todos",
  };

  return (
    <>
      
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 mt-1">
          <InputSearch
            value={searchValue}
            setValue={handleFilter}
            filterLabel="por Nro de Orden o Cliente"
          />
        </div>
        {/*<div className="col-2 col-sm-2 col-md-2 mt-1">
              <ButtonPrimary
                    change={downloadExcelReport}
                    text={"Descargar"}
                    icon={<i className="fa-solid fa-file-excel mx-auto"></i>}
                  />
  </div>*/}
       
        
        <div className="col-md-12 mt-4">
          <DataTable
            pagination
            columns={columns}
            progressPending={isLoading}
            noDataComponent={"No hay registros para mostrar"}
            paginationComponentOptions={paginationComponentOptions}
            data={ordersAux}
            customStyles={customStyles}
            progressComponent={
              <div className="my-6 mx-auto">
                <p className="mb-3">Cargando Datos...</p>
                <LoadingData />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default List;
