import React, { useCallback, useEffect, useMemo } from "react";
import InputSearch from "../../../Components/Dashboard/Inputs/InputSearch/InputSearch";
import ButtonPrimary from "../../../Components/Dashboard/Buttons/ButtonPrimary";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import './reports.css'
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { getDate, getFormatTime } from "../../../utils/dates";
import { getListOrdersReports } from "../../../Services/reportService";
import authServiceInstance from "../../../Services/authService";
import { getOrderStatus } from "../../../Services/orderService";
import { getCarrier } from "../../../Services/carrierService";
import { LoadingData } from "../../../Components/loaders/LoadingData";
import { api } from "../../../Services/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

const List = ({ changeStatus }) => {
  const token = authServiceInstance.getSessionToken();
  const admin = authServiceInstance.getSessionAdmin();
  const allDefault = 1000;
  const [currentId, setCurrentId] = useState(null);
  const [reports, setReports] = useState();
  const [reportsAux, setReportsAux] = useState();
  const [searchValue, setSearchValue] = useState();
  const [status, setStatus] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([0]);
  const [isAllStatusSelected, setIsAllStatusSelected] = useState(
    selectedStatus.includes(0)
  );
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const handleDateChange = (field, value) => {
    if (field === "from") {
      setFrom(value);
    } else if (field === "to") {
      setTo(value);
    }
  };

  const initialStartDate = useMemo(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() - 1;
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    return `${currentYear}-${currentMonth}-${currentDay}`;
  }, []);
  const initialEndDate = useMemo(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    return `${currentYear}-${currentMonth}-${currentDay}`;
  }, []);

  const [from, setFrom] = useState(initialStartDate);
  const [to, setTo] = useState(initialEndDate);

  const filters = useMemo(() => {
    if (selectedStatus.length > 0 && status.length > 0) {
      const isAllStatus = selectedStatus.length === status.length;

      const filter = {
        carrierId: selectedCarrier,
        statusId: isAllStatus ? 0 : selectedStatus.join(","),
        startDate: from,
        finishDate: to,
      };
      return filter;
    }
  }, [from, to, selectedCarrier, selectedStatus, status]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); 
  };

  const fetchStatus = useCallback(async () => {
    try {
      const status = await getOrderStatus(token);
      if (status.success) {
        setStatus(status.data);
      }
    } catch (error) {
      console.error("Error al obtener los reportes:", error);
    }
  }, [token]);

  const fetchCarriers = useCallback(async () => {
    try {
      const carriers = await getCarrier(token);
      if (carriers.success) {
        setCarriers(carriers.data);
      }
    } catch (error) {
      console.error("Error al obtener los reportes:", error);
    }
  }, [token]);

  useEffect(() => {
    if (admin) {
      setCurrentId(admin.id);
    }
  }, [admin]);

  useEffect(() => {
    fetchStatus();
    fetchCarriers();
  }, [fetchStatus, fetchCarriers]);

  const fetchReports = useCallback(async () => {
    setIsLoading(true);
    try {
      if (selectedStatus.length > 0) {
        const reports = await getListOrdersReports(token, filters, page, limit);
        setReports(reports.data.orders);
        setReportsAux(reports.data.orders);
        const total = reports.data ? reports.data.totalRows : 0;
        const calculatedTotalPages = Math.ceil(total / limit);
        setTotalRows(total)
        setTotalPages(calculatedTotalPages);
        setIsLoading(false);
      } else {
        setReports([]);
        setReportsAux([]);
        setTotalPages(0);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error al obtener los reportes:", error);
    }
  }, [token, filters, page, limit, selectedStatus]);

  const downloadExcelReport = async () => {
    
    console.log("parametros:",from,to,page,limit)
    await api
      .get(
        `/orders-report/download-excel-report?startDate=${from}&endDate=${to}&carrier_id=${selectedCarrier}&id_status=${selectedStatus}&page=${isChecked ? 1 : page}&limit=${isChecked ? allDefault :limit}&id_user=${currentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "ReporteDeOrdenes.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Hubo un problema con la solicitud de descarga:", error);
      });
  };

  useEffect(() => {
    if (filters) {
      fetchReports();
    }
  }, [filters, fetchReports, page, limit]);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const customCell = (guia) => (
    <div className="hover:cursor-pointer">
      <LightTooltip title={guia}>
        <i className="fa-solid fa-circle-info mx-auto"></i>
      </LightTooltip>
    </div>
  );

  const getStatusName = (id) => {
    const filtered = status?.find((s) => s.id_status === Number(id));

    return filtered?.status_name;
  };
  const columns = [
    {
      name: "Fecha de Creación",
      sortable: true,
      selector: (row) => (
        <div className="d-flex flex-column">
          <span>{getDate(row.created_at)}</span>
          <small>{getFormatTime(row.created_at)}</small>
        </div>
      ),
    },
    {
      name: "Nro de Orden",
      selector: (row) => "#" + row.order_number,
      sortable: true,
      width: "180px",
    },
    {
      name: "Servicio",
      selector: (row) => row.item,
      sortable: true,
    },
    {
      name: "Carrier",
      selector: (row) => row.carrier,
      sortable: true,
    },
    {
      name: "Guía",
      width: "70px",
      center: true,
      cell: (row) => customCell(row.bl_guia),
    },
    {
      name: "Estado",
      selector: (row) => row.id_status,
      sortable: true,
      width: "250px",
      center: true,
      cell: (row) => (
        <div className="flex justify-center">
          {row.id_status === 29 ? (
            <span className="text-ellipsis inline-block max-w-[220px] border-cancel bg-[#F4FFFF] overflow-hidden whitespace-nowrap text-xs text-textError py-1.5 px-3 rounded-[10px]">
              Cancelado
            </span>
          ) : (
            <span className="status-active text-xs px-3">
              {getStatusName(row.id_status)}
            </span>
          )}
        </div>
      ),
    },
    {
      name: "Cajas",
      cell: (row) => (
        <div className="flex justify-center w-full">{row.boxes.length}</div>
      ),
      sortable: true,
      width: "90px",
      center: true,
    },
    {
      name: "Peso",
      selector: (row) =>
        `${row.boxes.reduce((acc, el) => acc + Number(el.box_weight), 10)} Kg`,
      sortable: true,
      width: "100px",
    },
    {
      name: "País Origen",
      selector: (row) => row.OriginAddress.state.country.country_name,
      sortable: true,
    },
    {
      name: "País Destino",
      selector: (row) => row.DestinationAddress.state.country.country_name,
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        color: "#6B6B6B",
        fontWeight: 600,
        fontFamily: "Manrope",
        with: "auto",
      },
    },
    cells: {
      style: {
        fontSize: "15px",
        color: "#001222",
        fontWeight: 500,
        fontFamily: "Manrope",
      },
    },
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,

    selectAllRowsItemText: "Todos",
  };

  const handleFilter = (event) => {
    setSearchValue(event.target.value);
    const newData = reports.filter((row) => {
      const searchTerm = event.target.value.toLowerCase();
      return (
        row.item.toLowerCase().includes(searchTerm) ||
        row.carrier.toLowerCase().includes(searchTerm)
      );
    });
    setReportsAux(newData);
  };

  const handlePageChange = (currentRowsPerPage) => {
    setLimit(currentRowsPerPage);
  };

  useEffect(() => {
    if (status.length > 0) {
      const allStatus = status.map((st) => st.id_status);
      setSelectedStatus(allStatus);
    }
  }, [status]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const allStatus = status.map((status) => status.id_status);

    // Verificar si todos los checkboxes están seleccionados
    const allStatusSelected = value.length === allStatus.length;

    if (allStatusSelected) {
      // Si todos están seleccionados, actualiza el estado para reflejarlo
      setIsAllStatusSelected(true);
      setSelectedStatus(allStatus);
    } else {
      // Si no todos están seleccionados, mantén el estado actual de los checkboxes
      setIsAllStatusSelected(false);
      setSelectedStatus(value);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-8 col-md-12 mt-1">
          <InputSearch
            value={searchValue}
            className="w-full"
            setValue={handleFilter}
            filterLabel={"por Servicio o Courier"}
          />
        </div>

        <div className="flex md:flex-row flex-col w-full items-center justify-between my-2">
          <div className="filters px-0 items-center">
            <div className="w-full md:w-auto">
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 200,
                  "& .MuiOutlinedInput-root": {
                    height: 38,
                    borderColor: "#dee2e6",
                    borderWidth: "1px",
                    borderRadius: "0.375rem",
                    "&:hover": {
                      borderColor: "none",
                      outline: "none",
                    },
                    "&.Mui-focused": {
                      borderColor: "#86b7fe",
                      borderWidth: "1px",
                      outline: 0,
                      boxShadow: "0 0 0 0.25rem rgba(13,110,253,.25)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "& hover:.MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  },
                }}
                size="small"
              >
                <Select
                  labelId="states"
                  label="Estados"
                  id="multiple-states"
                  multiple
                  className="border-[#dee2e6]"
                  value={selectedStatus}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => {
                    if (selected.length === status.length) {
                      return "Todos los estados";
                    }
                    const selectedNames = selected.map((value) => {
                      const statusObject = status.find(
                        (s) => s.id_status === Number(value)
                      );
                      return statusObject ? statusObject.status_name : "";
                    });
                    return selectedNames.join(", ");
                  }}
                  sx={{
                    width: "100%",
                    maxWidth: "240px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  MenuProps={MenuProps}
                >
                  {status.map((st) => (
                    <MenuItem key={st.id_status} value={st.id_status}>
                      <Checkbox
                        checked={selectedStatus.indexOf(st.id_status) > -1}
                      />
                      <ListItemText primary={st.status_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full md:w-auto max-w-44">
              <select
                className="form-select"
                id="Courier"
                name="Courier"
                aria-describedby="Courier"
                value={selectedCarrier}
                onChange={(e) => {
                  setSelectedCarrier(e.target.value);
                  setPage(0);
                  setLimit(10);
                }}
              >
                <option value={0}>Todos los carrier</option>
                {carriers.map((carrier) => (
                  <option key={carrier.id} value={carrier.id}>
                    {carrier.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full md:w-auto">
              <input
                type="date"
                id="from"
                name="from"
                aria-describedby="from"
                className="form-control"
                value={from}
                onChange={(e) => handleDateChange("from", e.target.value)}
              />
            </div>
            <div className="w-full md:w-auto">
              <input
                type="date"
                className="form-control"
                id="to"
                name="to"
                aria-describedby="to"
                value={to}
                onChange={(e) => handleDateChange("to", e.target.value)}
              />
            </div>
            <div className="w-full md:w-auto">
              <div className="form-check">
                <input className="form-check-input" 
                  type="checkbox" 
                  value="" 
                  id="chkAll" 
                  
                  title="Desarga los últimos 1000 registros"
                  checked={isChecked}  
                  onChange={handleCheckboxChange} 
                  />
                <label className="form-check-label" for="chkAll" >
                  Descargar todos los registros
                </label>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-4 col-md-2 px-0 h-10 mt-0"
            style={{ minHeight: "35px" }}
          >
            <ButtonPrimary
              change={downloadExcelReport}
              text={"Descargar"}
              icon={<i className="fa-solid fa-file-excel mx-auto"></i>}
            />
          </div>
        </div>
        <div className="col-md-12">
          <DataTable
            progressPending={isLoading}
            pagination
            paginationServer
            columns={columns}
            paginationComponentOptions={paginationComponentOptions}
            
            data={!reportsAux ? reports : reportsAux}
            customStyles={customStyles}
            noDataComponent={"No hay registros para mostrar"}
            persistTableHead
            paginationTotalRows={totalRows}
            progressComponent={
              <div className="my-6 mx-auto">
                <p className="mb-3">Cargando Datos...</p>
                <LoadingData />
              </div>
            }
            onChangePage={(page) => setPage(page)}
            onChangeRowsPerPage={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default List;
