import React, { useEffect, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { getOrderStatus } from "../../../Services/orderService";
import authServiceInstance from "../../../Services/authService";

const StatusModal = ({
  statusData,
  setStatusData,
  openModalStatus,
  setOpenModalStatus,
  action,
}) => {
  const [orderStatus, setOrderStatus] = useState([]);
  const [isCheckedSend, setIsCheckedSend] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false); 

  const handleCloseModal = () => {
    setOpenModalStatus(false);
  };

  const changeData = (e) => {
    const { name, value } = e.target;
    setStatusData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAccept = () => {
    if (!isAccepting) { 
      setIsAccepting(true); 
      action(isCheckedSend).then(() => {
        setIsAccepting(false); 
        handleCloseModal(); 
      });
    }
  };

  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        const token = authServiceInstance.getSessionToken();
        const orderStatusData = await getOrderStatus(token);

        const orderedOrderStatus = orderStatusData.data.sort((a, b) => {
          const nombreA = a.status_name.toUpperCase();
          const nombreB = b.status_name.toUpperCase();

          if (nombreA < nombreB) {
            return -1;
          }
          if (nombreA > nombreB) {
            return 1;
          }

          return 0;
        });

        setOrderStatus(orderedOrderStatus);
      } catch (error) {
        console.error("Error fetching order status:", error);
      }
    };

    fetchOrderStatus();
  }, []);

  return (
    <div>
      {openModalStatus && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                <div className="modal-cabecera">
                  <div className="modal-icon pb-4">
                    <span className="icon-container icon-alert">
                      <FiAlertTriangle />
                    </span>
                  </div>
                  <div className="modal-question">
                    <span>Cambiar estado de orden</span>
                    <small>
                      El cliente recibirá una notificación por correo con la
                      actualización del estado de orden
                    </small>
                    <div className="mt-2 modal-form">
                      <label htmlFor="">ESTADO</label>
                      <select
                        name="id_status"
                        value={statusData.id_status}
                        onChange={changeData}
                        className="form-select"
                      >
                        <option value="0" disabled>
                          Selecciona estado
                        </option>
                        {orderStatus.length !== 0 &&
                          orderStatus.map((status) => (
                            <option
                              key={status.id_status}
                              value={status.id_status}
                            >
                              {status.status_name}
                            </option>
                          ))}
                      </select>
                      <textarea
                        name="note"
                        id=""
                        value={statusData.note}
                        onChange={changeData}
                        className="form-control mt-2"
                        cols="30"
                        rows="4"
                        placeholder="Notas"
                      ></textarea>
                      <div className="w-full my-2">
                        <label className="m-0 flex flex-start gap-2 items-center text-muted">
                          <input
                            type="checkbox"
                            id="sendEmail"
                            checked={isCheckedSend}
                            className="border border-secondary custom-check"
                            onChange={() => setIsCheckedSend(!isCheckedSend)}
                          />
                          Notificar por correo
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <a className="btn-cancel" onClick={handleCloseModal}>
                    CANCELAR
                  </a>
                  <a
                    className={`btn-acept ${isAccepting ? 'disabled' : ''}`}
                    onClick={handleAccept}
                  >
                    ACEPTAR
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusModal;