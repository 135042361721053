import React from 'react'

import { FiCheck } from "react-icons/fi";
import { useNavigate } from 'react-router';

const SuccessPassword = ({ openModalSuccess, setOpenSuccess,changeState}) => {
	const navigate = useNavigate();

    const handleCloseModal = () => {
        setOpenSuccess(false);
    };
    const handleReturn = ()=>{
      navigate('/mi-cuenta')
    }
  return (
    <div>

      {openModalSuccess && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                  <div className="modal-cabecera type-save pt-1">
                    <div className="modal-icon icon-save ">
                        <span className='icon-container icon-check'><FiCheck /></span>
                    </div>
                    <div className="modal-question">
                        <span>Contraseña Actualizada</span>
                        <small>Se realizó el cambio de contraseña de forma exitosa.</small>
                    </div>
                  </div>
                  <div className="modal-footer footer-save pb-0">
                    <a className='btn-acept' onClick={handleReturn}>Aceptar</a>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SuccessPassword