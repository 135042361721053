import Select from "react-select";
import "./selects.css";
import { useEffect } from "react";
import {
  getAddressByName,
  getAddressData,
} from "../../../Services/addressService";
import authService from "../../../Services/authService";
import { useState } from "react";

const DirectionSelect = ({ value, setValue, type, order, client }) => {
  const [directionData, setDirectionData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const token = authService.getSessionToken();

  useEffect(() => {
    const fetchData = async () => {
      if (client) {
        try {
          const data = await getAddressData(client, token);
          const fetchedOptions = data?.data?.map((option) => ({
            name: option.address_name,
            value: option.id_address,
            label: option.address_name,
          }));

          setDirectionData(fetchedOptions);
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    if (order?.id_user) {
      if (type === "origin") {
        handleSelectChange({
          name: order?.OriginAddress?.address_name,
          label: order?.OriginAddress?.address_name,
        });
      } else if (type === "destination") {
        handleSelectChange({
          name: order?.DestinationAddress?.address_name,
          label: order?.DestinationAddress?.address_name,
        });
      }
    }

    fetchData();
  }, [client]);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedOption(selectedOption);
      getDirection(selectedOption !== null ? selectedOption.name : "");
    } else {
      setSelectedOption(selectedOption);

      if (type === "origin") {
        setValue({
          ...value,
          id_address_origin: "",
          country_origin: "",
          state_origin: "",
          city_origin: "",
          postalCode_origin: "",
          address_origin: "",
        });
      } else if (type === "destination") {
        setValue({
          ...value,
          id_address_destination: "",
          country_destination: "",
          state_destination: "",
          city_destination: "",
          postalCode_destination: "",
          address_destination: "",
        });
      }
    }
  };

  const getDirection = async (directionName) => {
    try {
      const directionData = await getAddressByName(directionName, token);

      if (directionData !== "") {
        if (type === "origin") {
          setValue({
            ...value,
            id_address_origin: directionData.data[0].id_address,
            country_origin: directionData.data[0].state.id_country,
            state_origin: directionData.data[0].state.id_state,
            city_origin: directionData.data[0].city,
            postalCode_origin: directionData.data[0].postal_code,
            address_origin: directionData.data[0].address_name,
          });
        } else if (type === "destination") {
          setValue({
            ...value,
            id_address_destination: directionData.data[0].id_address,
            country_destination: directionData.data[0].state.id_country,
            state_destination: directionData.data[0].state.id_state,
            city_destination: directionData.data[0].city,
            postalCode_destination: directionData.data[0].postal_code,
            address_destination: directionData.data[0].address_name,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <div className="client-select">
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="dirección"
        isClearable={true}
        value={selectedOption}
        isSearchable={true}
        name="color"
        options={directionData}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default DirectionSelect;
