import React, { useCallback } from "react";
import Layout from "../../../Components/Dashboard/Layout/Layout";
import { useState } from "react";
import ClientSelect from "../../../Components/Dashboard/Selects/ClientSelect";
import { useEffect } from "react";
import {
  getOrderById,
  getOrderStatus,
  updateOrder,
  updateOrderStatus,
} from "../../../Services/orderService";
import DirectionSelect from "../../../Components/Dashboard/Selects/DirectionSelect";
import {
  getCitiesByCountry,
  getCountries,
} from "../../../Services/countryService";
import { useNavigate, useParams } from "react-router";
import UpdateModal from "../Modales/UpdateModal";
import { filesToBase64Array } from "../../../utils/utils";
import authServiceInstance from "../../../Services/authService";
import { getCarrier } from "../../../Services/carrierService";
import BoxOrder from "../../../Components/Dashboard/Orders/BoxOrder";
import FileInputEdit from "../../../Components/Dashboard/Inputs/FileInput/FileInputEdit";
import DeleteModal from "../Modales/DeleteModal";
import { toast } from "react-toastify";
import { LoadingData } from "../../../Components/loaders/LoadingData";
import { getDate } from "../../../utils/dates";

const EditOrder = ({ toggleStatus }) => {
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [orderData, setOrderData] = useState();
  const [countriesDestination, setCountriesDestination] = useState([]);
  const [citiesDestination, setCitiesDestination] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [formData, setFormData] = useState({});
  const { idorder } = useParams();
  const [data, setData] = useState();
  const [saveNewDirection, setSaveNewDirection] = useState(false);
  const [saveNewDirectionDestination, setSaveNewDirectionDestination] =
    useState(false);
  const [carriers, setCarriers] = useState([]);
  const token = authServiceInstance.getSessionToken();
  const [clientID, setClientID] = useState();
  const [documents, setDocuments] = useState({});
  const [invoices, setInvoices] = useState({});
  const [loader, setLoader] = useState(false);
  const [statusData, setStatusData] = useState({
    id_status: 39,
    note: "",
  });
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navegar hacia atrás en la pila de historial
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleChangeCountry = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "country_origin") {
      // Si cambia el país, carga las ciudades correspondientes
      const fetchCities = async () => {
        try {
          const citiesData = await getCitiesByCountry(value, token);
          setCities(citiesData.data);
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };

      fetchCities();
    }
  };
  const handleModalDelete = () => {
    setOpenModalDelete(true);
  };

  const handleDelete = async () => {
    setLoader(true);
    try {
      await updateOrderStatus(idorder, statusData, token);
      setLoader(false);
      navigate("/admin-itc/ordenes");
    } catch (error) {
      setLoader(false);
      console.error("Error deleting order:", error.message);
    }
  };
  const handleChangeCity = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "country_destination") {
      // Si cambia el país, carga las ciudades correspondientes
      const fetchCities = async () => {
        try {
          const citiesData = await getCitiesByCountry(value, token);
          setCitiesDestination(citiesData.data);
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };

      fetchCities();
    }
  };
  const numberFormat = (number) => {
    const cleanedNumber = number.toString().replace(/[^0-9.]/g, '');

    // Dividir el número en partes entera y decimal
    let partsNumber = cleanedNumber.split('.');

      
    partsNumber[0] = partsNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Añade comas cada tres dígitos

    return partsNumber.join('.'); // Une los números y el decimal si lo hay
  }
  const fetchCountries = useCallback(async () => {
    try {
      const countriesData = await getCountries(token);
      setCountries(countriesData.data);
      setCountriesDestination(countriesData.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  }, [token]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingDetails(true);
      try {
        const ordersData = await getOrderById(idorder, token);

        if (ordersData && ordersData.success) {
          const response = ordersData?.data;

          setOrderData(response);
          setFormData((prevFormData) => ({
            ...prevFormData,
            //origen
            country_origin: response.OriginAddress?.state?.id_country,
            state_origin: response.OriginAddress?.id_state,
            city_origin: response.OriginAddress?.city,
            postalCode_origin: response.OriginAddress?.postal_code,
            address_origin: response.OriginAddress?.address_name,
            //destino
            country_destination: response.DestinationAddress?.state?.id_country,
            state_destination: response.DestinationAddress?.id_state,
            city_destination: response.DestinationAddress?.city,
            postalCode_destination: response.DestinationAddress?.postal_code,
            address_destination: response.DestinationAddress?.address_name,
            //Services
            date: new Date(response?.collection_date)
              .toISOString()
              .slice(0, 16)
              .replace("T", " "),
            service_status: response?.status?.id_status,
            item: response?.item,
            //Shipment
            date_shipment: response.arrival_date,
            Courier: response.carrier,
            bl_guia: response.bl_guia,
            etd: response.etd,
            eta: response.eta,
            cost: response?.cost ?? "",
            sale_price: response?.sale_price ?? "",
            proforma: response.proforma,
            reference: response.client_reference,
            delivery_date:
              response?.delivery_date !== null
                ? new Date(response?.delivery_date).toISOString().split("T")[0]
                : null,
            invoice: response?.invoice,
            //Details
            container_type: response?.container_type,
            dimension: response?.m3,
            height: "",
            length: "",
            width: "",
            weight: response.total_weight,
            observations: response.notes,
            invoice_photo: response.invoices?.file_galleries,
            other_documents: response.documents?.file_galleries,
            boxes: [],
            item: response.item,
          }));

          setDocuments(response?.documents?.file_galleries);
          setInvoices(response?.invoices?.file_galleries);
          setLoadingDetails(false);
        }
      } catch (error) {
        setLoadingDetails(false);
        toast.error(`No se pudo cargar los datos Error: ${error.message}`);
        console.error("Error fetching order by ID:", error.message);
        // Puedes manejar el error de alguna manera, por ejemplo, mostrar un mensaje al usuario
      }
    };

    const fetchOrderStatus = async () => {
      try {
        const orderStatusData = await getOrderStatus(token);

        setOrderStatus(orderStatusData.data);
      } catch (error) {
        console.error("Error fetching order status:", error);
      }
    };

    const fetchCarriers = async () => {
      try {
        const carrierData = await getCarrier(token);
        setCarriers(carrierData.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCarriers();
    fetchData();
    fetchOrderStatus();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      if (formData.country_origin && formData.country_origin !== "") {
        try {
          const citiesData = await getCitiesByCountry(
            formData.country_origin,
            token
          );
          if (citiesData.data) {
            setCities(citiesData.data);
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      }
    };

    fetchCities();
  }, [formData.country_origin, token]);

  useEffect(() => {
    const fetchCities = async () => {
      if (formData.country_destination && formData.country_destination !== "") {
        try {
          const citiesData = await getCitiesByCountry(
            formData.country_destination,
            token
          );
          if (citiesData.data) {
            setCitiesDestination(citiesData.data);
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      }
    };

    fetchCities();
  }, [formData.country_destination, token]);

  useEffect(() => {
    if (orderData) {
      const fetchData = async () => {
        if (orderData)
          try {
            const documentsBase64 =
              formData?.other_documents?.length > 0
                ? await new Promise((resolve) =>
                    filesToBase64Array(formData.other_documents, resolve)
                  )
                : [];

            const invoicesBase64 =
              formData?.invoice_photo?.length > 0
                ? await new Promise((resolve) =>
                    filesToBase64Array(formData.invoice_photo, resolve)
                  )
                : [];

            setData({
              //created_at:formData.created_at,
              id_status: formData.service_status || orderData.id_status,
              client_name:
                (formData.name || orderData.user.name) +
                " " +
                (formData.lastName || orderData.user.last_name),
              item: formData.item || orderData.item,
              arrival_date: formData.date_shipment,
              collection_date: formData.date || orderData.collection_date,
              carrier: formData.Courier || orderData.carrier,
              bl_guia: formData.bl_guia || orderData.bl_guia,
              boxes:
                formData.boxes.length !== 0 ? formData.boxes : orderData.boxes,
              //container_number: 1,//agregar campo
              //container_type: 1,//agregar campo
              //m3: formData.height * formData.length * formData.width,
              //total_weight: formData.weight,
              etd: formData.etd,
              eta: formData.eta,
              cost: (formData?.cost ? parseFloat(formData?.cost.replace(/,/g, '') || 0) : "") || (orderData?.cost ? parseFloat(orderData?.cost.replace(/,/g, '') || 0) : ""),
              sale_price:
                (formData?.sale_price ? parseFloat(formData?.sale_price.replace(/,/g, '') || 0) : "") || (orderData?.sale_price ? parseFloat(orderData?.sale_price.replace(/,/g, '') || 0) : ""),
              proforma: formData.proforma || orderData.proforma,
              delivery_date: formData.delivery_date,
              itc_invoice: "Número de factura ITC",
              client_reference:
                formData.reference || orderData.client_reference,
              number_packages: 1,
              invoice: formData.invoice || orderData.invoice,
              files: "Nombre de archivos",
              notes: formData.observations || orderData.notes,
              order_notes: "Notas de la orden",
              order_actions: "Acciones de la orden",
              id_user: formData.id_user || orderData.user.id_user,
              id_address_origin:
                formData.id_address_origin || orderData.id_address_origin,
              id_address_destination:
                formData.id_address_destination ||
                orderData.id_address_destination,
              documents:
                documentsBase64.length !== 0
                  ? documentsBase64
                  : formData?.other_documents?.map((document) => document),
              invoices:
                invoicesBase64.length !== 0
                  ? invoicesBase64
                  : formData?.invoice_photo?.map((invoice) => invoice),

              address_origin: {
                city: formData.city_origin,
                postal_code: formData.postalCode_origin,
                address_name: formData.address_origin,
                id_state: formData.state_origin,
                flag_active: saveNewDirection,
              },
              address_destination: {
                city: formData.city_destination,
                postal_code: formData.postalCode_destination,
                address_name: formData.address_destination,
                id_state: formData.state_destination,
                flag_active: saveNewDirectionDestination,
              },
              client: {
                user_name: formData.name || orderData.user.name,
                name: formData.name || orderData.user.name,
                last_name: formData.lastName || orderData.user.last_name,
                position: formData.position,
                email: formData.email,
                number_phone_office: formData.officePhone,
                number_phone: formData.cellPhone,
              },
              company: {
                company_name: formData.companyName,
                rfc: formData.rfc,
                fiscal_address: formData.address,
                economic_activity: formData.businessActivity,
                tax_regime: formData.taxRegime,
                observations: formData.observations,
              },
            });
          } catch (error) {
            console.error(
              "Ocurrió un error al convertir archivos a base64:",
              error
            );
          }
      };
      fetchData();
    }
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    actualizarOrden();
  };

  const actualizarOrden = async () => {
    setLoadingUpdate(true);

    const nuevosDatosDeOrden = {
      ...data,
      documents: data.documents ? data.documents : [],
      invoices: data.invoices ? data.invoices : [],
    };

    try {
      await updateOrder(idorder, nuevosDatosDeOrden, token);
      setLoadingUpdate(false);
      setOpenModalUpdate(true);
    } catch (error) {
      setLoadingUpdate(false);
      toast.error(`Error: ${error.message}`);
      console.log(error);
      console.error("Error al crear una nueva orden:", error.message);
    }
  };

  if (loadingDetails)
    return (
      <div className="w-full mx-auto m-12">
        <LoadingData />
      </div>
    );

  return (
    <>
      <>
        <div
          className="container-report"
          style={{ backgroundColor: "#F4F6F8" }}
        >
          <div className={toggleStatus ? "orders " : "orders active"}>
            <Layout>
              <div className="container-step">
                <section className="section-step-order">
                  <div className="detail-order">
                    <span>
                      Número de orden: <b>{orderData?.order_number}</b>
                    </span>
                    <span>
                      Orden realizada el :{" "}
                      <b>{getDate(orderData?.created_at, true)}</b>
                    </span>
                  </div>
                  <button className="btn" onClick={handleModalDelete}>
                    {" "}
                    ELIMINAR
                  </button>
                </section>
                <div className="mt-2 border border-textPrimary p-3 w-fit text-sm text-muted rounded-md flex gap-2 items-center">
                  <div className="flex justify-center items-center text-orange-600">
                    <i className="fa-solid fa-triangle-exclamation w-3 h-3"></i>
                  </div>
                  <p className="mb-0">
                    {`Recuerda que para que surgan los cambios, debes presionar en el botón "Guardar Cambios"`}
                  </p>
                </div>
                <form className="p-0 m-0">
                  <section className="section-step">
                    <h3>Cliente</h3>
                    {orderData && (
                      <ClientSelect
                        valueData={formData}
                        setValueData={setFormData}
                        order={orderData}
                        client={clientID}
                        setClient={setClientID}
                      />
                    )}
                  </section>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-4">
                      <label htmlFor="name" className="form-label">
                        Nombre
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="name"
                        name="name"
                        aria-describedby="name"
                        placeholder="Ingresa nombre"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="lastName" className="form-label">
                        Apellido
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={formData.lastName}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="lastName"
                        name="lastName"
                        aria-describedby="lastName"
                        placeholder="Ingresa apellido"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="position" className="form-label">
                        Puesto
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={formData.position}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="position"
                        name="position"
                        aria-describedby="position"
                        placeholder="Puesto en la empresa"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="email" className="form-label">
                        Correo electrónico
                      </label>
                      <input
                        type="email"
                        readOnly
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="email"
                        name="email"
                        aria-describedby="email"
                        placeholder="Ingresa correo"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="officePhone" className="form-label">
                        Tel. Oficina
                      </label>
                      <input
                        readOnly
                        type="tel"
                        value={formData.officePhone}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="officePhone"
                        name="officePhone"
                        aria-describedby="officePhone"
                        placeholder="Teléfono de oficina"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="cellPhone" className="form-label">
                        Tel.Celular
                      </label>
                      <input
                        type="tel"
                        readOnly
                        value={formData.cellPhone}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="cellPhone"
                        name="cellPhone"
                        aria-describedby="cellPhone"
                        placeholder="Teléfono celular"
                      />
                    </div>
                  </div>

                  <h3 className="mt-3 mb-4">Compañía</h3>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-4">
                      <label htmlFor="companyName" className="form-label">
                        compañía
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={formData.companyName}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="companyName"
                        name="companyName"
                        aria-describedby="companyName"
                        placeholder="Ingresa nombre de la compañía"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="rfc" className="form-label">
                        RFC
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={formData.rfc}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="rfc"
                        name="rfc"
                        aria-describedby="rfc"
                        placeholder="Ingresa RFC"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="address" className="form-label">
                        Dirección fiscal
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={formData.address}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="address"
                        name="address"
                        aria-describedby="address"
                        placeholder="Dirección"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="businessActivity" className="form-label">
                        Actividad económica
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={formData.businessActivity}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="businessActivity"
                        name="businessActivity"
                        aria-describedby="businessActivity"
                        placeholder="Ingresa actividad económica"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="taxRegime" className="form-label">
                        Régimen fiscal
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={formData.taxRegime}
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="taxRegime"
                        name="taxRegime"
                        aria-describedby="taxRegime"
                        placeholder="Ingresa régimen fiscal"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="observations" className="form-label">
                        Observaciones
                      </label>
                      <input
                        type="text"
                        value={formData.observations}
                        readOnly
                        onChange={handleChange}
                        className="form-control hover:cursor-not-allowed"
                        id="observations"
                        name="observations"
                        aria-describedby="observations"
                        placeholder="Ingresa observaciones"
                      />
                    </div>
                  </div>

                  <h3 className="mt-3 mb-4">Servicio</h3>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-4">
                      <label htmlFor="date" className="form-label">
                        Fecha
                      </label>
                      <input
                        type="datetime-local"
                        value={formData.date}
                        onChange={handleChange}
                        className="form-control"
                        id="date"
                        name="date"
                        aria-describedby="date"
                        placeholder="Ingresa ciudad"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="service_status" className="form-label">
                        Estado
                      </label>
                      <select
                        disabled
                        value={formData.service_status}
                        onChange={handleChange}
                        className="form-select disabled:bg-slate-300 text-muted hover:cursor-not-allowed"
                        id="service_status"
                        name="service_status"
                        aria-describedby="service_status"
                        placeholder="Selecciona status"
                        aria-readonly
                      >
                        <option value="">Selecciona estado</option>
                        {orderStatus.length !== 0 &&
                          orderStatus?.map((status) => (
                            <option
                              key={status?.id_status}
                              value={status?.id_status}
                            >
                              {status.status_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="item" className="form-label">
                        Servicio
                      </label>
                      <input
                        type="text"
                        value={formData.item}
                        onChange={handleChange}
                        className="form-control"
                        id="item"
                        name="item"
                        aria-describedby="item"
                        placeholder="Tipo de servicio"
                      />
                    </div>
                  </div>
                  <section className="section-step">
                    <h3>Dirección origen</h3>
                    {orderData && Object.keys(formData).length !== 0 && (
                      <DirectionSelect
                        value={formData}
                        setValue={setFormData}
                        type="origin"
                        order={orderData}
                        client={clientID}
                      />
                    )}
                  </section>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-3">
                      <label htmlFor="country_origin" className="form-label">
                        País
                      </label>
                      <select
                        value={formData.country_origin}
                        onChange={handleChangeCountry}
                        className="form-select"
                        id="country_origin"
                        name="country_origin"
                        aria-describedby="country_origin"
                        placeholder="Selecciona país"
                      >
                        <option value="">Selecciona un país</option>
                        {countries.length !== 0 &&
                          countries?.map((country) => (
                            <option
                              key={country.id_country}
                              value={country.id_country}
                            >
                              {country.country_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label htmlFor="state_origin" className="form-label">
                        Estado/Provincia
                      </label>
                      <select
                        value={formData.state_origin}
                        onChange={handleChange}
                        className="form-select"
                        id="state_origin"
                        name="state_origin"
                        aria-describedby="state_origin"
                        placeholder="Selecciona estado"
                      >
                        <option value="">Selecciona estado</option>
                        {cities?.length !== 0 &&
                          cities?.map((city) => (
                            <option key={city.id_state} value={city.id_state}>
                              {city.state_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label htmlFor="city_origin" className="form-label">
                        Ciudad
                      </label>
                      <input
                        type="text"
                        value={formData.city_origin}
                        onChange={handleChange}
                        className="form-control"
                        id="city_origin"
                        name="city_origin"
                        aria-describedby="city_origin"
                        placeholder="Ingresa ciudad"
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label htmlFor="postalCode_origin" className="form-label">
                        Código Postal
                      </label>
                      <input
                        type="email"
                        value={formData.postalCode_origin}
                        onChange={handleChange}
                        className="form-control"
                        id="postalCode_origin"
                        name="postalCode_origin"
                        aria-describedby="postalCode_origin"
                        placeholder="Ingresa código postal"
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label htmlFor="address_origin" className="form-label">
                        Dirección
                      </label>
                      <input
                        type="tel"
                        value={formData.address_origin}
                        onChange={handleChange}
                        className="form-control"
                        id="address_origin"
                        name="address_origin"
                        aria-describedby="address_origin"
                        placeholder="Ingresa una dirección"
                      />
                    </div>
                  </div>

                  <section className="section-step">
                    <h3>Dirección destino</h3>
                    {orderData && Object.keys(formData).length !== 0 && (
                      <DirectionSelect
                        value={formData}
                        setValue={setFormData}
                        type="destination"
                        order={orderData}
                        client={clientID}
                      />
                    )}
                  </section>

                  <div className="section row m-0">
                    <div className="mb-3 col-md-3">
                      <label
                        htmlFor="country_destination"
                        className="form-label"
                      >
                        País
                      </label>
                      <select
                        value={formData.country_destination}
                        onChange={handleChangeCity}
                        className="form-select"
                        id="country_destination"
                        name="country_destination"
                        aria-describedby="country_destination"
                        placeholder="Selecciona país"
                      >
                        <option value="">Selecciona país</option>
                        {countriesDestination.length !== 0 &&
                          countriesDestination?.map((country) => (
                            <option
                              key={country.id_country}
                              value={country.id_country}
                            >
                              {country.country_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label htmlFor="state_destination" className="form-label">
                        Estado/Provincia
                      </label>
                      <select
                        value={formData.state_destination}
                        onChange={handleChange}
                        className="form-select"
                        id="state_destination"
                        name="state_destination"
                        aria-describedby="state_destination"
                        placeholder="Selecciona estado"
                      >
                        <option value="">Selecciona estado</option>
                        {citiesDestination.length !== 0 &&
                          citiesDestination?.map((city) => (
                            <option key={city.id_state} value={city.id_state}>
                              {city.state_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label htmlFor="city_destination" className="form-label">
                        Ciudad
                      </label>
                      <input
                        type="text"
                        value={formData.city_destination}
                        onChange={handleChange}
                        className="form-control"
                        id="city_destination"
                        name="city_destination"
                        aria-describedby="city_destination"
                        placeholder="Ingresa ciudad"
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label
                        htmlFor="postalCode_destination"
                        className="form-label"
                      >
                        Código Postal
                      </label>
                      <input
                        type="email"
                        value={formData.postalCode_destination}
                        onChange={handleChange}
                        className="form-control"
                        id="postalCode_destination"
                        name="postalCode_destination"
                        aria-describedby="postalCode_destination"
                        placeholder="Ingresa código postal"
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label
                        htmlFor="address_destination"
                        className="form-label"
                      >
                        Dirección
                      </label>
                      <input
                        type="tel"
                        value={formData.address_destination}
                        onChange={handleChange}
                        className="form-control"
                        id="address_destination"
                        name="address_destination"
                        aria-describedby="address_destination"
                        placeholder="Ingresa una dirección"
                      />
                    </div>
                  </div>
                  <section className="section-step">
                    <h3>Embarque</h3>
                  </section>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-4">
                      <label htmlFor="date_shipment" className="form-label">
                        Fecha arribo/recolección
                      </label>
                      <input
                        type="date"
                        value={formData.date_shipment}
                        onChange={handleChange}
                        className="form-control"
                        id="date_shipment"
                        name="date_shipment"
                        aria-describedby="date_shipment"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="Courier" className="form-label">
                        Carrier
                      </label>
                      <select
                        value={formData.Courier}
                        onChange={handleChange}
                        className="form-select"
                        id="Courier"
                        name="Courier"
                        aria-describedby="Courier"
                        placeholder="Selecciona courier"
                      >
                        <option value="">Selecciona Carrier</option>
                        {carriers.length !== 0 &&
                          carriers?.map((carrier) => (
                            <option key={carrier.id} value={carrier.name}>
                              {carrier.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="bl_guia" className="form-label">
                        BL/Guía
                      </label>
                      <input
                        type="text"
                        value={formData.bl_guia}
                        onChange={handleChange}
                        className="form-control"
                        id="bl_guia"
                        name="bl_guia"
                        aria-describedby="bl_guia"
                        placeholder="Ingresa guía"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="etd" className="form-label">
                        ETD
                      </label>
                      <input
                        type="date"
                        value={formData.etd}
                        onChange={handleChange}
                        className="form-control"
                        id="etd"
                        name="etd"
                        aria-describedby="etd"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="eta" className="form-label">
                        ETA
                      </label>
                      <input
                        type="date"
                        value={formData.eta}
                        onChange={handleChange}
                        className="form-control"
                        id="eta"
                        name="eta"
                        aria-describedby="eta"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="proforma" className="form-label">
                        Proforma
                      </label>
                      <input
                        type="text"
                        value={formData.proforma}
                        onChange={handleChange}
                        className="form-control"
                        id="proforma"
                        name="proforma"
                        aria-describedby="proforma"
                        placeholder="Ingresa proforma"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="reference" className="form-label">
                        Referencia del cliente
                      </label>
                      <input
                        type="text"
                        value={formData.reference}
                        onChange={handleChange}
                        className="form-control"
                        id="reference"
                        name="reference"
                        aria-describedby="reference"
                        placeholder="Ingresa referencia"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="delivery_date" className="form-label">
                        Fecha de entrega
                      </label>
                      <input
                        type="date"
                        value={formData.delivery_date}
                        onChange={handleChange}
                        className="form-control"
                        id="delivery_date"
                        name="delivery_date"
                        aria-describedby="delivery_date"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="invoice" className="form-label">
                        Factura del cliente
                      </label>
                      <input
                        type="text"
                        value={formData.invoice}
                        onChange={handleChange}
                        className="form-control"
                        id="invoice"
                        name="invoice"
                        aria-describedby="invoice"
                        placeholder="Ingresa factura"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="cost" className="form-label">
                        Costo
                      </label>
                      <input
                        type="text"
                        value={formData.cost ? numberFormat(formData.cost) : ''}
                        onChange={handleChange}
                        className="form-control"
                        id="cost"
                        name="cost"
                        aria-describedby="cost"
                        placeholder="0.00"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="sale_price" className="form-label">
                        Precio de Venta
                      </label>
                      <input
                        type="text"
                        value={formData.sale_price ? numberFormat(formData.sale_price) : ''}
                        onChange={handleChange}
                        className="form-control"
                        id="sale_price"
                        name="sale_price"
                        aria-describedby="sale_price"
                        placeholder="0.00"
                      />
                    </div>
                  </div>

                  {
                    <BoxOrder
                      value={formData}
                      setValue={setFormData}
                      order={orderData}
                    />
                  }

                  <section className="section-step">
                    <h3>Documentos</h3>
                  </section>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-6">
                      <label htmlFor="date_shipment" className="form-label">
                        Facturas
                      </label>
                      <FileInputEdit
                        value={formData}
                        setValue={setFormData}
                        name="invoice_photo"
                        data={invoices}
                        setData={setInvoices}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="Courier" className="form-label">
                        Otros documentos
                      </label>
                      <FileInputEdit
                        value={formData}
                        setValue={setFormData}
                        name="other_documents"
                        data={documents}
                        setData={setDocuments}
                      />
                    </div>
                  </div>
                  {loadingUpdate && (
                    <div className="section-button">
                      <LoadingData />
                    </div>
                  )}
                  {!loadingUpdate && (
                    <div className="section-button">
                      <a onClick={handleGoBack}>VOLVER</a>
                      <button
                        type="button"
                        className="btn-next"
                        onClick={handleSubmit}
                      >
                        Guardar Cambios
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </Layout>
          </div>
        </div>
        <UpdateModal
          openModalUpdate={openModalUpdate}
          setOpenModalUpdate={setOpenModalUpdate}
        />
        <DeleteModal
          openModalDelete={openModalDelete}
          setOpenModalDelete={setOpenModalDelete}
          action={handleDelete}
          setStatusData={setStatusData}
          statusData={statusData}
        />
      </>
    </>
  );
};

export default EditOrder;
