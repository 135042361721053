import React from 'react'

import { FiCheck } from "react-icons/fi";

const SuccessClient = ({ openModalSuccess, setOpenSuccess,changeState}) => {
    const handleCloseModal = () => {
        setOpenSuccess(false);
    };
    const handleReturn = ()=>{
      changeState()
      handleCloseModal()
    }
  return (
    <div>

      {openModalSuccess && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                  <div className="modal-cabecera type-save pt-1">
                    <div className="modal-icon icon-save ">
                        <span className='icon-container icon-check'><FiCheck /></span>
                    </div>
                    <div className="modal-question">
                        <span>Usuario creado</span>
                        <small>Se realizó el registro de forma exitosa.<br/> Los datos fueron enviados a su correo.</small>
                    </div>
                  </div>
                  <div className="modal-footer footer-save pb-0">
                    <a className='btn-acept' onClick={handleReturn}>Aceptar</a>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SuccessClient