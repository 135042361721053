import React from 'react'
import { FiAlertOctagon } from "react-icons/fi";
import './modals.css'
const DeleteAdmin = ({statusData, setStatusData, openModalDelete, setOpenModalDelete, action}) => {
    const handleCloseModal = () => {
        setOpenModalDelete(false);
    };
    const changeData = (e)=>{
      const { name, value } = e.target;
      setStatusData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    
  return (
    <div>
      

      {openModalDelete && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                  <div className="modal-cabecera">
                    <div className="modal-icon pb-4">
                        <span className='icon-container'><FiAlertOctagon /></span>
                    </div>
                    <div className="modal-question">
                        <span>Eliminar permanentemente</span>
                        <small>El administrador no podrá acceder a la plataforma, y sus datos serán eliminados.</small>
                        
                    </div>
                  </div>
                  <div className="modal-footer p-0 pt-3">
                    <a className='btn-cancel m-0'  onClick={handleCloseModal}>Cancelar</a>
                    <a className='btn-acept m-0' onClick={action}>Aceptar</a>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeleteAdmin