import { api } from "./api";

export const getTotalsKPI = async (sessionToken, timeFrame) => {
  try {
    const response = await api.get(
      `/dashboard-summary?timeFrame=${timeFrame}`,
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Error fetching list orders");
  }
};

export const getOrdersDashboard = async (sessionToken, from, to) => {
  try {
    const response = await api.get(
      `/bashboard?startDate=${from}&endDate=${to}`,
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Error fetching list orders");
  }
};
