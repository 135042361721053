import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import store from "../redux/store";
import { checkAuthenticationAdmin } from "../redux/actions/authActions";

const PrivateRouteAdmin = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const [isAuthenticationChecked, setAuthenticationChecked] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      await store.dispatch(checkAuthenticationAdmin());
      setAuthenticationChecked(true);
    };

    checkAuthentication();
  }, []);

  const isAuthenticated = useSelector((state) => state.auth.user !== null);

  if (!isAuthenticationChecked) {
    // Evitar la redirección hasta que la autenticación esté completa
    return null; // o algún otro indicador de carga
  }

  return isAuthenticated ? children : <Navigate to="/login-admin" />;
};

export default PrivateRouteAdmin;
