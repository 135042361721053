import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../redux/actions/authActions";
import {
  createClient,
  requestPasswordReset,
  resetPasswordClient,
} from "../../Services/clientService";
import Spinner from "../../Components/loaders/Spinner";
import SuccessClient from "../Dashboard/Modales/SuccessClient";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router";
import SuccessPassword from "../Dashboard/Modales/SuccessPassword";
import { t } from "i18next";

const ResetPassword = () => {
  const [register, setRegister] = useState(true);
  const [loader, setLoader] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const { resetToken } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [validInputs, setValidInputs] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.user !== null);
  const authState = useSelector((state) => state.auth);
  const [isLoading, setIsloading] = useState(false);
  const { user } = authState;
  const [inputError, setInputError] = useState({
    password: false,
    passwordRepeat: false,
  });

  const changeState = () => {
    navigate("/mi-cuenta");
  };
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    password: "",
    passwordRepeat: "",
  });

  const validateInputs = () => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{}[\]:;<>,.?/\\])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    // Validar formato de correo electrónico
    if (!passwordRegex.test(credentials.password)) {
      setInputError((prev) => ({
        ...prev,
        password: `${t('resetPassword.errorPassword1')}`,
      }));
      setValidInputs(false);
      return false;
    }
    if (!passwordRegex.test(credentials.passwordRepeat)) {
      setInputError((prev) => ({
        ...prev,
        passwordRepeat: `${t('resetPassword.errorPassword2')}`,
      }));
      setValidInputs(false);
      return false;
    } else {
      if (passwordRegex.test(credentials.password)) {
        setInputError((prev) => ({ ...prev, password: "" }));
        setValidInputs(true);
      }
      if (passwordRegex.test(credentials.passwordRepeat)) {
        setInputError((prev) => ({ ...prev, passwordRepeat: "" }));
        setValidInputs(true);
      } else {
        setValidInputs(false);
      }
    }

    return true;
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {}, [credentials]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  const handleResetPassword = async (event) => {
    event.preventDefault();

    if (credentials.password === credentials.passwordRepeat && validInputs) {
      setLoader(true);
      try {
        const response = await resetPasswordClient({
          newPassword: credentials.password,
          token: resetToken,
        });

        setLoader(false);
        setOpenSuccess(true);
      } catch (error) {
        setLoader(false);
        console.error("Error al registrar el cliente:", error.message);
      }
    } else {
      setError(true);
    }
  };

  return (
    <>
      {loader && <Spinner />}
      <SuccessPassword
        openModalSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        changeState={changeState}
      />
      <div className="micuentaPage">
        <div className="slider">
          <h1>{t('resetPassword.title')}</h1>
          <span>{t('resetPassword.breadcrumb.prev')} {">"} {t('resetPassword.breadcrumb.name')}</span>
        </div>
        <div className="container p-5">
          <div className="row m-0 p-2 container-cuenta">
            {
              <div className="col-md-6 m-0 p-3 p-lg-5">
                <h3 className="p-2">{t('resetPassword.subTitle')}</h3>
                <form
                  action=""
                  className="row m-0 p-0"
                  onSubmit={handleResetPassword}
                >
                  <div className="col-12 p-2 m-0 d-flex align-items-center">
                    <input
                      className={`form-control ${
                        inputError.password ? "error-form" : ""
                      }`}
                      value={credentials.password}
                      required
                      placeholder={`${t('resetPassword.password1')}`}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={handleChange}
                      maxLength={100}
                      onBlur={validateInputs}
                    />
                    {showPassword ? (
                      <i
                        className="fa-regular fa-eye-slash"
                        style={{ marginLeft: "-30px", cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      ></i>
                    ) : (
                      <i
                        className="fa-regular fa-eye"
                        style={{ marginLeft: "-30px", cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      ></i>
                    )}
                  </div>
                  {inputError.password !== "" && (
                    <small className="text-danger">{inputError.password}</small>
                  )}
                  <div className="col-12 p-2 m-0 d-flex align-items-center">
                    <input
                      className={`form-control ${
                        inputError.passwordRepeat ? "error-form" : ""
                      }`}
                      value={credentials.passwordRepeat}
                      required
                      placeholder={`${t('resetPassword.password2')}`}
                      type={showPassword ? "text" : "password"}
                      name="passwordRepeat"
                      onChange={handleChange}
                      maxLength={100}
                      onBlur={validateInputs}
                    />
                    {showPassword ? (
                      <i
                        className="fa-regular fa-eye-slash"
                        style={{ marginLeft: "-30px", cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      ></i>
                    ) : (
                      <i
                        className="fa-regular fa-eye"
                        style={{ marginLeft: "-30px", cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      ></i>
                    )}
                  </div>
                  {inputError.passwordRepeat !== "" && (
                    <small className="text-danger">
                      {inputError.passwordRepeat}
                    </small>
                  )}
                  {error && (
                    <p
                      className="pt-1"
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {t('resetPassword.error1')} <br /> {t('resetPassword.error2')}
                    </p>
                  )}
                  <button className="btn" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <ThreeDots
                        height="10"
                        width="400"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      `${t('resetPassword.button')}`
                    )}
                  </button>
                </form>
              </div>
            }

            <div className="col-md-6 m-0 p-3 p-lg-5">
              <h3 className="text-center">{t('resetPassword.detail.title')}</h3>
              <p className="pt-3">
              {t('resetPassword.detail.description')}
              </p>
              <button className="btn-register" onClick={changeState}>
              {t('resetPassword.detail.button')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
