import React from "react";

const Create = () => {
  return (
    <>
      <div className="">Holi</div>
    </>
  );
};

export default Create;
