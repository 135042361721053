import React, { useCallback, useEffect, useState } from "react";
import authServiceInstance from "../../../Services/authService";
import { useNavigate, useParams } from "react-router";
import { getCarrier, updateCarrier } from "../../../Services/carrierService";
import { LoadingData } from "../../../Components/loaders/LoadingData";
import CreateCarrierModal from "../Modales/CreateCarrierModal";

const Edit = ({ changeTap }) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const admin = authServiceInstance.getSessionAdmin();
  const token = authServiceInstance.getSessionToken();
  const [currentCarrier, setCurrentCarrier] = useState(null);
  const { idcarrier } = useParams();
  const navigate = useNavigate();
  const returnList = () => {
    navigate("/admin-itc/carrier");
  };

  const fetchCarriers = useCallback(async () => {
    setIsLoading(true);
    try {
      const carrierData = await getCarrier(token);

      if (carrierData.success) {
        const filtered = carrierData.data.find(
          (carrier) => carrier.id === Number(idcarrier)
        );
        setName(filtered.name);
        setCurrentCarrier(filtered);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching carriers:", error);
    }
  }, [token, idcarrier]);

  useEffect(() => {
    if (idcarrier) {
      fetchCarriers();
    }
  }, [fetchCarriers, idcarrier]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || name?.length < 2) {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }
    const data = {
      name: name,
      iduser: admin?.id,
    };
    handleUpdateCarrier(data);
  };

  const handleUpdateCarrier = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        await updateCarrier(idcarrier, data, token);
        setOpenModalUpdate(true);
        setIsLoading(false);
      } catch (error) {
        console.error("Error al actualizar el carrier:", error.message);
        setIsLoading(false);
      }
    },
    [token, idcarrier]
  );
  return (
    <>
      <div className="container-step">
        {isLoading && (
          <div className="my-4 mx-auto text-center">
            <p className="mb-4 text-muted"> Cargando datos...</p>
            <LoadingData />
          </div>
        )}
        {!isLoading && (
          <form className="p-0 m-0 w-100 max-w-lg mx-auto">
            <section className="section-step">
              <h3>Carrier</h3>
            </section>
            <div className="section row m-0">
              <div className="mb-3 d-flex row m-0 p-0">
                <div className=" col-12">
                  <label htmlFor="name" className="form-label">
                    Nombre
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={`form-control ${
                      nameError ? "invalid-input" : ""
                    }`}
                    id="name"
                    name="name"
                    aria-describedby="name"
                    placeholder="Ingresa nombre"
                  />
                  {nameError && (
                    <span className="text-xs text-textError">
                      El nombre es requerido
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="section-button pb-2 gap-2">
              <button
                type="button"
                className=" btn-cancelar ms-auto"
                onClick={returnList}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn-next mr-1"
                onClick={handleSubmit}
              >
                Guardar Cambios
              </button>
            </div>
          </form>
        )}
      </div>
      <CreateCarrierModal
        openModalUpdate={openModalUpdate}
        setOpenModalUpdate={setOpenModalUpdate}
        type={"carrier"}
        returnList={returnList}
      />
    </>
  );
};

export default Edit;
