import React from 'react'
import './index.css'
const Card = ({item}) => {
  return (
    <div className="card-swiper card shadow">
        <p>{item.comentario}</p>
        <div className="footer-card">
            <img src="/assets/img/cliente.png" alt="client" />
            <div className='footer-client'>
                <span>{item.nombre}</span>
                <small>{item.cargo}</small>
            </div>
        </div>
    </div>
  )
}

export default Card