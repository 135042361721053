import React from "react";
import store from "../../redux/store";
import { checkAuthenticationAdmin } from "../../redux/actions/authActions";
import CardsInfo from "../../Components/Dashboard/Card/CardsInfo";
import OrdersChart from "../../Components/Dashboard/Charts/OrdersChart";

const Dashboard = ({ toggleStatus }) => {
  store.dispatch(checkAuthenticationAdmin());

  return (
    <div
      className="container-report mt-3 md:mt-0"
      style={{ backgroundColor: "#F4F6F8" }}
    >
      <div
        className={`home flex-col justify-start ${
          toggleStatus ? "" : "active"
        } pt-4`}
      >
        <CardsInfo />
        <div className="px-3 md:px-10 w-full">
          <OrdersChart />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
