import React, { useState } from "react";
import { FiAlertOctagon } from "react-icons/fi";
import { LoadingData } from "../../../Components/loaders/LoadingData";

const DeleteFile = ({
  openModalDelete,
  setOpenModalDelete,
  action,
  currentFile,
  isLoading,
}) => {
  const handleCloseModal = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = () => {
    action(currentFile?.name);
  };

  return (
    <div>
      {openModalDelete && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                <div className="modal-cabecera">
                  <div className="modal-icon pb-4">
                    <span className="icon-container">
                      <FiAlertOctagon />
                    </span>
                  </div>
                  <div className="modal-question">
                    <span>Eliminar Archivo</span>
                    <small>
                      Recuerda que eliminar un archivo es una acción
                      irreversible y para que surga efecto, debe guardar los
                      cambios.
                    </small>
                    <span className="mt-2 font-semibold text-sm">
                      Archivo: {currentFile.name.split(" ").join("_")}
                      {"."}
                      {currentFile?.url?.split(".").slice(-1)}
                    </span>
                  </div>
                </div>
                {isLoading ? (
                  <div className="mx-auto my-4">
                    <LoadingData />
                  </div>
                ) : (
                  <div className="modal-footer p-0 pt-3">
                    <a className="btn-cancel m-0" onClick={handleCloseModal}>
                      Cancelar
                    </a>
                    <a className="btn-acept m-0" onClick={handleDelete}>
                      Aceptar
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteFile;
