import React, { useState } from "react";
import "./tracking.css";
import { useNavigate } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import { validateCaptcha } from "../../Services/captchaService";
import { t } from "i18next";

const TrackingComponent = ({ handleOption, stateButton }) => {
  const [idTracking, setIdTracking] = useState("");
  const [error, setError] = useState(false);
  const [validRecaptcha, setValidRecaptcha] = useState(null);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (idTracking !== "" && validRecaptcha) {
      navigate(`/tracking/${idTracking}`);
      handleOption();
    } else {
      setError(true);
    }
  };
  const handleChange = (event) => {
    setIdTracking(event.target.value);
  };
  const handleRecaptcha = async (value) => {
    // Aquí puedes manejar la lógica cuando reCAPTCHA es completado con éxito
    try {
      const response = await validateCaptcha({ recaptchaToken: value });
      if (response.data.isValid) {
        setValidRecaptcha(true);
      } else {
        setValidRecaptcha(false);
      }
    } catch (error) {
      console.error("Error al validar el reCAPTCHA:", error.message);
    }
  };

  return (
    <div className="tracking-component d-flex flex-column" style={{}}>
      <i
        className="fa-solid fa-xmark ms-auto pr-2"
        onClick={() => stateButton(false)}
      ></i>
      <div className="container-track">
        <div className="body-track">
          <div className="div">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleRecaptcha}
            />
          </div>
          <span>{t('tracking.textform')}</span>
          <div className="d-flex flex-column w-100 align-items-start">
            <div className="input-track">
              <input
                type="text"
                placeholder="Ek. ITCMEX123456"
                value={idTracking}
                onChange={handleChange}
              />
            </div>
            {error && (
              <span
                className="text-danger"
                style={{ marginTop: "-20px!important" }}
              >
                {t('tracking.errorID')}
              </span>
            )}
            {validRecaptcha === false && (
              <span
                className="text-danger"
                style={{ marginTop: "-20px!important" }}
              >
                {t('tracking.errorRecaptcha')}
              </span>
            )}
          </div>
        </div>
        <div className="footer-track">
          <button onClick={handleButtonClick}>{t('tracking.button')}</button>
        </div>
      </div>
    </div>
  );
};

export default TrackingComponent;
