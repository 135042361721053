import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import "./Micuenta.css";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../redux/actions/authActions";
import ProfileUser from "../../Components/ProfileUser/ProfileUser";
import {
  createClient,
  requestPasswordReset,
} from "../../Services/clientService";
import SuccessClient from "../Dashboard/Modales/SuccessClient";
import Spinner from "../../Components/loaders/Spinner";
import { useLocation } from "react-router";
import { t } from "i18next";
import SuccessMail from "../Dashboard/Modales/SuccesMail";

const Micuenta = () => {
  const [register, setRegister] = useState(true);
  const [loader, setLoader] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(null);
  const [errormsg, setErrormsg] = useState('');
  const [repeatEmail, setRepeatEmail] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.user !== null);
  const authState = useSelector((state) => state.auth);
  const [isLoading, setIsloading] = useState(false);
  const { user, error } = authState;
  const [inputError, setInputError] = useState({
    email: false,
    password: false,
  });
  const location = useLocation();
  const typeForm = location.state?.type;

  const changeState = () => {
    setRegister((prev) => !prev);
    setResetPassword(false);
    setErrormsg('')
  };
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (typeForm === "resetPassword") {
      setResetPassword(true);
      setRegister(false);
    }
  }, [typeForm]);

  const validateInputs = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validar formato de correo electrónico
    if (!emailRegex.test(credentials.email)) {
      setInputError((prev) => ({
        ...prev,
        email: `${t('account.login.error')}`,
      }));
      return false;
    } else if (emailRegex.test(credentials.email)) {
      setInputError((prev) => ({ ...prev, email: "" }));
    }

    return true;
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    setIsloading(true);
    e.preventDefault();
    // Validar inputs antes de enviar la solicitud
    if (!validateInputs()) {
      setIsloading(false);
      return;
    }
    // Restablecer errores de input si la validación es exitosa
    setInputError({ email: false, password: false });

    dispatch(login(credentials));
    setIsloading(false);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {}, [credentials]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  const handleRegister = async (event) => {
    event.preventDefault();
    var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    if (validEmail.test(email)) {
      if (resetPassword) {
        try {
          const response = await requestPasswordReset({ email: email });
          
          setResetPassword(false);
          setRegister(true);
          setOpenMail(true)
          setErrormsg('')
        } catch (error) {
          console.error("Error al enviar el correo:", error.message);
          
         
          setLoader(false);
          setErrormsg(error.message)
        }
      } else {
        setLoader(true);
        try {
          const response = await createClient({ email: email });
          console.log(response)
          setErrormsg('')
          setLoader(false);
          setOpenSuccess(true);
        } catch (error) {

          console.error(error.message);
         
          setLoader(false);
          setErrormsg(error.message)
          setRepeatEmail(true)
        }
      }
    } else {
      setErrorEmail(true);
    }
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    setErrormsg('')
  };
  const handleResetPassword = () => {
    setRegister(false);
    setResetPassword(true);
    setErrormsg('')
  };
  return (
    <>
      {loader && <Spinner />}
      <SuccessClient
        openModalSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        changeState={changeState}
      />
      <SuccessMail
        openModalSuccess={openMail}
        setOpenSuccess={setOpenMail}
        changeState={changeState}
      />
      <div className="micuentaPage">
        <div className="slider">
          <h1>{t('account.title')}</h1>
          <span>{t('account.breadcrumb.prev')} {">"} {t('account.breadcrumb.name')}</span>
        </div>
        <div className="container py-5">
          {isAuthenticated ? (
            <ProfileUser handleLogout={handleLogout} />
          ) : (
            <div className="row m-0 p-2 container-cuenta">
              {register ? (
                <div className="col-md-6 m-0 p-3 p-lg-5">
                  <h3 className="p-2">{t('account.login.title')}</h3>
                  <form
                    action=""
                    className="row m-0 p-0"
                    onSubmit={handleSubmit}
                  >
                    <div className="col-12 p-2 m-0">
                      <input
                        required
                        className={`form-control ${
                          inputError.email ? "error-form" : ""
                        }`}
                        value={credentials.email}
                        placeholder={`${t('account.login.user')}`}
                        name="email"
                        onChange={handleChange}
                        onBlur={validateInputs}
                        maxLength={100}
                      ></input>
                    </div>
                    {inputError.email !== "" && (
                      <small className="text-danger">{inputError.email}</small>
                    )}
                    <div className="col-12 p-2 m-0 d-flex align-items-center">
                      <input
                        className={`form-control ${
                          inputError.password ? "error-form" : ""
                        }`}
                        value={credentials.password}
                        required
                        placeholder={`${t('account.login.password')}`}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={handleChange}
                        maxLength={100}
                        onBlur={validateInputs}
                      />
                      {showPassword ? (
                        <i
                          className="fa-regular fa-eye-slash"
                          style={{ marginLeft: "-30px", cursor: "pointer" }}
                          onClick={togglePasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye"
                          style={{ marginLeft: "-30px", cursor: "pointer" }}
                          onClick={togglePasswordVisibility}
                        ></i>
                      )}
                    </div>
                    {inputError.password !== "" && (
                      <small className="text-danger">
                        {inputError.password}
                      </small>
                    )}
                    {error && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {error}
                      </p>
                    )}
                    <button className="btn" type="submit" disabled={isLoading}>
                      {isLoading ? (
                        <ThreeDots
                          height="10"
                          width="400"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        t('account.login.button')
                      )}
                    </button>
                    <a href="#" onClick={handleResetPassword}>
                    {t('account.login.recover')}
                    </a>
                  </form>
                </div>
              ) : (
                <div className="col-md-6 m-0 p-3 p-lg-5">
                  <h3 className="p-2">
                    {resetPassword ? `${t('account.login.recover')}` : `${t('account.register.form.title')}`}
                  </h3>
                  <form
                    action=""
                    className="row m-0 p-0"
                    onSubmit={handleRegister}
                  >
                    <div className="col-12 p-2 m-0">
                      <input
                        value={email}
                        onChange={handleChangeEmail}
                        className="form-control"
                        placeholder={`${t('account.register.form.email')}`}
                        type="email"
                      />
                      {repeatEmail && (
                        <small className="text-danger">
                          {t('account.register.form.errorRepeat')} <br/>
                        </small>
                      )}
                      {errormsg !== '' && (
                        <small className="text-danger">

                        {errormsg}
                      </small>
                      )
                      }
                    </div>

                    <small>
                    {t('account.register.form.text1')}
                    </small>
                    {!resetPassword && (
                      <small>
                        {t('account.register.form.text2')}
                      </small>
                    )}

                    <button className="btn" type="submit">
                      {resetPassword ? `${t('account.login.buttonRecover')}` : `${t('account.register.form.button')}`}
                    </button>
                  </form>
                </div>
              )}

              <div className="col-md-6 m-0 p-3 p-lg-5">
                <h3 className="text-center">{t('account.register.form.title')}</h3>
                <p className="pt-3">
                  {t('account.register.description')}
                </p>
                <div className="text-center">
                  <button className="btn-register" onClick={changeState}>
                    {register ? `${t('account.register.buttonRegister')}` : `${t('account.register.buttonLogin')}`}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Micuenta;
