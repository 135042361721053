import React, { useEffect, useState } from 'react'
import './contenido.css'
import { t } from 'i18next'

const Desktop = ({onItemSelected,handleLogout}) => {
    const [dataProfile, setDataProfile] = useState(null)

    const obtenerUsuario = ()=>{
        const usuarioAlmacenado = JSON.parse(localStorage.getItem('user'));
        setDataProfile({
          user_name:usuarioAlmacenado.username,
          user_email:usuarioAlmacenado.email,
          user_photo:usuarioAlmacenado.profile_picture_url
        })
      }
      useEffect(() => {
        obtenerUsuario()
      }, [])
      
  return (
    <div className='escritorio'>
        <p>{t('myAccount.home.welcome')} {dataProfile?.user_name} <b>(¿{t('myAccount.home.question')} {dataProfile?.user_name}?)</b> <a href="" onClick={handleLogout}>{t('myAccount.home.logout2')}</a> </p>
        <p> {t('myAccount.home.description')}</p>
    
        <div className="escritorio-cards">
            
            <div className="item-escritorio" onClick={() => onItemSelected('embarques')}>
            <i className="fa-solid fa-file-lines"></i>
                <span>{t('myAccount.home.orders')}</span>
            </div>
            <div className="item-escritorio" onClick={() => onItemSelected('direccion')}>
            <i className="fa-solid fa-location-dot"></i>
                <span>{t('myAccount.home.address')}</span>
            </div>
            <div className="item-escritorio" onClick={() => onItemSelected('cuenta')}>
            <i className="fa-solid fa-circle-user"></i>
                <span>{t('myAccount.home.account')}</span>
            </div>
            <div className="item-escritorio" onClick={handleLogout}>
            <i className="fa-solid fa-right-from-bracket"></i>
                <span>{t('myAccount.home.logout')}</span>
            </div>
            
        </div>
    </div>
  )
}

export default Desktop