import React from 'react'
import './politicas.css'
import { t } from 'i18next'

const AvisoDePrivacidad = () => {
  return (
    <div className="politicsPage">
      <div className="slider">
      <h1>{t('private.title')}</h1>
          <span>{t('private.breadcrumb.prev')} {'>'} {t('private.breadcrumb.section')} {'>'} {t('private.breadcrumb.name')}</span>
      </div>
      <div className="politicas">
          <div className="container">
              
              <div className="politicas-contenido" dangerouslySetInnerHTML={{ __html: t('private.content') }} />
          </div>
      </div>
    </div>

  )
}

export default AvisoDePrivacidad