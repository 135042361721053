import React, { useEffect, useState } from "react";
import { getUserAddress } from "../../../../Services/perfilService";
import { useSelector } from "react-redux";
import { createAddress, updateAddress } from "../../../../Services/addressService";
import authServiceInstance from "../../../../Services/authService";
import Spinner from "../../../loaders/Spinner";
import UpdateAddress from "../../../../Pages/Dashboard/Modales/UpdateAddress";
import CreateAddress from "../../../../Pages/Dashboard/Modales/CreateAddress";
import SuccessAddress from "../../../../Pages/Dashboard/Modales/SuccesAddress";
import { t } from "i18next";

const Direccion = () => {
  const [addresses, setAddresses] = useState([]);
  const authState = useSelector((state) => state.auth);
  const { user, error } = authState;
  const token = authServiceInstance.getSessionToken();
  const [loader, setLoader] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const fetchClientAddress = async () => {
    try {
      const clientData = await getUserAddress(user.user_id);
      setAddresses(clientData.data.filter((address) => !address.flag_active));
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
   
    fetchClientAddress();
  }, [user]);

  const handleInputChange = (index, field, value) => {
    console.log('Index:', index);
    const updatedAddresses = [...addresses];
    updatedAddresses[index][field] = value;
    setAddresses(updatedAddresses);
    console.log('Updated Addresses:', updatedAddresses);
  };

  const handleDelete = async(index) => {
    const updatedAddress = addresses[index];
    setLoader(true)
    try {
      await updateAddress(updatedAddress.id_address, {
        flag_active:'1'
      }, token);
      setOpenModalUpdate(true)
      
    } catch (error) {
      console.error("Error updating address:", error.message);
    }
    setLoader(false)
  };
  const returnList =()=>{
   
    fetchClientAddress();
    setOpenModalUpdate(false)
    setOpenModalSuccess(false)
  }


  const handleUpdate = async (index) => {
    const updatedAddress = addresses[index];
    setLoader(true)
    try {
      await updateAddress(updatedAddress.id_address, {
        address_name:updatedAddress.address_name,
        city:updatedAddress.city,
        postal_code:updatedAddress.postal_code
      }, token);
      setOpenModalUpdate(true)
      
    } catch (error) {
      console.error("Error updating address:", error.message);
    }
    setLoader(false)

  };

  const handleCreate = async (address) => {
    const createdAddress = address;
    console.log(createdAddress,user.user_id )
    console.log(token)
    setLoader(true)
    try {
      await createAddress({
        address_name:createdAddress.address,
        city:createdAddress.city,
        postal_code:createdAddress.postalCode,
        id_user:user.user_id,
        flag_active:0
      }, token);
      setOpenModalSuccess(true)
      
    } catch (error) {
      console.error("Error updating address:", error.message);
    }
    setLoader(false)

  };

  return (
    <>
    {loader && <Spinner />}
    <div>
      <h2>{`${t('myAccount.address.title')}`}</h2>

      <div className="btn-add" onClick={()=>setOpenModalCreate(true)}>
        <span>{`${t('myAccount.address.subTitle')}`}</span>
      </div>

      {addresses?.length > 0 ? (
        addresses?.filter((address) => !address.flag_active)
        .map((address, index) => (
          <div className="section row my-2" key={index}>
            <div className="mb-3 col-md-4">
              <label htmlFor={`city-${index}`} className="form-label">
              {`${t('myAccount.address.form.city.title')}`}
              </label>
              <input
                type="text"
                value={address?.city ?? ""}
                className={`form-control`}
                id={`city-${index}`}
                name={`city-${index}`}
                aria-describedby={`city-${index}`}
                placeholder={`${t('myAccount.address.form.city.placeholder')}`}
                onChange={(e) => handleInputChange(index, "city", e.target.value)}
              />
            </div>

            <div className="mb-3 col-md-4">
              <label htmlFor={`postalCode-${index}`} className="form-label">
              {`${t('myAccount.address.form.postalCode.title')}`}
              </label>
              <input
                type="text"
                value={address?.postal_code ?? ""}
                className={`form-control`}
                id={`postalCode-${index}`}
                name={`postalCode-${index}`}
                aria-describedby={`postalCode-${index}`}
                placeholder={`${t('myAccount.address.form.postalCode.placeholder')}`}
                onChange={(e) => handleInputChange(index, "postal_code", e.target.value)}
              />
            </div>

            <div className="mb-3 col-md-4">
              <label htmlFor={`addressName-${index}`} className="form-label">
              {`${t('myAccount.address.form.Address.title')}`}
              </label>
              <input
                type="text"
                value={address?.address_name ?? ""}
                className={`form-control`}
                id={`addressName-${index}`}
                name={`addressName-${index}`}
                aria-describedby={`addressName-${index}`}
                placeholder={`${t('myAccount.address.form.Address.placeholder')}`}
                onChange={(e) => handleInputChange(index, "address_name", e.target.value)}
              />
            </div>

            {/* Agrega más campos aquí según sea necesario */}

            <div className="address-buttons">
              <button
                type="button"
                className="btn btn-delete"
                onClick={() => handleDelete(index)}
              >
                {`${t('myAccount.address.delete')}`}
              </button>
              <button
                type="button"
                className="btn btn-update"
                onClick={() => handleUpdate(index)}
              >
                {`${t('myAccount.address.update')}`}
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="text-muted text-sm section row my-2">
          <span>
            Todavía no se han agregado direcciones para este usuario
          </span>
        </div>
      )}
    </div>
    <UpdateAddress
    openModalUpdate={openModalUpdate}
    setOpenModalUpdate={setOpenModalUpdate}
    type={"client"}
    returnList={returnList}
  />
  <CreateAddress
    openModalCreateAddress={openModalCreate}
    setOpenModalCreateAddress={setOpenModalCreate}
    returnList={returnList}
    action={handleCreate}
  />
  <SuccessAddress 
    openModalSuccess={openModalSuccess}
    setOpenSuccess={setOpenModalSuccess}
    returnList={returnList}
  />
    </>
  );
};

export default Direccion;
