import { toast } from "react-toastify";
import { api } from "./api";

export const updateClient = async (
    idClient,
    updatedClientData,
  ) => {
    try {
      const response = await api.put(`/perfilClients/${idClient}`,updatedClientData);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error(`Error updating client with id ${idClient}`);
    }
  };
  
  export const getPerfilOrders = async (idClient) => {
    try {
      const response = await api.get(`/perfilClients/orders/${idClient}`);
  
      return response.data;
    } catch (error) {
      throw new Error("Error fetching client orders data");
    }
  };

  export const getUserAddress = async (idClient) => {
    try {
      const response = await api.get(`/perfilClients/address/${idClient}`);
  
      return response.data;
    } catch (error) {
      throw new Error("Error fetching client address data");
    }
  };

  export const updateClientImgProfile = async (
    idClient,
    updatedClientData,
  ) => {
    try {
      const response = await api.put(`/perfilClients/image/${idClient}`,updatedClientData);
      return response.data;
    } catch (error) {
      if (!error.response.data.success) {
        toast.error(error.response.data.message);
      }
      throw new Error(`Error updating client with id ${idClient}`);
    }
  };

  export const getTiposCambio = async () => {
    try {
      const response = await api.get(`/perfilClients/tipo-cambio`);
  
      return response.data;
    } catch (error) {
      throw new Error("Error fetching tipo cambio data");
    }
  };