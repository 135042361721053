import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useState, useEffect } from "react";
import "./administracion.css";

import { useNavigate } from "react-router";
import authServiceInstance from "../../../../Services/authService";
import InputSearch from "../../../../Components/Dashboard/Inputs/InputSearch/InputSearch";
import ButtonPrimary from "../../../../Components/Dashboard/Buttons/ButtonPrimary";
import SuspendAdmin from "../../Modales/SuspendAdmin";
import { updateAdminstatus } from "../../../../Services/admin";
import DeleteAdmin from "../../Modales/DeleteAdmin";
import ActiveAdmin from "../../Modales/ActiveAdmin";
import { LoadingData } from "../../../../Components/loaders/LoadingData";

const List = (
  { changeStatus, 
    adminsData, 
    fetchAdminList, 
    isLoading,
    currentPage,
    pageSize,
    setCurrentPage,
    setPageSize, 
    onPageChange 
  }) => {
  const [orders, setOrders] = useState();
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalActive, setOpenModalActive] = useState(false);
  const [idUserAdmin, setIdUserAdmin] = useState(0);
  const [ordersAux, setOrdersAux] = useState();
  const [searchValue, setSearchValue] = useState();
  const [filterAll, setFilterAll] = useState(true);
  const [totalCount, setTotalCount] = useState();
  const [filterActive, setFilterActive] = useState(false);
  const [filterDeleted, setFilterDeleted] = useState(false);
  const [statusData, setStatusData] = useState({
    status: 0,
    commentary: "",
  });
  const token = authServiceInstance.getSessionToken();

  let navigate = useNavigate();

  const handleFilter = (event) => {
    setSearchValue(event.target.value);
    const newData = orders.filter((row) => {
      return (
        row.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.last_name
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.email?.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setOrdersAux(newData);
  };

  const applyFilters = () => {
    if (filterAll) {
      setOrdersAux(orders);
    } else if (filterActive) {
      const activeOrders = orders.filter(
        (row) => row.id_status !== 39 && row.id_status !== 16
      );
      setOrdersAux(activeOrders);
    } else if (filterDeleted) {
      const deletedOrders = orders.filter((row) => row.id_status === 39);
      setOrdersAux(deletedOrders);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [filterAll, filterActive, filterDeleted, orders]);

  const handleDelete = async () => {
    try {
      await updateAdminstatus(idUserAdmin, statusData, token);

      setIdUserAdmin(0);
      setStatusData({ status: 0, commentary: "" });
      setOpenModalDelete(false);

      fetchAdminList();
    } catch (error) {
      console.error("Error deleting order:", error.message);
    }
  };

  const handleStatus = async () => {
    try {
      await updateAdminstatus(idUserAdmin, statusData, token);
      setOpenModalActive(false);
      setIdUserAdmin(0);
      setStatusData({ status: 0, commentary: "" });
      setOpenModalStatus(false);
      fetchAdminList();
    } catch (error) {
      console.error("Error deleting order:", error.message);
    }
  };

  useEffect(() => {
    setOrders(adminsData?.data);
    setOrdersAux(adminsData?.data);
    setTotalCount(adminsData?.totalCount);
  }, [adminsData]);

  const customCell = (row) => (
    <div className="dropdown drop-menu">
      <button
        className="btn btn-secondary dropdown-toggle action-menu"
        type="button"
        id={`dropdownMenuButton${row.id_user}`}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </button>
      <ul
        className="dropdown-menu list-menu p-0"
        aria-labelledby={`dropdownMenuButton${row.id_user}`}
      >
        <li>
          <a
            className="dropdown-item"
            href="#"
            onClick={() => handleMenuClick(row, "edit")}
          >
            Ver perfil{" "}
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            href="#"
            onClick={() => handleMenuClick(row, "status")}
          >
            {row.status === 0 ? "Activar usuario" : "Suspender usuario"}
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            href="#"
            onClick={() => handleMenuClick(row, "delete")}
          >
            Eliminar usuario
          </a>
        </li>
      </ul>
    </div>
  );

  const columns = [
    {
      name: "Nombre",
      sortable: true,
      selector: (row) => row.name + " " + row?.last_name,
    },
    {
      name: "Correo Electrónico",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Rol",
      selector: (row) => row.role?.role_name,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div>
          {row.status === 0 ? (
            <span className="status-delete">Suspendido</span>
          ) : (
            <span className="status-active">Activo</span>
          )}
        </div>
      ),
    },
    {
      name: "Acciones",
      cell: customCell,
    },
  ];

  const handleMenuClick = (admin, type) => {
    if (type === "status") {
      setIdUserAdmin(admin.id_user);
      if (admin.status === 0) {
        setOpenModalActive(true);
        setStatusData({
          status: 1,
          commentary: "",
        });
        return;
      } else {
        setOpenModalStatus(true);
        setStatusData({
          status: admin.status,
          commentary: "",
        });
      }
    } else if (type === "delete") {
      setStatusData({
        status: 0,
        commentary: "",
        flag: 0,
      });
      setOpenModalDelete(true);
      setIdUserAdmin(admin.id_user);
    } else if (type === "edit") {
      navigate(`/admin-itc/administracion/${admin.id_user}`, {
        state: { data: admin },
      });
    }
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        color: "#6B6B6B",
        fontWeight: 600,
        fontFamily: "Manrope",
      },
    },
    cells: {
      style: {
        fontSize: "15px",
        color: "#001222",
        fontWeight: 500,
        fontFamily: "Manrope",
      },
    },
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,

    selectAllRowsItemText: "Todos",
  };

  return (
    <>
      <SuspendAdmin
        openModalStatus={openModalStatus}
        setOpenModalStatus={setOpenModalStatus}
        action={handleStatus}
        setStatusData={setStatusData}
        statusData={statusData}
      />
      <DeleteAdmin
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        action={handleDelete}
        setStatusData={setStatusData}
        statusData={statusData}
      />
      <ActiveAdmin
        openModalActive={openModalActive}
        setOpenModalActive={setOpenModalActive}
        action={handleStatus}
        setStatusData={setStatusData}
        statusData={statusData}
      />
      <div className="row">
        <div className="col-12 col-sm-8 col-md-10 mt-1">
          <InputSearch
            value={searchValue}
            setValue={handleFilter}
            filterLabel="por Nombre o Correo Electrónico"
          />
        </div>
        <div
          className="col-12 col-sm-4 col-md-2 mt-1"
          style={{ minHeight: "35px" }}
        >
          <ButtonPrimary change={changeStatus} text="AÑADIR USUARIO" />
        </div>
        <div className="col-md-12 filters"></div>
        <div className="col-md-12">
          <DataTable
            pagination
            paginationServer
            paginationTotalRows={totalCount}
            paginationPerPage={pageSize}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangePage={onPageChange}
            
            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
              setPageSize(currentRowsPerPage);
              setCurrentPage(currentPage);
            }}
            columns={columns}
            noDataComponent={"No hay registros para mostrar"}
            paginationComponentOptions={paginationComponentOptions}
            data={ordersAux}
            customStyles={customStyles}
            progressPending={isLoading}
            progressComponent={
              <div className="my-6 mx-auto">
                <p className="mb-3">Cargando Datos...</p>
                <LoadingData />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default List;
