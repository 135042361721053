import React, { useEffect, useState } from 'react'

import { FiAlertOctagon} from 'react-icons/fi';

const SuspendUser = ({statusData, setStatusData, openModalStatus, setOpenModalStatus, action}) => {

    const handleCloseModal = () => {
        setOpenModalStatus(false);
    };
    const changeData = (e)=>{
      const { name, value } = e.target;
      setStatusData((prevData) => ({
        ...prevData,
        [name]: value,
        status:2
      }));
    }

   
  return (
    <div>
      

      {openModalStatus && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                  <div className="modal-cabecera">
                    <div className="modal-icon pb-4">
                        <span className='icon-container'><FiAlertOctagon /></span>
                    </div>
                    <div className="modal-question">
                        <span>Suspender Usuario</span>
                        <small>El usuario no podrá acceder a la plataforma, pero su información no será eliminada</small>
                        <div className="mt-2 modal-form">
                            <label htmlFor="" >MOTIVO</label>
                            <textarea name="commentary" id="" value={statusData.commentary}  onChange={changeData} className='form-control mt-2' cols="30" rows="3" placeholder='Motivo'></textarea>
                        </div>
                    </div>
                    
                  </div>
                  <div className="modal-footer">
                    <a className='btn-cancel'  onClick={handleCloseModal}>CANCELAR</a>
                    <a className='btn-acept' onClick={action}>ACEPTAR</a>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SuspendUser