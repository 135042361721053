import React from "react";
import { useState } from "react";

import { useNavigate, useParams } from "react-router";
import UpdateModal from "../../Modales/UpdateModal";
import authServiceInstance from "../../../../Services/authService";
import Spinner from "../../../../Components/loaders/Spinner";
import ImageUploader from "../../../../Components/Dashboard/Inputs/Image/ImageUploader";
import { createAdmin } from "../../../../Services/admin";

const Create = ({ changeTap, fetchAdminList }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    password: "",
    status: 1,
    email: "",
    role_id: 2,
    profile_picture_url: "",
  });
  const token = authServiceInstance.getSessionToken();

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const returnList = () => {
    changeTap();
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{}[\]:;<>,.?/\\])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    if (formData?.name === "" || formData?.name?.length < 2) {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }

    if (formData.last_name === "" || formData?.last_name?.length < 2) {
      setLastNameError(true);
      return;
    } else {
      setLastNameError(false);
    }
    if (!emailRegex.test(formData.email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    if (!passwordRegex.test(formData.password)) {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    crearAdmin();
  };

  const crearAdmin = async () => {
    const nuevosDatosDeAdmin = formData;
    const newData = {
      ...formData,
      profile_picture_url: imageProfile,
    };
    setLoader(true);
    try {
      const nuevoAdmin = await createAdmin(newData, token);
      setOpenModalUpdate(true);
      setLoader(false);
      fetchAdminList();
    } catch (error) {
      console.error("Error al crear un nuevo admin:", error.message);
      setLoader(false);
    }
  };

  const handleChangeImageProfile = (img) => {
    if (img) {
      setImageProfile(img);
    } else {
      setImageProfile(null);
    }
  };
  return (
    <>
      {loader && <Spinner />}
      <>
        <div className="container-step">
          <form className="p-0 m-0 w-100">
            <section className="section-step">
              <h3>Datos personales</h3>
            </section>
            <div className="section row m-0">
              <div className="mb-3 col-md-5 d-flex row m-0 p-0">
                <div className="col-md-4 col-lg-3">
                  <ImageUploader
                    dataImg={imageProfile}
                    setValor={handleChangeImageProfile}
                  />
                </div>
                <div className="col-md-8 col-lg-9 col-12">
                  <label htmlFor="name" className="form-label">
                    Nombre
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    className={`form-control ${
                      nameError ? "invalid-input" : ""
                    }`}
                    id="name"
                    name="name"
                    aria-describedby="name"
                    placeholder="Ingresa nombre"
                  />
                </div>
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="last_name" className="form-label">
                  Apellido
                </label>
                <input
                  type="text"
                  value={formData.last_name}
                  onChange={handleChange}
                  className={`form-control ${
                    lastNameError ? "invalid-input" : ""
                  }`}
                  id="last_name"
                  name="last_name"
                  aria-describedby="last_name"
                  placeholder="Ingresa apellido"
                />
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="role_id" className="form-label">
                  Rol
                </label>
                <select
                  value={formData.role_id}
                  onChange={handleChange}
                  className="form-select"
                  id="role_id"
                  name="role_id"
                  aria-describedby="role_id"
                >
                  <option value="1">Administrador</option>
                  <option value="2">Costumer Services</option>
                </select>
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="status" className="form-label">
                  Estado Usuario
                </label>
                <select
                  value={formData.status}
                  onChange={handleChange}
                  className="form-select"
                  id="status"
                  name="status"
                  aria-describedby="status"
                >
                  <option value="1">Activo</option>
                  <option value="2">Suspendido</option>
                </select>
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="email" className="form-label">
                  Correo electrónico
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`form-control ${
                    emailError ? "invalid-input" : ""
                  }`}
                  id="email"
                  name="email"
                  aria-describedby="email"
                  placeholder="Ingresa correo"
                />
                {emailError && <small>Formato de correo inválido </small>}
              </div>
              <div className=" mb-3 col-md-4 form-group form-group-password password-admin">
                <label htmlFor="password" className="form-label">
                  Asignar contraseña
                </label>
                <input
                  required
                  type={showPassword ? "text" : "password"}
                  className={`form-control ${
                    passwordError ? "invalid-input" : ""
                  }`}
                  id="password"
                  onChange={handleChange}
                  name="password"
                  value={formData.password}
                  placeholder="Contraseña"
                  maxLength={100}
                />
                {showPassword ? (
                  <i
                    className="fa-regular fa-eye-slash password-icon"
                    onClick={togglePasswordVisibility}
                  ></i>
                ) : (
                  <i
                    className="fa-regular fa-eye password-icon"
                    onClick={togglePasswordVisibility}
                  ></i>
                )}
                {passwordError && (
                  <small>
                    Formato inválido, mínimo 8 caracteres, una mayúscula, un
                    numero y un carácter especial
                  </small>
                )}
              </div>
            </div>
            <div className="section-button pb-2 gap-2">
              <button
                type="button"
                className=" btn-cancelar ms-auto"
                onClick={returnList}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn-next mr-1"
                onClick={handleSubmit}
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
        <UpdateModal
          openModalUpdate={openModalUpdate}
          setOpenModalUpdate={setOpenModalUpdate}
          type={"admin-create"}
          returnList={returnList}
        />
      </>
    </>
  );
};

export default Create;
