import React from "react";
import { LoadingData } from "../../../Components/loaders/LoadingData";
import { FiAlertOctagon } from "react-icons/fi";

const DeleteAddress = ({
  openModalDelete,
  setOpenModalDelete,
  action,
  currentIndex,
  isLoading,
}) => {
  const handleCloseModal = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = () => {
    action(currentIndex);
  };

  return (
    <div>
      {openModalDelete && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                <div className="modal-cabecera">
                  <div className="modal-icon pb-4">
                    <span className="icon-container">
                      <FiAlertOctagon />
                    </span>
                  </div>
                  <div className="modal-question">
                    <span>Eliminar Dirección</span>
                    <small>
                      Recuerda que eliminar una dirección es una acción
                      irreversible. Si deseas continuar dale en aceptar
                    </small>
                  </div>
                </div>
                {isLoading ? (
                  <div className="mx-auto my-4">
                    <LoadingData />
                  </div>
                ) : (
                  <div className="modal-footer p-0 pt-3">
                    <a className="btn-cancel m-0" onClick={handleCloseModal}>
                      Cancelar
                    </a>
                    <a className="btn-acept m-0" onClick={handleDelete}>
                      Aceptar
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteAddress;
