import { toast } from "react-toastify";
import { api } from "./api";

export const getOrderStatus = async (sessionToken) => {
  try {
    const response = await api.get("/order-status", {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Error fetching order status data");
  }
};

export const getListOrders = async (sessionToken, page = 1, pageSize = 10 , searchTerm ) => {
  try {
    const response = await api.get("/orders", {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm
      },
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Error fetching list orders");
  }
};
export const createOrder = async (orderData, sessionToken) => {
  try {
    const response = await api.post("/orders", orderData, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (!error.response.data.success) {
      toast.error(error.response.data.message);
    }
    throw new Error("Error creating new order");
  }
};

export const updateOrderStatus = async (
  idServiceOrden,
  statusData,
  sessionToken
) => {
  try {
    const response = await api.patch(
      `/orders/status/${idServiceOrden}`,
      statusData,
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Error updating order status");
  }
};

export const getOrderById = async (idServiceOrden, sessionToken) => {
  try {
    const response = await api.get(`/orders/${idServiceOrden}`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error) {
    return [];
    //throw new Error('Error fetching order by ID');
  }
};

export const getTrackById = async (idServiceOrden) => {
  try {
    const response = await api.get(`/orders/tracking/${idServiceOrden}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    return [];
    //throw new Error('Error fetching order by ID');
  }
};

export const updateOrder = async (
  idServiceOrden,
  updatedOrderData,
  sessionToken
) => {
  try {
    const response = await api.put(
      `/orders/${idServiceOrden}`,
      updatedOrderData,
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(`Error updating order with id ${idServiceOrden}`);
  }
};
