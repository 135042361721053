import React, { useState } from 'react';
import { FiCheck } from "react-icons/fi";
import './modals.css';
import { useNavigate } from 'react-router';

const CreateAddress = ({ openModalCreateAddress, setOpenModalCreateAddress, returnList, action }) => {
  const navigate = useNavigate();
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');

  const handleCloseModal = () => {
    setOpenModalCreateAddress(false);
  };

  const handleReturnOrderList = () => {
    returnList();
  };

  const handleCreateAddress = () => {
    console.log(city,postalCode,address)
    action({
      city:city,
      postalCode:postalCode,
      address:address
    })
    setCity('')
    setPostalCode('')
    setAddress('')
    setOpenModalCreateAddress(false);
  };

  return (
    <div>
      {openModalCreateAddress && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                <div className="modal-cabecera type-save pt-1">
                  {/*<div className="modal-icon icon-save">
                    <span className='icon-container icon-check'><FiCheck /></span>
                  </div>*/}
                  <div className="modal-question">
                    <span>Registrar Dirección</span>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                  <label htmlFor="city">Ciudad</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={city}
                      placeholder='Ingrese la ciudad'
                      className='form-control'
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="postalCode">Código Postal</label>
                    <input
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      value={postalCode}
                      placeholder='Ingrese el código postal'
                      className='form-control'
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address">Dirección</label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={address}
                      placeholder='Ingrese la dirección'
                      className='form-control'
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="modal-footer footer-save pb-0">
                  <a className='btn-acept' onClick={handleCreateAddress}>Aceptar</a>
                  <a className='btn-cancel' onClick={()=>setOpenModalCreateAddress(false)}>Cancelar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAddress;
