import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import UpdateModal from '../../../../Pages/Dashboard/Modales/UpdateModal';
import Spinner from '../../../loaders/Spinner';
import { updateClient } from '../../../../Services/perfilService';
import { t } from 'i18next';
const Cuenta = () => {
  const authState = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const { user, error } = authState;
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [data, setData] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [repeatPasswordError, setRepeatPasswordError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [cellError, setCellError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [positionError, setPositionError] = useState(null);
  const [token, setToken] = useState(null)
  const [idClient, setIdClient] = useState(null)
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    userName:"",
    position: "",
    email: "",
    phone: "",
    cell: "",
    password: "",
    repeatPassword:""  
  });
  const fetchData = () => {
    const clientData = JSON.parse(localStorage.getItem('user'));
    console.log(clientData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: clientData?.name,
      userName: clientData?.username,
      email: clientData?.email,
      password: "",
      repeatPassword:"",
      last_name: clientData?.last_name,
      position: clientData?.position,
      phone: clientData?.number_phone_office,
      cell: clientData?.number_phone,
    }));
 setToken(clientData.session_token)
 setIdClient(clientData.id)
};
  useEffect(() => {
    

    fetchData();
  }, [user]);

  useEffect(() => {
    setData({
      name: formData.name ,
      last_name: formData.last_name ,
      user_name: formData.userName ,
      email: formData.email ,
      position: formData.position ,
      phone: formData.phone ,
      cell: formData.cell,
      ...(formData.password && {
        password: formData.password,
      }),
    });
  }, [formData])
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes("company")) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        company: {
          ...prevFormData.company,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{}[\]:;<>,.?/\\])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    if (formData?.name === "" || formData?.name?.length < 2) {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }
    if (formData.last_name === "" || formData?.last_name?.length < 2) {
      setLastNameError(true);
      return;
    } else {
      setLastNameError(false);
    }
    if (formData.position === "" || formData?.position?.length < 2) {
      setPositionError(true);
      return;
    } else {
      setPositionError(false);
    }
    if (!emailRegex.test(formData.email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }
    if (formData.phone === "" || formData?.phone?.length < 2) {
      setPhoneError(true);
      return;
    } else {
      setPhoneError(false);
    }
    if (formData.cell === "" || formData?.cell?.length < 2) {
      setCellError(true);
      return;
    } else {
      setCellError(false);
    }
    if (!passwordRegex.test(formData.password) && formData.password !== "") {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }
    if (formData.password !== "")  {
      if(formData.repeatPassword !== formData.password){
        setRepeatPasswordError(true);
        return;
      }
      else {
        setRepeatPasswordError(false);
      }
    } else {
      setRepeatPasswordError(false);
    }
    actualizarCliente();
  };
  const actualizarCliente = async () => {
    const nuevosDatosDeCliente = data;
    console.log(nuevosDatosDeCliente);
    try {
      setLoader(true);
      const clienteActualizado = await updateClient(
        idClient,
        nuevosDatosDeCliente,
      );
      console.log("actualizacion:", clienteActualizado);
      setOpenModalUpdate(true);
    } catch (error) {
      console.error("Error al actualizar el cliente:", error.message);
    }
    setLoader(false);
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2((prev) => !prev);
  };
  const returnList =()=>{
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      password: "",
      repeatPassword:"",
    }));

    actualizarLocalStorage()
    setOpenModalUpdate(false)
  }

  const actualizarLocalStorage = ()=>{
    const usuarioAlmacenado = JSON.parse(localStorage.getItem('user'));

    if (usuarioAlmacenado) {
      usuarioAlmacenado.email = formData.email;
      usuarioAlmacenado.last_name = formData.last_name;
      usuarioAlmacenado.name = formData.name;
      usuarioAlmacenado.number_phone = formData.cell;
      usuarioAlmacenado.number_phone_office = formData.phone;
      usuarioAlmacenado.position = formData.position;
      usuarioAlmacenado.username = formData.userName;

      localStorage.setItem('user', JSON.stringify(usuarioAlmacenado));

      console.log('Campos actualizados exitosamente');
    } else {
      console.log('No se encontró el objeto en localStorage');
    }
    fetchData()
  }

  return (
    <>
    {loader && <Spinner />}
    <div>
        <h2>{t('myAccount.account.title')}</h2>
        <form className="p-0 m-0 w-100">
                  <section className="section-step">
                    <h4>{t('myAccount.account.subTitle1')}</h4>
                  </section>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-3 ">
                        <label htmlFor="name" className="form-label">
                        {t('myAccount.account.form.name.title')}
                        </label>
                        <input
                          required
                          type="text"
                          value={formData.name}
                          onChange={handleChange}
                          className={`form-control ${
                            nameError ? "invalid-input" : ""
                          }`}
                          id="name"
                          name="name"
                          aria-describedby="name"
                          placeholder={`${t('myAccount.account.form.name.placeholder')}`}
                        />
                    </div>
                    <div className="mb-3 col-md-3 ">
                      <label htmlFor="last_name" className="form-label">
                      {t('myAccount.account.form.lastName.title')}
                      </label>
                      <input
                        required
                        type="text"
                        value={formData.last_name}
                        onChange={handleChange}
                        className={`form-control ${
                          lastNameError ? "invalid-input" : ""
                        }`}
                        id="last_name"
                        name="last_name"
                        aria-describedby="last_name"
                        placeholder={`${t('myAccount.account.form.lastName.placeholder')}`}
                      />
                    </div>
                    <div className="mb-3 col-md-3 ">
                        <label htmlFor="userName" className="form-label">
                        {t('myAccount.account.form.user.title')}
                        </label>
                        <input
                          required
                          type="text"
                          value={formData.userName}
                          onChange={handleChange}
                          className={`form-control ${
                            nameError ? "invalid-input" : ""
                          }`}
                          id="userName"
                          name="userName"
                          aria-describedby="userName"
                          placeholder={`${t('myAccount.account.form.user.placeholder')}`}
                        />
                    </div>
                    <div className="mb-3 col-md-3 ">
                      <label htmlFor="position" className="form-label">
                      {t('myAccount.account.form.position.title')}
                      </label>
                      <input
                        required
                        type="text"
                        value={formData.position}
                        onChange={handleChange}
                        className={`form-control ${
                          positionError ? "invalid-input" : ""
                        }`}
                        id="position"
                        name="position"
                        aria-describedby="position"
                        placeholder={`${t('myAccount.account.form.position.placeholder')}`}
                      />
                    </div>

                    <div className="mb-3 col-md-4">
                      <label htmlFor="email" className="form-label">
                      {t('myAccount.account.form.email.title')}
                      </label>
                      <input
                        required
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`form-control ${
                          emailError ? "invalid-input" : ""
                        }`}
                        id="email"
                        name="email"
                        aria-describedby="email"
                        placeholder={`${t('myAccount.account.form.email.placeholder')}`}
                      />
                      {emailError && <small>Formato de correo inválido </small>}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="phone" className="form-label">
                      {t('myAccount.account.form.phone.title')}
                      </label>
                      <input
                        type="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className={`form-control ${
                          phoneError ? "invalid-input" : ""
                        }`}
                        id="phone"
                        name="phone"
                        aria-describedby="phone"
                        placeholder={`${t('myAccount.account.form.phone.placeholder')}`}
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="cell" className="form-label">
                      {t('myAccount.account.form.cell.title')}
                      </label>
                      <input
                        type="phone"
                        value={formData.cell}
                        onChange={handleChange}
                        className={`form-control ${
                          cellError ? "invalid-input" : ""
                        }`}
                        id="cell"
                        name="cell"
                        aria-describedby="cell"
                        placeholder={`${t('myAccount.account.form.cell.placeholder')}`}
                      />
                    </div>
                  </div>

                  <section className="section-step">
                    <h4>{t('myAccount.account.subTitle2')}</h4>
                  </section>
                  <div className="section row m-0">
                  <div className=" mb-3 col-md-6 form-group form-group-password password-admin">
                      <label htmlFor="password" className="form-label">
                      {t('myAccount.account.formPassword.password.title')}
                      </label>
                      <input
                        required
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${
                          passwordError ? "invalid-input" : ""
                        }`}
                        id="password"
                        onChange={handleChange}
                        name="password"
                        value={formData.password}
                        placeholder={`${t('myAccount.account.formPassword.password.placeholder')}`}
                        maxLength={100}
                      />
                      {showPassword ? (
                        <i
                          className="fa-regular fa-eye-slash password-icon"
                          onClick={togglePasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye password-icon"
                          onClick={togglePasswordVisibility}
                        ></i>
                      )}
                      {passwordError && (
                        <small>
                          Formato inválido, mínimo 8 caracteres, una mayúscula,
                          un número y un carácter especial
                        </small>
                      )}
                    </div>
                    <div className=" mb-3 col-md-6 form-group form-group-password password-admin">
                      <label htmlFor="password" className="form-label">
                      {t('myAccount.account.formPassword.repeatPassword.title')}
                      </label>
                      <input
                        required
                        type={showPassword2 ? "text" : "password"}
                        className={`form-control ${
                          repeatPasswordError ? "invalid-input" : ""
                        }`}
                        id="repeatPassword"
                        onChange={handleChange}
                        name="repeatPassword"
                        value={formData.repeatPassword}
                        placeholder={`${t('myAccount.account.formPassword.repeatPassword.placeholder')}`}
                        maxLength={100}
                      />
                      {showPassword2 ? (
                        <i
                          className="fa-regular fa-eye-slash password-icon"
                          onClick={togglePasswordVisibility2}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye password-icon"
                          onClick={togglePasswordVisibility2}
                        ></i>
                      )}
                      {repeatPasswordError && (
                        <small>
                          Por favor revise las contraseñas, deben ser iguales.
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="section-button pb-2 gap-2">
                    <button
                      type="button"
                      className="btn-next mr-1"
                      onClick={handleSubmit}
                    >
                      {`${t('myAccount.account.button')}`}
                    </button>
                  </div>
                </form>
    </div>
    <UpdateModal
    openModalUpdate={openModalUpdate}
    setOpenModalUpdate={setOpenModalUpdate}
    type={"client"}
    returnList={returnList}
  />
  </>
  )
}

export default Cuenta