import { t } from "i18next";
import React, { useState } from "react";
import ModalTipoCambio from "../../Pages/Dashboard/Modales/ModalTipoCambio";
import NavBar from "../NavBar/NavBar";
import "./Header.css";

const Header = ({changeLanguage}) => {
  const [openModalTipoCambio, setOpenModalTipoCambio] = useState(false);

  

  return (
    <>
    <ModalTipoCambio
    openModalTipoCambio={openModalTipoCambio}
    setOpenModalTipoCambio={setOpenModalTipoCambio}
    return={()=>setOpenModalTipoCambio(false)}
  />
      <div className="topbar px-3">
        <div className="m-0 row d-flex ">
          <div className="col-5 d-flex justify-content-between p-3">
            <div className="">
            {t('header.title')}
            </div>
             
          </div>
          <div className="col-7 d-flex">
         
            <ul className="navbar-nav flex-row flex-wrap ms-md-auto politicas">
            {/*<div  
            className="d-flex align-items-center"
              onClick={()=>setOpenModalTipoCambio(true)}
              style={{cursor:'pointer'}}
              >{t('header.rates')}
              </div>*/} 
              <li className="nav-item  sociales p-3">
                <a href="https://www.facebook.com/profile.php?id=61551280761271" target="_blank">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/itcconsultancy" target="_blank">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/in/itc-international-trade-consultancy" target="_blank">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
                <a href="https://www.tiktok.com/@itcconsultancy" target="_blank">
                  <i className="fa-brands fa-tiktok"></i>
                </a>
              </li>
              <li className="nav-item  border-start p-3 border-dark-subtle">
                <a href="/contacto">{t('header.contactUs')}</a>
              </li>
              <li className="nav-item col border-start p-3 border-dark-subtle">
                <a href="/#faqs">FAQS</a>
              </li>
              <li className="nav-item  border-start border-end p-3 border-dark-subtle dropdown ">
                <a
                  className="dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                 {t('header.policies.title')}
                </a>
                <ul className="dropdown-menu ">
                  <li>
                    <a
                      className="dropdown-item"
                      href="/politicas/aviso-de-privacidad"
                    >
                      {t('header.policies.policy1')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/politicas/terminos-y-condiciones"
                    >
                      {t('header.policies.policy2')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/politicas/politica-de-garantia"
                    >
                      {t('header.policies.policy3')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/politicas/politica-de-devoluciones-y-reembolsos"
                    >
                      {t('header.policies.policy4')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/politicas/otras-politicas"
                    >
                      {t('header.policies.policy5')}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <NavBar changeLanguage={changeLanguage}/>
    </>
  );
};

export default Header;
