import { api } from "./api";

export const getListClient = async (sessionToken, status = 0, page = 1, pageSize = 10) => {
  try {
    let url = '/adminClients';
    let params = { page, pageSize };

    if (status !== 0) {
      params.status = status;
    }

    const response = await api.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Error fetching list clients");
  }
};

export const createClient = async (clientData, sessionToken) => {
  try {
    const response = await api.post("/adminClients", clientData, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error creating new client");
  }
};

export const updateAdminstatus = async (
  idUserAdmin,
  statusData,
  sessionToken
) => {
  try {
    const response = await api.patch(`/admin/${idUserAdmin}`, statusData, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Error updating admin status");
  }
};

export const getClientById = async (idUserAdmin, sessionToken) => {
  try {
    const response = await api.get(`/adminClients/${idUserAdmin}`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    return [];
    //throw new Error('Error fetching order by ID');
  }
};

export const getTrackById = async (idServiceOrden) => {
  try {
    const response = await api.get(`/orders/tracking/${idServiceOrden}`);
    return response.data;
  } catch (error) {
    return [];
    //throw new Error('Error fetching order by ID');
  }
};

export const updateClient = async (
  idClient,
  updatedClientData,
  sessionToken
) => {
  try {
    const response = await api.put(
      `/adminClients/${idClient}`,
      updatedClientData,
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(`Error updating admin with id ${idClient}`);
  }
};
