import React from 'react'
import { FiAlertOctagon } from "react-icons/fi";
import './modals.css'
const DeleteModal = ({statusData, setStatusData, openModalDelete, setOpenModalDelete, action}) => {
    const handleCloseModal = () => {
        setOpenModalDelete(false);
    };
    const changeData = (e)=>{
      const { name, value } = e.target;
      setStatusData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    
  return (
    <div>
      

      {openModalDelete && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                  <div className="modal-cabecera">
                    <div className="modal-icon pb-4">
                        <span className='icon-container'><FiAlertOctagon /></span>
                    </div>
                    <div className="modal-question">
                        <span>Eliminar orden</span>
                        <small>¿Estás seguro que quiere eliminar esta orden? Si es así, escriba el motivo</small>
                        <div className="mt-2 modal-form">
                            <label htmlFor="" >MOTIVO</label>
                            <textarea name="note" id="" value={statusData.note} onChange={changeData} className='form-control mt-2' cols="30" rows="3" placeholder='Escriba su motivo'></textarea>
                        </div>
                    </div>
                  </div>
                  <div className="modal-footer p-0 pt-3">
                    <a className='btn-cancel m-0'  onClick={handleCloseModal}>Cancelar</a>
                    <a className='btn-acept m-0' onClick={action}>Aceptar</a>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeleteModal