import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import './index.css';
const index = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [serviceType, setServiceType] = useState('')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [name, setName] = useState('')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();
    const currentUrl = location.hash;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        let targetTab = ''
        let targetContent= ''
        let tabPanes = document.querySelectorAll('.nav-link');
        tabPanes.forEach(function (tabPane) {
            tabPane.classList.remove('active');
          });
        let tabContent = document.querySelectorAll('.tab-pane');
        tabContent.forEach(function (content) {
            content.classList.remove('active','show');
          });
          
        if(currentUrl.includes('terrestre')){
            setName(`${t('services.services.service1.name')}`)
            targetTab = document.getElementById('pills-terrestre-tab');
            targetTab.classList.add('active');
            targetContent = document.getElementById('pills-terrestre');
            targetContent.classList.add('active','show');
        }
        if(currentUrl.includes('maritimo')){
            setName(`${t('services.services.service2.name')}`)
            targetTab = document.getElementById('pills-maritimo-tab');
            targetTab.classList.add('active');
            targetContent = document.getElementById('pills-maritimo');
            targetContent.classList.add('active','show');
        }
        if(currentUrl.includes('aereo')){
            setName(`${t('services.services.service3.name')}`)
            targetTab = document.getElementById('pills-aereo-tab');
            targetTab.classList.add('active');
            targetContent = document.getElementById('pills-aereo');
            targetContent.classList.add('active','show');
        }
        if(currentUrl.includes('almacenaje')){
            setName(`${t('services.services.service4.name')}`)
            targetTab = document.getElementById('pills-almacenaje-tab');
            targetTab.classList.add('active');
            targetContent = document.getElementById('pills-almacenaje');
            targetContent.classList.add('active','show');
        }
        setServiceType(currentUrl)
    }, [currentUrl])

    const goTobrochure = ()=>{
        window.open('https://drive.google.com/file/d/1oZhWr-vHV5ydz6UGaIDV_Ayd3OmKNXOy/view', '_blank');
    }
    
    return (
        <div className="servicesPage">
            <div className="slider">
                <h1>{name}</h1>
                <span>{t('services.breadcrumb.prev')} {'>'} {t('services.breadcrumb.section')} {'>'} {name}</span>
            </div>
            <div className="servicios container">
                <div className="servicios-lista">
                    <span>{t('services.subTitle')}</span>
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button onClick={() => window.location.hash = 'terrestre'} className="nav-link active" id="pills-terrestre-tab" data-bs-toggle="pill" data-bs-target="#pills-terrestre" type="button" role="tab" aria-controls="pills-terrestre" aria-selected="true">{t('services.services.service1.name')} <span><i className="fa-solid fa-angle-right"></i></span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => window.location.hash = 'maritimo'} className="nav-link" id="pills-maritimo-tab" data-bs-toggle="pill" data-bs-target="#pills-maritimo" type="button" role="tab" aria-controls="pills-maritimo" aria-selected="false">{t('services.services.service2.name')} <span><i className="fa-solid fa-angle-right"></i></span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => window.location.hash = 'aereo'} className="nav-link" id="pills-aereo-tab" data-bs-toggle="pill" data-bs-target="#pills-aereo" type="button" role="tab" aria-controls="pills-aereo" aria-selected="false">{t('services.services.service3.name')} <span><i className="fa-solid fa-angle-right"></i></span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => window.location.hash = 'almacenaje'} className="nav-link" id="pills-almacenaje-tab" data-bs-toggle="pill" data-bs-target="#pills-almacenaje" type="button" role="tab" aria-controls="pills-almacenaje" aria-selected="false" >{t('services.services.service4.name')} <span><i className="fa-solid fa-angle-right"></i></span></button>
                        </li>
                    </ul>
                    <div className="brochure">
                        <div className='body-brochure'>
                            <small>{t('services.brochure.subTitle')}</small>
                            <span>{t('services.brochure.title')}</span>
                        </div>
                        <div className="download" onClick={goTobrochure}>
                            <span><i className="fa-solid fa-chevron-right"></i> {t('services.brochure.download')}</span>
                        </div>
                    </div>
                </div>
                <div className=" servicios-contenido tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-terrestre" role="tabpanel" aria-labelledby="pills-terrestre-tab" tabIndex="0">
                        <h3>Servicio de transporte nacional terrestre</h3>
                        <p>Con una sólida experiencia en logística y una extensa red de socios, brindamos un servicio de transporte terrestre de primer nivel. Ya sea que necesites transportar carga a nivel nacional o internacional, ¡nosotros lo hacemos posible!
                        Nuestros servicios incluyen la gestión del transporte para las adquisiciones, entre las plantas y los embarques internos, así como la organización de transporte de distribución a los clientes de nuestros clientes y la transferencia de mercancías entre las sucursales y centros de producción.
                        </p>
                        <p>Somos un socio flexible y confiable cuando se trata de gestionar los envíos de nuestros clientes. Elegimos el modo apropiado de transporte para adaptarse a cada necesidad.</p>
                        <h4>{t('services.services.service1.subTitle')}</h4>
                        <ul>
                            <li><i className="fa-solid fa-check"></i> Transporte de carga contenerizada</li>
                            <li><i className="fa-solid fa-check"></i> Sobredimensionada</li>
                            <li><i className="fa-solid fa-check"></i> Justo a tiempo, Hot Shot</li>
                            <li><i className="fa-solid fa-check"></i> Transfer (Cruce Aduanas Fronterizas)</li>
                        </ul>

                        <h3>Servicio de transporte internacional terrestre</h3>
                        <ul>
                            <li><i className="fa-solid fa-check"></i> Caja Seca 53', Caja Seca</li>
                            <li><i className="fa-solid fa-check"></i> 48' Plataforma, low boys</li>
                            <li><i className="fa-solid fa-check"></i> Carga LTL</li>
                            <li><i className="fa-solid fa-check"></i> Sobredimensionada</li>
                            <li><i className="fa-solid fa-check"></i> Justo a tiempo, Hot</li>
                            <li><i className="fa-solid fa-check"></i> Transporte Intermodal</li>
                            <li><i className="fa-solid fa-check"></i> Caja Refrigerada 53</li>
                            <li><i className="fa-solid fa-check"></i> Transfer (Cruce Aduanas Fronterizas)</li>
                        </ul>
                        <img src="/assets/img/img-4-1.png" className='img-fluid' alt="img-4-1" />
                    </div>
                    <div className="tab-pane fade" id="pills-maritimo" role="tabpanel" aria-labelledby="pills-maritimo-tab" tabIndex="0">
                        <h3>{t('services.services.service2.title')}</h3>
                        <h5>¡POR MAR SOMOS TU SOLUCIÓN!</h5>
                        <p>El tiempo incluso por mar juego un papel decisivo a la hora de realizar los servicios de carga marítima, ya sea carga FCL o LCL, nuestro equipo trabaja para ti dando soluciones para darle un servicio expedito con las mejores navieras.</p>
                        <p>Ya sea desde recolecciones en su planta o movimientos puerto-puerto, ITC se adecua a sus necesidades, brindando confianza, calidad a sus necesidades maritima a cualquier parte del mundo.</p>
                        <p>Coordina todo el proceso, desde la elección de los modos adecuados de transporte y carga de equipos confiable, incluyendo el modo de transporte principal y la continuación de transporte.</p>
                        <h4>{t('services.services.service2.subTitle')}</h4>
                        <ul>
                            <li><i className="fa-solid fa-check"></i> FCL(FULL CONTAINER LOAD)</li>
                            <li><i className="fa-solid fa-check"></i> LCL (LESS CONTAINER LOAD)</li>
                            <li><i className="fa-solid fa-check"></i> CARGA SUELTA (BREAK BULK)</li>
                            <li><i className="fa-solid fa-check"></i> FLAT RACK</li>
                            <li><i className="fa-solid fa-check"></i> OPEN TOP</li>
                            <li><i className="fa-solid fa-check"></i> FLEXITANQUE</li>
                        </ul>
                        <img src="/assets/img/img-4-2.png" className='img-fluid' alt="img-4-2" />
                    </div>
                    <div className="tab-pane fade" id="pills-aereo" role="tabpanel" aria-labelledby="pills-aereo-tab" tabIndex="0">
                        <h3>{t('services.services.service3.title')}</h3>
                        <p>Cuando se trata de carga aérea, la rapidez y eficacia son de suma importancia, El tiempo y la velocidad son lo mas importante para llevar su carga a cualquier destino en el mundo, ITC es una empresa flexible que se moldea a tus necesidades de urgencia.</p>
                        <p>Como especialista global de carga aérea, ofrecemos conexiones de vuelos regulares de y hacia cualquier punto comercial importante en el mundo.</p>
                        <p>Desde piezas de componentes electrónicos sensibles y urgentes hasta los textiles de alta calidad, nos encontramos con la solución de transporte adecuada para cada envío. Naturalmente, también nos aseguramos de transportar mercancías peligrosas, cumpliendo los estándares internacionales aprobados.</p>
                        <p>ITC opera un sistema informático global que ofrece principales detalles sobre el estado del envío de sus productos</p>
                        <h4>{t('services.services.service3.subTitle')}</h4>
                        <ul>
                            <li><i className="fa-solid fa-check"></i> Importaciones y Exportaciones de carga aérea.</li>
                            <li><i className="fa-solid fa-check"></i> Vuelos pasajeros</li>
                            <li><i className="fa-solid fa-check"></i> Vuelos cargueros</li>
                            <li><i className="fa-solid fa-check"></i> Hand Carrier</li>
                            <li><i className="fa-solid fa-check"></i> Courier LATAM y el Caribe</li>
                            <li><i className="fa-solid fa-check"></i> Transporte puerta a puerto</li>
                            <li><i className="fa-solid fa-check"></i> Transporte de Mercancías Peligrosas.</li>
                            <li><i className="fa-solid fa-check"></i> Vuelos "Charter".</li>
                            <li><i className="fa-solid fa-check"></i> Coordinación de Despacho en aduana</li>

                        </ul>
                        <img src="/assets/img/img-4-3.png" className='img-fluid' alt="img-4-3" />
                    </div>
                    <div className="tab-pane fade" id="pills-almacenaje" role="tabpanel" aria-labelledby="pills-almacenaje-tab" tabIndex="0">
                        <h3>{t('services.services.service4.title')}</h3>
                        <p>Con el uso de sistemas de gestión de almacenes inteligentes, nos aseguramos de implementar procesos eficientes y de alto nivel para que el proceso operativo de nuestros clientes sea confiable y eficiente.</p>
                        <p>Como socio logístico ITC desarrolla conceptos apropiados de almacenaje para todas las industrias e invierte en soluciones avanzadas. Al mismo tiempo, ITC es capaz de proporcionar una variedad de servicios adicionales a petición del cliente.</p>
                        <h4>{t('services.services.service4.subTitle')}</h4>
                        <ul>
                            <li><i className="fa-solid fa-check"></i> Racks y almacenamiento de bloques, trasiego de estanterias, estanterias y almacenamiento de piezas pequenas (bin).</li>
                            <li><i className="fa-solid fa-check"></i> Soluciones y opciones para múltiples usuarios de almacenamiento a la medida.</li>
                            <li><i className="fa-solid fa-check"></i> Gestión del inventario con un sistema de gestión de almacén moderno (WMS).</li>
                            <li><i className="fa-solid fa-check"></i> Almacenamiento personalizado para industrias: "moda", "automotriz","electrónica" (Split Services & Manufacturing Part Number") y Servicios de Destrucción de Mercancia.</li>
                            <li><i className="fa-solid fa-check"></i> Almacenamiento	provisional,	cross-docking,	completa externalización de la logística.</li>
                            <li><i className="fa-solid fa-check"></i> Reconstrucción de Inventarios Anexo 24.</li>
                            <li><i className="fa-solid fa-check"></i> Recinto Fiscalizado Estratégico.</li>
                        </ul>
                        <img src="/assets/img/img-4-4.png" className='img-fluid' alt="img-4-4" />
                    </div>
                    {false && (<div className='soluciones'>
                        <h2>Nuestras soluciones</h2>
                        <div className='card-soluciones'>
                            <div className="item-soluciones">
                                <span className='icon-item'><i className="fa-regular fa-eye"></i></span>
                                <span>Wharehousing</span>
                                <p>Vix ut ignota deserunt partien ad, pros tale falli periculis ad, idque deseruisse constituam.</p>
                            </div>
                            <div className="item-soluciones">
                                <span className='icon-item'><i className="fa-solid fa-pen"></i></span>
                                <span>Land Freight</span>
                                <p>Vix ut ignota deserunt partien ad, pros tale falli periculis ad, idque deseruisse constituam.</p>
                            </div>
                            <div className="item-soluciones">
                                <span className='icon-item'><i className="fa-regular fa-hourglass"></i></span>
                                <span>Puntualidad</span>
                                <p>Vix ut ignota deserunt partien ad, pros tale falli periculis ad, idque deseruisse constituam.</p>
                            </div>
                            <div className="item-soluciones">
                                <span className='icon-item'><i className="fa-regular fa-hourglass"></i></span>
                                <span>Ocean Freight</span>
                                <p>Vix ut ignota deserunt partien ad, pros tale falli periculis ad, idque deseruisse constituam.</p>
                            </div>
                            <div className="item-soluciones">
                                <span className='icon-item'><i className="fa-regular fa-hourglass"></i></span>
                                <span>Rail Freight</span>
                                <p>Vix ut ignota deserunt partien ad, pros tale falli periculis ad, idque deseruisse constituam.</p>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    )
}

export default index