import authServiceInstance from "../../Services/authService";
import {
  LOGIN_SUCCESS,
  LOGOUT,
  CHECK_AUTHENTICATION,
  LOGIN_FAILURE,
  CHECK_AUTHENTICATION_ADMIN,
} from "../actions/types";

const initialState = {
  user: null,
  error: null,
  isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
      };
    case CHECK_AUTHENTICATION:
      return {
        ...state,
        user: authServiceInstance.isAuthenticated()
          ? authServiceInstance.getSessionUser()
          : null,
      };
    case CHECK_AUTHENTICATION_ADMIN:
      return {
        ...state,
        user: authServiceInstance.isAuthenticatedAdmin()
          ? authServiceInstance.getSessionAdmin()
          : null,
      };
    default:
      return state;
  }
};

export default authReducer;
