import { useState } from "react";
import Layout from "../../../Components/Dashboard/Layout/Layout";
import List from "./List";
import Create from "./Create";

const Report = ({ toggleStatus }) => {
  const [isCreate, setIsCreate] = useState(false);

  const changeStatus = () => {
    setIsCreate((prev) => !prev);
  };
  return (
    <div className="container-report" style={{ backgroundColor: "#F4F6F8" }}>
      <div className={toggleStatus ? "carriers " : "carriers active"}>
        <Layout>
          {!isCreate ? (
            <List {...{ changeStatus }} />
          ) : (
            <Create changeTap={changeStatus} />
          )}
        </Layout>
      </div>
    </div>
  );
};

export default Report;
