import React, { useEffect, useState } from 'react'

const BoxOrder = ({value, setValue,order}) => {

    const [formDataBox, setFormDataBox] = useState({
        container_type_id: '',
        container_type_name: '', // Inicializado con un valor vacío en lugar de undefined
        number_packages: '',
        box_height: '',
        box_length: '',
        box_width: '',
        box_weight: '',
        boxes: [],
      });

      
      useEffect(() => {
        
        if(order){

          setFormDataBox((prevData) => ({
            ...prevData,
            boxes: order.boxes,
          }))
        }
      }, [order])
      
    
      const handleChangeCaja = (e, index) => {

        const { name, value } = e.target;
        setFormDataBox((prevData) => {
          const newBoxes = [...prevData.boxes];
          newBoxes[index] = { ...newBoxes[index], 
            [name]: value, 
            container_type_id: '1', 
            m3: (parseFloat(newBoxes[index].box_height) * parseFloat(newBoxes[index].box_length) * parseFloat(newBoxes[index].box_width)).toFixed(2),

        };

          return { ...prevData, boxes: newBoxes };
        });
      };
    
      const handleAddCaja = () => {

        setFormDataBox((prevData) => ({
          ...prevData,
          boxes: [...prevData.boxes, {}],
        }));
      };

      const handleRemoveCaja = (index) => {

        if (formDataBox.boxes.length === 1) {
          // No eliminar si solo hay un elemento
          return;
        }
    
        setFormDataBox((prevData) => {
          const newBoxes = [...prevData.boxes];
          newBoxes.splice(index, 1);
          return { ...prevData, boxes: newBoxes };
        });
      };

      useEffect(() => {
        setValue((prevValue) => ({
          ...prevValue,
          boxes: formDataBox.boxes,
        }));
      }, [formDataBox]);
      
    
  return (
    <>
       <div>
      {formDataBox.boxes.map((caja, index) => (
        <div key={index} className="section row m-0 mt-4">
          <div className="mb-3 col-md-3">
            <label htmlFor={`container_type_name`} className="form-label">
              Tipo de contenedor
            </label>
            <input
              value={caja.container_type_name}
              onChange={(e) => handleChangeCaja(e, index)}
              className="form-control"
              id={`container_type_name`}
              name={`container_type_name`}
              aria-describedby={`container_type_name`}
              type='text'
            />

          </div>
          <div className="mb-3 col-md-3">
            <label htmlFor={`number_packages`} className="form-label">
              Número de contenedor
            </label>
            <input
              type="text"
              value={caja.number_packages}
              onChange={(e) => handleChangeCaja(e, index)}
              className="form-control"
              id={`number_packages`}
              name={`number_packages`}
              aria-describedby={`number_packages`}
              placeholder="Número de contenedor"
            />
          </div>
          <div className="mb-3 col-md-4">
            <label htmlFor={`dimensions`} className="form-label">
              Caja Dimensión
            </label>
            <div className="row">
              <div className="col-md-4 flex-row d-flex align-items-center">
                <input
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={caja.box_height}
                  onChange={(e) => handleChangeCaja(e, index)}
                  className="form-control"
                  id={`box_height`}
                  name={`box_height`}
                  aria-describedby={`box_height`}
                  placeholder="0"
                />
                <label htmlFor={`box_height`} className="form-label m-0 pl-3">
                  X
                </label>
              </div>
              <div className="col-md-4 flex-row d-flex align-items-center">
                <input
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={caja.box_length}
                  onChange={(e) => handleChangeCaja(e, index)}
                  className="form-control"
                  id={`box_length`}
                  name={`box_length`}
                  aria-describedby={`box_length`}
                  placeholder="0"
                />
                <label htmlFor={`box_length`} className="form-label m-0 pl-3">
                  X
                </label>
              </div>
              <div className="col-md-4 flex-row d-flex align-items-center">
                <input
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={caja.box_width}
                  onChange={(e) => handleChangeCaja(e, index)}
                  className="form-control"
                  id={`box_width`}
                  name={`box_width`}
                  aria-describedby={`box_width`}
                  placeholder="0"
                />
                <label htmlFor={`box_width`} className="form-label m-0 pl-3">
                  Cm
                </label>
              </div>
              
            </div>
          </div>
          <div className="mb-3 col-md-2">
            <label htmlFor={`box_weight`} className="form-label">
              Peso
            </label>
            <input
              type="number"
              value={caja.box_weight}
              onChange={(e) => handleChangeCaja(e, index)}
              className="form-control"
              id={`box_weight`}
              name={`box_weight`}
              aria-describedby={`box_weight`}
              placeholder="0 Kg"
            />
          </div>
          {formDataBox.boxes.length > 1 && (
            <div className="mb-3 col-md-2">
              <button
                type="button"
                onClick={() => handleRemoveCaja(index)}
                className="btn btn-danger"
              >
                Eliminar caja
              </button>
            </div>
          )}
        </div>
      ))}

      <div className="btn-add" onClick={handleAddCaja}>
        <span>Añadir nueva caja</span>
      </div>
    </div>
    </>
  )
}

export default BoxOrder