import { api } from './api';
import { toast } from "react-toastify";

export const getAddressData = async (idClient,sessionToken) => {
  try {
    const response = await api.get(`/address?id_user=${idClient}`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Error fetching client data');
  }
};
export const getAddressByName = async (name, sessionToken) => {
    try {
      
      const response = await api.get(`/address/${name}`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching client data for ${name}`);
    }
  };

export const updateAddress = async (id, data, sessionToken) => {
  try {
    const response = await api.put(`/address/${id}`, data, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating address with id ${id}`);
  }
};

export const createAddress = async (addressData, sessionToken) => {
  try {
    const response = await api.post("/address", addressData, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (!error.response.data.success) {
      toast.error(error.response.data.message);
    }
    throw new Error("Error creating new order");
  }
};