import React from "react";
import Layout from "../../../../Components/Dashboard/Layout/Layout";
import { useState } from "react";

import { useEffect } from "react";

import { useNavigate, useParams } from "react-router";
import UpdateModal from "../../Modales/UpdateModal";
import { filesToBase64Array } from "../../../../utils/utils";
import authServiceInstance from "../../../../Services/authService";
import Spinner from "../../../../Components/loaders/Spinner";

import ImageUploader from "../../../../Components/Dashboard/Inputs/Image/ImageUploader";
import {
  getAdminById,
  updateAdmin,
  updateAdminstatus,
} from "../../../../Services/admin";
import DeleteAdmin from "../../Modales/DeleteAdmin";
import "./administracion.css";

const EditAdmin = ({ toggleStatus }) => {
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [adminData, setAdminData] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    password: "",
    status: "",
    email: "",
    role_id: "",
    profile_picture_url: "",
  });
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const { idadmin } = useParams();
  const [data, setData] = useState();

  const token = authServiceInstance.getSessionToken();

  const [loader, setLoader] = useState(false);
  const [statusData, setStatusData] = useState({
    status: 0,
    commentary: "",
    flag: 0,
  });
  const [imageProfile, setImageProfile] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const returnList = () => {
    navigate("/admin-itc/administracion");
  };

  const handleDelete = async () => {
    try {
      await updateAdminstatus(idadmin, statusData, token);
      setStatusData({ status: 0, commentary: "" });
      setOpenModalDelete(false);
      returnList();
    } catch (error) {
      console.error("Error deleting order:", error.message);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminsData = await getAdminById(idadmin, token);

        setAdminData(adminsData);
        setImageProfile(adminsData.profile_picture_url);
        setFormData((prevFormData) => ({
          ...prevFormData,
          name: adminsData?.name,
          email: adminsData?.email,
          status: adminsData?.status,
          password: "",
          last_name: adminsData?.last_name,
          role_id: adminsData?.role_id,
          // profile_picture_url: adminsData?.profile_picture_url,
        }));
      } catch (error) {
        console.error("Error fetching order by ID:", error.message);
        // Puedes manejar el error de alguna manera, por ejemplo, mostrar un mensaje al usuario
      }
    };

    fetchData();
  }, []);

  const handleModalDelete = (e) => {
    e.preventDefault();
    setOpenModalDelete(true);
  };

  useEffect(() => {
    if (adminData) {
      const fetchData = async () => {
        if (adminData)
          try {
            setData({
              name: formData.name || adminData.name,
              last_name: formData.last_name || adminData.last_name,
              email: formData.email || adminData.email,
              status: formData.status || adminData.status,
              role_id: formData.role_id || adminData.role_id,
              profile_picture_url: formData.profile_picture_url || imageProfile,
              ...(formData.password && {
                password: formData.password || adminData.password,
              }),
            });
          } catch (error) {
            console.error(
              "Ocurrió un error al convertir archivos a base64:",
              error
            );
          }
      };
      fetchData();
    }
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{}[\]:;<>,.?/\\])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    if (formData.name === "" || formData.name.length < 2) {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }

    if (formData.last_name === "" || formData.last_name.length < 2) {
      setLastNameError(true);
      return;
    } else {
      setLastNameError(false);
    }
    if (!emailRegex.test(formData.email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    if (!passwordRegex.test(formData.password) && formData.password !== "") {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    actualizarAdmin();
  };

  const actualizarAdmin = async () => {
    const nuevosDatosDeAdmin = data;
    const newData = {
      ...data,
      profile_picture_url: imageProfile,
    };

    try {
      const ordenActualizada = await updateAdmin(idadmin, newData, token);
      setOpenModalUpdate(true);
    } catch (error) {
      console.error("Error al crear una nueva orden:", error.message);
    }
  };

  const handleChangeImageProfile = (img) => {
    if (img) {
      setImageProfile(img);
    } else {
      setImageProfile(null);
    }
  };

  return (
    <>
      {loader && <Spinner />}
      <>
        <div
          className="container-report"
          style={{ backgroundColor: "#F4F6F8" }}
        >
          <div className={toggleStatus ? "orders " : "orders active"}>
            <Layout>
              <div className="container-step">
                <form className="p-0 m-0 w-100">
                  <section className="section-step">
                    <h3>Datos personales</h3>
                  </section>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-5 d-flex row m-0 p-0">
                      <div className="col-md-4 col-lg-3">
                        <ImageUploader
                          setValor={handleChangeImageProfile}
                          dataImg={imageProfile}
                        />
                      </div>
                      <div className="col-md-8 col-lg-9 col-12">
                        <label htmlFor="name" className="form-label">
                          Nombre
                        </label>
                        <input
                          required
                          type="text"
                          value={formData.name}
                          onChange={handleChange}
                          className={`form-control ${
                            nameError ? "invalid-input" : ""
                          }`}
                          id="name"
                          name="name"
                          aria-describedby="name"
                          placeholder="Ingresa nombre"
                        />
                      </div>
                    </div>
                    <div className="mb-3 col-md-4 ">
                      <label htmlFor="last_name" className="form-label">
                        Apellido
                      </label>
                      <input
                        required
                        type="text"
                        value={formData.last_name}
                        onChange={handleChange}
                        className={`form-control ${
                          lastNameError ? "invalid-input" : ""
                        }`}
                        id="last_name"
                        name="last_name"
                        aria-describedby="last_name"
                        placeholder="Ingresa apellido"
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <label htmlFor="role_id" className="form-label">
                        Rol
                      </label>
                      <select
                        value={formData.role_id}
                        onChange={handleChange}
                        className="form-select"
                        id="role_id"
                        name="role_id"
                        aria-describedby="role_id"
                      >
                        <option value="1">Administrador</option>
                        <option value="2">Costumer Services</option>
                      </select>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label htmlFor="status" className="form-label">
                        Estado Usuario
                      </label>
                      <select
                        value={formData.status}
                        onChange={handleChange}
                        className="form-select"
                        id="status"
                        name="status"
                        aria-describedby="status"
                      >
                        <option value="1">Activo</option>
                        <option value="2">Suspendido</option>
                      </select>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="email" className="form-label">
                        Correo electrónico
                      </label>
                      <input
                        required
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`form-control ${
                          emailError ? "invalid-input" : ""
                        }`}
                        id="email"
                        name="email"
                        aria-describedby="email"
                        placeholder="Ingresa correo"
                      />
                      {emailError && <small>Formato de correo inválido </small>}
                    </div>

                    <div className=" mb-3 col-md-3 form-group form-group-password password-admin">
                      <label htmlFor="password" className="form-label">
                        Asignar contraseña
                      </label>
                      <input
                        required
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${
                          passwordError ? "invalid-input" : ""
                        }`}
                        id="password"
                        onChange={handleChange}
                        name="password"
                        value={formData.password}
                        placeholder="Contraseña"
                        maxLength={100}
                      />
                      {showPassword ? (
                        <i
                          className="fa-regular fa-eye-slash password-icon"
                          onClick={togglePasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye password-icon"
                          onClick={togglePasswordVisibility}
                        ></i>
                      )}
                      {passwordError && (
                        <small>
                          Formato inválido, mínimo 8 caracteres, una mayúscula,
                          un numero y un carácter especial
                        </small>
                      )}
                    </div>

                    <div className="mb-3 col-md-2">
                      <div className="d-flex flex-column">
                        <label htmlFor="" className="form-label">
                          Eliminar
                        </label>
                        <button
                          className="btn-eliminar"
                          onClick={handleModalDelete}
                        >
                          {" "}
                          ELIMINAR
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="section-button pb-2 gap-2">
                    <button
                      type="button"
                      className=" btn-cancelar ms-auto"
                      onClick={returnList}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="btn-next mr-1"
                      onClick={handleSubmit}
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              </div>
            </Layout>
          </div>
        </div>
        <UpdateModal
          openModalUpdate={openModalUpdate}
          setOpenModalUpdate={setOpenModalUpdate}
          type={"admin"}
        />
        <DeleteAdmin
          openModalDelete={openModalDelete}
          setOpenModalDelete={setOpenModalDelete}
          action={handleDelete}
          setStatusData={setStatusData}
          statusData={statusData}
        />
      </>
    </>
  );
};

export default EditAdmin;
