import React, { useCallback, useState } from "react";
import authServiceInstance from "../../../Services/authService";
import CreateCarrierModal from "../Modales/CreateCarrierModal";
import { createCarrier } from "../../../Services/carrierService";
import { LoadingData } from "../../../Components/loaders/LoadingData";

const Create = ({ changeTap }) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const admin = authServiceInstance.getSessionAdmin();
  const token = authServiceInstance.getSessionToken();

  const returnList = () => {
    changeTap();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || name?.length < 2) {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }
    const data = {
      name: name,
      iduser: admin?.id,
    };
    addNewCarrier(data);
  };

  const addNewCarrier = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        await createCarrier(data, token);
        setOpenModalUpdate(true);
        setIsLoading(false);
      } catch (error) {
        console.error("Error al crear el carrier:", error.message);
        setIsLoading(false);
      }
    },
    [token]
  );

  return (
    <>
      <div className="container-step">
        <form className="p-0 m-0 w-full max-w-lg mx-auto">
          <section className="section-step">
            <h3>Carrier</h3>
          </section>
          <div className="section row m-0">
            <div className="mb-3 d-flex row m-0 p-0">
              <div className=" col-12">
                <label htmlFor="name" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={`form-control ${nameError ? "invalid-input" : ""}`}
                  id="name"
                  name="name"
                  aria-describedby="name"
                  placeholder="Ingresa nombre"
                />
                {nameError && (
                  <span className="text-xs text-textError">
                    El nombre es requerido
                  </span>
                )}
              </div>
            </div>
          </div>
          {isLoading && (
            <div className="mx-auto my-4">
              <LoadingData />
            </div>
          )}
          {!isLoading && (
            <div className="section-button pb-2 gap-2">
              <button
                type="button"
                className=" btn-cancelar ms-auto"
                onClick={returnList}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn-next mr-1"
                onClick={handleSubmit}
              >
                Guardar
              </button>
            </div>
          )}
        </form>
      </div>
      <CreateCarrierModal
        openModalUpdate={openModalUpdate}
        setOpenModalUpdate={setOpenModalUpdate}
        type={"carrier-create"}
        returnList={returnList}
      />
    </>
  );
};

export default Create;
