import React, { useState } from 'react';
import { FiPlus, FiImage } from "react-icons/fi";
import './modals.css';

const ModalImgProfile = ({ openModalImgProfile, setOpenModalImgProfile, action }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleCloseModal = () => {
    setOpenModalImgProfile(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handledUploadImg = () => {
    action({
        profile_picture_url:selectedImage
    })
    setOpenModalImgProfile(false);
    setSelectedImage(null)
  };

  return (
    <div>
      {openModalImgProfile && (
        <div>
          <div className="modal-backdrop show" onClick={handleCloseModal}></div>
          <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content contenedor-modal">
                <div className="modal-cabecera type-save pt-1">
                  <div className="modal-icon icon-save">
                    <span className='icon-container icon-check'><FiImage /></span>
                  </div>
                  <div className="modal-question">
                    <span>Subir nueva imagen</span>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="image-selector">
                   
                      <label htmlFor="imageInput" className="left-square upload-icon">
                        <FiPlus />
                      </label>
                      <input
                        type="file"
                        id="imageInput"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                      />
                   
                    <div className="right-square">
                      {/* Mostrar la imagen seleccionada o un cuadrado vacío */}
                      {selectedImage ? (
                        <img src={selectedImage} alt="Selected" />
                      ) : (
                        <div className="empty-square"></div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer footer-save pb-0">
                  <a className='btn-acept' onClick={handledUploadImg}>Aceptar</a>
                  <a className='btn-cancel' onClick={() => setOpenModalImgProfile(false)}>Cancelar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalImgProfile;
