import React from "react";
import Layout from "../../../Components/Dashboard/Layout/Layout";

import EditUsuario from "./EditUsuario";
import authServiceInstance from "../../../Services/authService";

const Setting = ({ toggleStatus }) => {
  const token = authServiceInstance.getSessionToken();
  const admin = authServiceInstance.getSessionAdmin();

  return (
    <div className="container-report" style={{ backgroundColor: "#F4F6F8" }}>
      <div className={toggleStatus ? "orders " : "orders active"}>
        <Layout>
          <EditUsuario idUser={admin.id} token={token} />
        </Layout>
      </div>
    </div>
  );
};

export default Setting;
