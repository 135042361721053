import React, { useState, useEffect } from "react";
import "./ImageUploader.css";

const ImageUploader = ({ setValor, dataImg }) => {
  const [image, setImage] = useState();

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const newImageResult = reader.result;
        setImage(newImageResult);

        setValor(newImageResult);
        // Guardar la nueva imagen en el estado o enviarla a la API según sea necesario
        // setValor((prevValor) => ({
        //   ...prevValor,
        //   profile_picture_url: reader.result,
        // }));
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    if (dataImg) {
      setImage(dataImg);
    }
  }, [dataImg]);
  const handleAddImageClick = () => {
    document.getElementById("imageInput").click();
  };
  useEffect(() => {
    setImage(dataImg);
  }, [dataImg]);
  return (
    <div className="image-uploader">
      <div className="circle" onClick={handleAddImageClick}>
        {image ? (
          <img src={image} alt="Uploaded" className="uploaded-image" />
        ) : (
          <div className="placeholder"></div>
        )}
      </div>
      <span className="icon-plus-img" onClick={handleAddImageClick}>
        <i className="fa-solid fa-plus"></i>
      </span>
      <input
        type="file"
        id="imageInput"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default ImageUploader;
