import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import WebRouter from "./WebRouter";
import DashboardRouter from "./DashboardRouter";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import AdminLogin from "../Pages/Dashboard/Login/Login";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<WebRouter />} />
        <Route
          path="/admin-itc/*"
          element={
            <PrivateRouteAdmin>
              <DashboardRouter />
            </PrivateRouteAdmin>
          }
        />
        <Route exact path="/login-admin" element={<AdminLogin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
