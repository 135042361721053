import React from 'react'
import './politicas.css'
import { t } from 'i18next'
const TerminosYCondiciones = () => {
  return (
    <div className="politicsPage">
    <div className="slider">
      <h1>{t('condition.title')}</h1>
      <span>{t('condition.breadcrumb.prev')} {'>'} {t('condition.breadcrumb.section')} {'>'} {t('condition.breadcrumb.name')}</span>
    </div>
    <div className="politicas">
        <div className="container">
            
            <div className="politicas-contenido" dangerouslySetInnerHTML={{ __html: t('condition.content') }} />
        </div>
    </div>
  </div>
  )
}

export default TerminosYCondiciones