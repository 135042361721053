import React from 'react'
import { useState } from 'react';
import Switch from "react-switch";
const SwitchInput = ({handleType,state}) => {
    const [checked, setChecked] = useState(false);

    const handleChange = (isChecked) => {
      setChecked(isChecked);
      handleType(isChecked)
    };
  return (
    <div className="switch-input">
  
    <label htmlFor="material-switch" style={{margin:'0px', display:'flex'}}>
      
      <Switch
        checked={state}
        onChange={handleChange}
        onColor="#1A1449"
        onHandleColor="#fff"
        handleDiameter={18}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={18}
        width={35}
        className="react-switch"
        id="material-switch"
      />
    </label>
  </div>
  )
}

export default SwitchInput