import React, { useEffect, useState } from "react";
import "./fileinput.css";
import { useRef } from "react";
import DeleteFile from "../../../../Pages/Dashboard/Modales/DeleteFile";

const FileInputEdit = ({ value, setValue, name, data }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openModalDeleteFile, setOpenModalDeleteFile] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleModalDeleteFile = (value) => {
    setOpenModalDeleteFile(true);
    setCurrentFile(value);
  };

  const fileInputRef = useRef(null);

  useEffect(() => {
    // Si hay archivos en el valor, establecerlos como archivos seleccionados
    if (value && value[name] && value[name].length > 0) {
      setSelectedFiles(value[name] ?? []);
    }
  }, [value, name]);

  useEffect(() => {
    if (name === "other_documents") {
      if (data && data?.length !== 0 && Object?.keys(data)?.length !== 0) {
        setSelectedFiles(data ?? []);
      }
    } else if (name === "invoice_photo") {
      if (data && data.length !== 0 && Object.keys(data)?.length !== 0) {
        setSelectedFiles(data ?? []);
      }
    }
  }, [data]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    const updatedFiles = [...selectedFiles, ...files];

    setSelectedFiles(updatedFiles);
    setValue({
      ...value,
      [name]: updatedFiles,
    });
  };

  const handleRemoveFile = (fileName) => {
    setIsLoading(true);

    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);

    setSelectedFiles(updatedFiles);
    setValue((prevValue) => ({
      ...prevValue,
      [name]: updatedFiles,
    }));

    setIsLoading(false);
    setOpenModalDeleteFile(false);
    setCurrentFile(null);
  };

  const handleUploadClick = () => {
    // Simula el clic en el input file
    fileInputRef.current.click();
  };

  const handleViewFile = (file) => {
    const fileURL = data ? file.url : URL.createObjectURL(file);

    window.open(fileURL, "_blank");
  };

  const handleDownloadFile = (file) => {
    // Implementa la lógica para descargar el archivo
    if (data) {
      const a = document.createElement("a");
      a.href = file.url;
      a.download = file.name;
      a.target = "_blank"; // Agrega este atributo
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  return (
    <>
      <div className="file-input-container">
        <div className="file-input">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            multiple
          />
          {
            <label>
              <div className="file-input-label">
                <p>
                  Explore sus archivos y cargue sus{" "}
                  {name === "other_documents" ? "documentos" : "facturas"}
                </p>
              </div>

              <button
                type="button"
                className="upload-button"
                onClick={handleUploadClick}
              >
                SUBIR
              </button>
            </label>
          }
        </div>

        {selectedFiles?.length > 0 && (
          <div className="file-list">
            <ul>
              {selectedFiles?.map((file, index) => {
                return (
                  <li key={index}>
                    {!file.type
                      ? file?.name?.split(" ").join("_").split(".")[0] +
                        "." +
                        file?.url?.split(".").slice(-1)
                      : file?.name?.split(" ").join("_")}

                    <div>
                      <a onClick={() => handleModalDeleteFile(file)}>
                        Eliminar
                      </a>
                      <a onClick={() => handleViewFile(file)}>Ver</a>
                      <a onClick={() => handleDownloadFile(file)}>Descargar</a>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <DeleteFile
        openModalDelete={openModalDeleteFile}
        setOpenModalDelete={setOpenModalDeleteFile}
        action={handleRemoveFile}
        currentFile={currentFile}
        isLoading={isLoading}
      />
    </>
  );
};
export default FileInputEdit;
