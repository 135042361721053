import React from "react";
import Perfil from "./Perfil";

const ProfileUser = ({ handleLogout }) => {
 
  return (
    <div>
      <Perfil handleLogout={handleLogout}/>
    </div>
  );
};

export default ProfileUser;
