import React, { useEffect } from "react";
import { useState } from "react";
import "./order.css";
import SwitchInput from "../../../Components/Dashboard/Inputs/Switch/SwitchInput";
import ClientSelect from "../../../Components/Dashboard/Selects/ClientSelect";
import DirectionSelect from "../../../Components/Dashboard/Selects/DirectionSelect";

import FileInput from "../../../Components/Dashboard/Inputs/FileInput/FileInput";
import {
  getCitiesByCountry,
  getCountries,
} from "../../../Services/countryService";
import { createOrder, getOrderStatus } from "../../../Services/orderService";
import { filesToBase64Array } from "../../../utils/utils";
import CancelModal from "../Modales/CancelModal";
import SaveModal from "../Modales/SaveModal";
import authServiceInstance from "../../../Services/authService";
import BoxOrder from "../../../Components/Dashboard/Orders/BoxOrder";
import Spinner from "../../../Components/loaders/Spinner";
import { getCarrier } from "../../../Services/carrierService";
import { toast } from "react-toastify";

const Create = ({ changeTap, fetchOrders }) => {
  const [isNewClient, setIsNewClient] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [openModalSave, setOpenModalSave] = useState(false);
  const [isNewDirection, setIsNewDirection] = useState(false);
  const [isNewDirectionDestination, setIsNewDirectionDestination] =
    useState(false);
  const [saveNewDirection, setSaveNewDirection] = useState(false);
  const [saveNewDirectionDestination, setSaveNewDirectionDestination] =
    useState(false);
  const [clientID, setClientID] = useState();
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const token = authServiceInstance.getSessionToken();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [countriesDestination, setCountriesDestination] = useState([]);
  const [citiesDestination, setCitiesDestination] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    position: "",
    email: "",
    officePhone: "",
    cellPhone: "",
    id_user: "",
    //company
    companyName: "",
    rfc: "",
    address: "",
    businessActivity: "",
    taxRegime: "",
    observations: "",
    //origen
    id_address_origin: "",
    country_origin: "",
    state_origin: "",
    city_origin: "",
    postalCode_origin: "",
    address_origin: "",
    //destino
    id_address_destination: "",
    country_destination: "",
    state_destination: "",
    city_destination: "",
    postalCode_destination: "",
    address_destination: "",
    //Services
    date: "",
    service_status: "",
    item: "",
    //Shipment
    date_shipment: "",
    Courier: "",
    bl_guia: "",
    cost: "",
    sale_price: "",
    etd: "",
    eta: "",
    proforma: "",
    reference: "",
    delivery_date: "",
    invoice: "",
    //Details
    boxes: [],
    invoice_photo: [],
    other_documents: [],
  });
  const [data, setData] = useState({});

  const handleTypeClient = (isChecked) => {
    setIsNewClient(isChecked);
  };
  const handleTypeDirection = (isChecked) => {
    setIsNewDirection(isChecked);
    setSaveNewDirection(false);
  };
  const handleTypeDirectionDestination = (isChecked) => {
    setIsNewDirectionDestination(isChecked);
    setSaveNewDirectionDestination(false);
  };
  useEffect(() => {
    if (!isNewClient) {
      setFormData({
        ...formData,
        name: "",
        lastName: "",
        position: "",
        email: "",
        officePhone: "",
        cellPhone: "",
        //compañía
        companyName: "",
        rfc: "",
        address: "",
        businessActivity: "",
        taxRegime: "",
        observations: "",
      });

      const formFields = document.querySelectorAll(".form-control");
      formFields.forEach((field) => {
        field.classList.remove("is-invalid");
      });
    }
  }, [isNewClient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (data.id_status === "Selecciona estado")
      toast.error("Debes seleccionar un Estado");
    crearNuevaOrden();
  };

  const handleCheckboxChange = () => {
    setSaveNewDirection(!saveNewDirection);
  };
  const handleCheckboxChangeDestination = () => {
    setSaveNewDirectionDestination(!saveNewDirectionDestination);
  };

  const registrarCliente = () => {
    if (isStepValid()) {
      const formFields = document.querySelectorAll(".form-control");
      formFields.forEach((field) => {
        field.classList.remove("is-invalid");
      });
    } else {
      // Aplica clases de estilo "is-invalid" a los campos que no pasan la validación
      const formFields = document.querySelectorAll(".form-control");
      formFields.forEach((field) => {
        if (!field.value.trim()) {
          field.classList.add("is-invalid");
        } else {
          field.classList.remove("is-invalid");
        }
      });

      // Muestra un mensaje de error o maneja la entrada no válida de alguna manera
    }
  };

  const nextStep = () => {
    if (isStepValid()) {
      const formFields = document.querySelectorAll(".form-control");
      formFields.forEach((field) => {
        field.classList.remove("is-invalid");
      });
      setStep(step + 1);
    } else {
      // Aplica clases de estilo "is-invalid" a los campos que no pasan la validación
      const formFields = document.querySelectorAll(".form-control");
      formFields.forEach((field) => {
        if (!field.value.trim()) {
          field.classList.add("is-invalid");
        } else {
          field.classList.remove("is-invalid");
        }
      });
    }
  };

  const prevStep = () => {
    const formFields = document.querySelectorAll(".form-control");
    formFields.forEach((field) => {
      field.classList.remove("is-invalid");
    });
    setStep(step - 1);
  };

  const isStepValid = () => {
    switch (step) {
      case 1:
        // Valida los campos para el Paso 1
        return (
          formData.name !== "" &&
          formData.lastName !== "" &&
          formData.position !== "" &&
          formData.email !== "" &&
          formData.officePhone !== "" &&
          formData.cellPhone !== "" &&
          formData.companyName !== "" &&
          formData.rfc !== "" &&
          formData.address !== "" &&
          formData.businessActivity !== "" &&
          formData.taxRegime !== ""
        );
      case 2:
        // Valida los campos para el Paso 2
        return (
          formData.country_origin !== "" &&
          formData.state_origin !== "" &&
          formData.city_origin !== "" &&
          formData.postalCode_origin !== "" &&
          formData.address_origin !== "" &&
          formData.country_destination !== "" &&
          formData.state_destination !== "" &&
          formData.city_destination !== "" &&
          formData.postalCode_destination !== "" &&
          formData.address_destination !== ""
        );
      case 3:
        // Valida los campos para el Paso 3
        return (
          formData.date !== "" &&
          formData.service_status !== "" &&
          formData.service_status !== "Selecciona estado" &&
          formData.item !== ""
        );
      default:
        return true;
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesData = await getCountries(token);
        if (countriesData.data) {
          const ordered = countriesData?.data?.sort((a, b) => {
            const nombreA = a.country_name.toUpperCase();
            const nombreB = b.country_name.toUpperCase();

            if (nombreA < nombreB) {
              return -1;
            }
            if (nombreA > nombreB) {
              return 1;
            }

            return 0;
          });

          setCountries(ordered);
          setCountriesDestination(ordered);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    const fetchOrderStatus = async () => {
      try {
        const orderStatusData = await getOrderStatus(token);
        setOrderStatus(orderStatusData.data);
      } catch (error) {
        console.error("Error fetching order status:", error);
      }
    };
    const fetchCarriers = async () => {
      try {
        const carrierData = await getCarrier(token);
        setCarriers(carrierData.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCarriers();
    fetchCountries();
    fetchOrderStatus();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const documentsBase64 =
          formData.other_documents.length > 0
            ? await new Promise((resolve) =>
                filesToBase64Array(formData.other_documents, resolve)
              )
            : [];

        const invoicesBase64 =
          formData.invoice_photo.length > 0
            ? await new Promise((resolve) =>
                filesToBase64Array(formData.invoice_photo, resolve)
              )
            : [];
        setData({
          id_status: formData.service_status,
          client_name: formData.name + " " + formData.lastName,
          item: formData.item, //agregar campo
          arrival_date: formData.date_shipment,
          collection_date: formData.date, //revisar campo
          carrier: formData.Courier,
          bl_guia: formData.bl_guia,
          cost: formData?.cost ? parseFloat(formData?.cost.replace(/,/g, '') || 0) : "",
          sale_price: formData?.sale_price ? parseFloat(formData?.sale_price.replace(/,/g, '') || 0) : "",
          boxes: formData.boxes,
          /*container_number: 1,//agregar campo
            container_type: 1,//agregar campo
            m3: formData.height * formData.length * formData.width,
            total_weight: formData.weight,*/
          etd: formData.etd,
          eta: formData.eta,
          proforma: formData.proforma,
          delivery_date: formData.delivery_date,
          itc_invoice: "Número de factura ITC",
          client_reference: formData.reference,
          number_packages: 1,
          invoice: formData.invoice,
          files: "Nombre de archivos",
          notes: formData.observations,
          order_notes: "Notas de la orden",
          order_actions: "Acciones de la orden",
          id_user: formData.id_user,
          id_address_origin: formData.id_address_origin,
          id_address_destination: formData.id_address_destination,
          documents: documentsBase64,
          invoices: invoicesBase64,
          address_origin: {
            city: formData.city_origin,
            postal_code: formData.postalCode_origin,
            address_name: formData.address_origin,
            id_state: formData.state_origin,
            flag_active: saveNewDirection,
          },
          address_destination: {
            city: formData.city_destination,
            postal_code: formData.postalCode_destination,
            address_name: formData.address_destination,
            id_state: formData.state_destination,
            flag_active: saveNewDirectionDestination,
          },
          client: {
            user_name: formData.name,
            name: formData.name,
            last_name: formData.lastName,
            position: formData.position,
            email: formData.email,
            number_phone_office: formData.officePhone,
            number_phone: formData.cellPhone,
          },
          company: {
            company_name: formData.companyName,
            rfc: formData.rfc,
            fiscal_address: formData.address,
            economic_activity: formData.businessActivity,
            tax_regime: formData.taxRegime,
            observations: formData.observations,
          },
        });
      } catch (error) {
        console.error(
          "Ocurrió un error al convertir archivos a base64:",
          error
        );
      }
    };
    fetchData();
  }, [formData]);

  useEffect(() => {
    const fetchCities = async () => {
      if (formData.country_origin) {
        try {
          const citiesData = await getCitiesByCountry(
            formData.country_origin,
            token
          );
          if (citiesData.data) {
            const ordered = citiesData?.data?.sort((a, b) => {
              const nombreA = a.state_name.toUpperCase();
              const nombreB = b.state_name.toUpperCase();

              if (nombreA < nombreB) {
                return -1;
              }
              if (nombreA > nombreB) {
                return 1;
              }

              return 0;
            });
            console.log(ordered);
            setCities(ordered);
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      }
    };

    fetchCities();
  }, [formData.country_origin]);

  useEffect(() => {
    const fetchCities = async () => {
      if (formData.country_destination) {
        try {
          const citiesData = await getCitiesByCountry(
            formData.country_destination,
            token
          );

          if (citiesData.data) {
            const ordered = citiesData?.data?.sort((a, b) => {
              const nombreA = a.state_name.toUpperCase();
              const nombreB = b.state_name.toUpperCase();

              if (nombreA < nombreB) {
                return -1;
              }
              if (nombreA > nombreB) {
                return 1;
              }

              return 0;
            });

            setCitiesDestination(ordered);
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      }
    };

    fetchCities();
  }, [formData.country_destination]);

  const handleChangeCountry = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "country_origin") {
      // Si cambia el país, carga las ciudades correspondientes
      const fetchCities = async () => {
        try {
          const citiesData = await getCitiesByCountry(value, token);
          setCities(citiesData.data);
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };

      fetchCities();
    }
  };

  const numberFormat = (number) => {
    const cleanedNumber = number.toString().replace(/[^0-9.]/g, '');

    // Dividir el número en partes entera y decimal
    let partsNumber = cleanedNumber.split('.');

      
    partsNumber[0] = partsNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Añade comas cada tres dígitos

    return partsNumber.join('.'); // Une los números y el decimal si lo hay
  }

  const handleChangeCity = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "country_destination") {
      // Si cambia el país, carga las ciudades correspondientes
      const fetchCities = async () => {
        try {
          const citiesData = await getCitiesByCountry(value, token);
          setCitiesDestination(citiesData.data);
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };

      fetchCities();
    }
  };

  const crearNuevaOrden = async () => {
    if (isStepValid()) {
      const formFields = document.querySelectorAll(".form-control");
      formFields.forEach((field) => {
        field.classList.remove("is-invalid");
      });
      const nuevosDatosDeOrden = data;
      console.log(nuevosDatosDeOrden)
      try {
        setLoader(true);
        const nuevaOrdenCreada = await createOrder(nuevosDatosDeOrden, token);
        setOpenModalSave(true);
        setLoader(false);
      } catch (error) {
        console.log(error);
        setLoader(false);
        console.error("Error al crear una nueva orden:", error.message);
      }
    } else {
      // Aplica clases de estilo "is-invalid" a los campos que no pasan la validación
      const formFields = document.querySelectorAll(".form-control");
      formFields.forEach((field) => {
        if (!field.value.trim()) {
          field.classList.add("is-invalid");
        } else {
          field.classList.remove("is-invalid");
        }
      });
    }
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <div className="container-step">
            <small>Paso 1 de 3</small>
            <section className="section-step">
              <h3>Cliente</h3>
              <small> Cliente existente</small>
              <div className="switch">
                No
                <SwitchInput
                  handleType={handleTypeClient}
                  state={isNewClient}
                />
                Si
              </div>
              {!isNewClient ? (
                ""
              ) : (
                <ClientSelect
                  valueData={formData}
                  setValueData={setFormData}
                  client={clientID}
                  setClient={setClientID}
                />
              )}
            </section>
            <div className="section row m-0">
              <div className="mb-3 col-md-4">
                <label htmlFor="name" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  className="form-control"
                  id="name"
                  name="name"
                  aria-describedby="name"
                  placeholder="Ingresa nombre"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="lastName" className="form-label">
                  Apellido
                </label>
                <input
                  type="text"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  aria-describedby="lastName"
                  placeholder="Ingresa apellido"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="position" className="form-label">
                  Puesto
                </label>
                <input
                  type="text"
                  value={formData.position}
                  onChange={handleChange}
                  className="form-control"
                  id="position"
                  name="position"
                  aria-describedby="position"
                  placeholder="Puesto en la empresa"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="email" className="form-label">
                  Correo electrónico
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control"
                  id="email"
                  name="email"
                  aria-describedby="email"
                  placeholder="Ingresa correo"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="officePhone" className="form-label">
                  Tel. Oficina
                </label>
                <input
                  type="tel"
                  value={formData.officePhone}
                  onChange={handleChange}
                  className="form-control"
                  id="officePhone"
                  name="officePhone"
                  aria-describedby="officePhone"
                  placeholder="Teléfono de oficina"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="cellPhone" className="form-label">
                  Tel. Celular
                </label>
                <input
                  type="tel"
                  value={formData.cellPhone}
                  onChange={handleChange}
                  className="form-control"
                  id="cellPhone"
                  name="cellPhone"
                  aria-describedby="cellPhone"
                  placeholder="Teléfono celular"
                />
              </div>
            </div>
            <h3 className="mt-3 mb-4">Compañía</h3>
            <div className="section row m-0">
              <div className="mb-3 col-md-4">
                <label htmlFor="companyName" className="form-label">
                  Compañía
                </label>
                <input
                  type="text"
                  value={formData.companyName}
                  onChange={handleChange}
                  className="form-control"
                  id="companyName"
                  name="companyName"
                  aria-describedby="companyName"
                  placeholder="Ingresa nombre de la compañía"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="rfc" className="form-label">
                  RFC
                </label>
                <input
                  type="text"
                  value={formData.rfc}
                  onChange={handleChange}
                  className="form-control"
                  id="rfc"
                  name="rfc"
                  aria-describedby="rfc"
                  placeholder="Ingresa RFC"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="address" className="form-label">
                  Dirección fiscal
                </label>
                <input
                  type="text"
                  value={formData.address}
                  onChange={handleChange}
                  className="form-control"
                  id="address"
                  name="address"
                  aria-describedby="address"
                  placeholder="Dirección"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="businessActivity" className="form-label">
                  Actividad económica
                </label>
                <input
                  type="text"
                  value={formData.businessActivity}
                  onChange={handleChange}
                  className="form-control"
                  id="businessActivity"
                  name="businessActivity"
                  aria-describedby="businessActivity"
                  placeholder="Ingresa actividad económica"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="taxRegime" className="form-label">
                  Régimen fiscal
                </label>
                <input
                  type="text"
                  value={formData.taxRegime}
                  onChange={handleChange}
                  className="form-control"
                  id="taxRegime"
                  name="taxRegime"
                  aria-describedby="taxRegime"
                  placeholder="Ingresa régimen fiscal"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="observations" className="form-label">
                  Observaciones (Opcional)
                </label>
                <input
                  type="text"
                  value={formData.observations}
                  onChange={handleChange}
                  className="form-control"
                  id="observations"
                  name="observations"
                  aria-describedby="observations"
                  placeholder="Ingresa observaciones"
                />
              </div>
            </div>
            <div className="section-button">
              <a onClick={() => setOpenModalCancel(true)}>CANCELAR</a>
              <button type="button" onClick={nextStep} className="btn-next">
                Siguiente
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="container-step">
            <small>Paso 2 de 3</small>
            <section className="section-step">
              <h3>Dirección origen</h3>
              {isNewClient && (
                <>
                  <small> Dirección existente</small>
                  <div className="switch">
                    No
                    <SwitchInput
                      handleType={handleTypeDirection}
                      state={isNewDirection}
                    />
                    Si
                  </div>
                </>
              )}

              {!isNewDirection ? (
                <>
                  <label className="input-group-checkbox">
                    <input
                      type="checkbox"
                      id="mycheck"
                      checked={saveNewDirection}
                      className="border border-secondary"
                      onChange={handleCheckboxChange}
                    />
                    Guarde esta dirección para un futuro uso
                  </label>
                </>
              ) : (
                <DirectionSelect
                  value={formData}
                  setValue={setFormData}
                  type="origin"
                  client={clientID}
                />
              )}
            </section>
            <div className="section row m-0">
              <div className="mb-3 col-md-3">
                <label htmlFor="country_origin" className="form-label">
                  País
                </label>
                <select
                  value={formData.country_origin}
                  onChange={handleChangeCountry}
                  className="form-select"
                  id="country_origin"
                  name="country_origin"
                  aria-describedby="country_origin"
                  placeholder="Selecciona país"
                >
                  <option selected>Selecciona un país</option>
                  {countries.length !== 0 &&
                    countries.map((country) => (
                      <option
                        key={country.id_country}
                        value={country.id_country}
                      >
                        {country.country_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="state_origin" className="form-label">
                  Estado/Provincia
                </label>
                <select
                  value={formData.state_origin}
                  onChange={handleChange}
                  className="form-select"
                  id="state_origin"
                  name="state_origin"
                  aria-describedby="state_origin"
                  placeholder="Selecciona estado"
                >
                  <option selected>Selecciona estado</option>
                  {cities.length !== 0 &&
                    cities.map((city) => (
                      <option key={city.id_state} value={city.id_state}>
                        {city.state_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="city_origin" className="form-label">
                  Ciudad
                </label>
                <input
                  type="text"
                  value={formData.city_origin}
                  onChange={handleChange}
                  className="form-control"
                  id="city_origin"
                  name="city_origin"
                  aria-describedby="city_origin"
                  placeholder="Ingresa ciudad"
                />
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="postalCode_origin" className="form-label">
                  Código Postal
                </label>
                <input
                  type="email"
                  value={formData.postalCode_origin}
                  onChange={handleChange}
                  className="form-control"
                  id="postalCode_origin"
                  name="postalCode_origin"
                  aria-describedby="postalCode_origin"
                  placeholder="Ingresa código postal"
                />
              </div>
              <div className="mb-3 col-md-12">
                <label htmlFor="address_origin" className="form-label">
                  Dirección
                </label>
                <input
                  type="tel"
                  value={formData.address_origin}
                  onChange={handleChange}
                  className="form-control"
                  id="address_origin"
                  name="address_origin"
                  aria-describedby="address_origin"
                  placeholder="Ingresa una dirección"
                />
              </div>
            </div>

            <section className="section-step">
              <h3>Dirección destino</h3>
              {isNewClient && (
                <>
                  <small> Dirección existente</small>
                  <div className="switch">
                    No
                    <SwitchInput
                      handleType={handleTypeDirectionDestination}
                      state={isNewDirectionDestination}
                    />
                    Si
                  </div>
                </>
              )}
              {!isNewDirectionDestination ? (
                <>
                  <label className="input-group-checkbox">
                    <input
                      type="checkbox"
                      id="mycheck"
                      checked={saveNewDirectionDestination}
                      onChange={handleCheckboxChangeDestination}
                      className="border border-secondary"
                    />
                    Guarde esta dirección para un futuro uso
                  </label>
                </>
              ) : (
                <DirectionSelect
                  value={formData}
                  setValue={setFormData}
                  type="destination"
                  client={clientID}
                />
              )}
            </section>
            <div className="section row m-0">
              <div className="mb-3 col-md-3">
                <label htmlFor="country_destination" className="form-label">
                  País
                </label>
                <select
                  value={formData.country_destination}
                  onChange={handleChangeCity}
                  className="form-select"
                  id="country_destination"
                  name="country_destination"
                  aria-describedby="country_destination"
                  placeholder="Selecciona país"
                >
                  <option selected>Selecciona país</option>
                  {countriesDestination.length !== 0 &&
                    countriesDestination.map((country) => (
                      <option
                        key={country.id_country}
                        value={country.id_country}
                      >
                        {country.country_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="state_destination" className="form-label">
                  Estado/Provincia
                </label>
                <select
                  value={formData.state_destination}
                  onChange={handleChange}
                  className="form-select"
                  id="state_destination"
                  name="state_destination"
                  aria-describedby="state_destination"
                  placeholder="Selecciona estado"
                >
                  <option selected>Selecciona estado</option>
                  {citiesDestination.length !== 0 &&
                    citiesDestination.map((city) => (
                      <option key={city.id_state} value={city.id_state}>
                        {city.state_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="city_destination" className="form-label">
                  Ciudad
                </label>
                <input
                  type="text"
                  value={formData.city_destination}
                  onChange={handleChange}
                  className="form-control"
                  id="city_destination"
                  name="city_destination"
                  aria-describedby="city_destination"
                  placeholder="Ingresa ciudad"
                />
              </div>
              <div className="mb-3 col-md-3">
                <label htmlFor="postalCode_destination" className="form-label">
                  Código Postal
                </label>
                <input
                  type="email"
                  value={formData.postalCode_destination}
                  onChange={handleChange}
                  className="form-control"
                  id="postalCode_destination"
                  name="postalCode_destination"
                  aria-describedby="postalCode_destination"
                  placeholder="Ingresa código postal"
                />
              </div>
              <div className="mb-3 col-md-12">
                <label htmlFor="address_destination" className="form-label">
                  Dirección
                </label>
                <input
                  type="tel"
                  value={formData.address_destination}
                  onChange={handleChange}
                  className="form-control"
                  id="address_destination"
                  name="address_destination"
                  aria-describedby="address_destination"
                  placeholder="Ingresa una dirección"
                />
              </div>
            </div>

            <div className="section-button">
              <a onClick={prevStep}>VOLVER</a>
              <button type="button" onClick={nextStep} className="btn-next">
                Siguiente
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="container-step">
            <small>Paso 3 de 3</small>
            <section className="section-step">
              <h3>Servicio</h3>
            </section>
            <div className="section row m-0">
              <div className="mb-3 col-md-4">
                <label htmlFor="date" className="form-label">
                  Fecha
                </label>
                <input
                  type="datetime-local"
                  value={formData.date}
                  onChange={handleChange}
                  className="form-control"
                  id="date"
                  name="date"
                  aria-describedby="date"
                  placeholder="Ingresa ciudad"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="service_status" className="form-label">
                  Estado
                </label>
                <select
                  value={formData.service_status}
                  onChange={handleChange}
                  className="form-select"
                  id="service_status"
                  name="service_status"
                  aria-describedby="service_status"
                  placeholder="Selecciona país"
                >
                  <option selected>Selecciona estado</option>
                  {orderStatus.length !== 0 &&
                    orderStatus.map((status) => (
                      <option key={status.id_status} value={status.id_status}>
                        {status.status_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="item" className="form-label">
                  Servicio
                </label>
                <input
                  type="text"
                  value={formData.item}
                  onChange={handleChange}
                  className="form-control"
                  id="item"
                  name="item"
                  aria-describedby="item"
                  placeholder="Tipo de servicio"
                />
              </div>
            </div>

            <section className="section-step">
              <h3>Embarque</h3>
            </section>
            <div className="section row m-0">
              <div className="mb-3 col-md-4">
                <label htmlFor="date_shipment" className="form-label">
                  Fecha arribo/recolección
                </label>
                <input
                  type="date"
                  value={formData.date_shipment}
                  onChange={handleChange}
                  className="form-control"
                  id="date_shipment"
                  name="date_shipment"
                  aria-describedby="date_shipment"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="Courier" className="form-label">
                  Carrier
                </label>
                <select
                  value={formData.Courier}
                  onChange={handleChange}
                  className="form-select"
                  id="Courier"
                  name="Courier"
                  aria-describedby="Courier"
                  placeholder="Ingresa carrier"
                >
                  <option selected>Selecciona carrier</option>
                  {carriers.length !== 0 &&
                    carriers.map((carrier) => (
                      <option key={carrier.id} value={carrier.name}>
                        {carrier.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="mb-3 col-md-4">
                <label htmlFor="bl_guia" className="form-label">
                  BL/Guía
                </label>
                <input
                  type="text"
                  value={formData.bl_guia}
                  onChange={handleChange}
                  className="form-control"
                  id="bl_guia"
                  name="bl_guia"
                  aria-describedby="bl_guia"
                  placeholder="Ingresa guía"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="etd" className="form-label">
                  ETD
                </label>
                <input
                  type="date"
                  value={formData.etd}
                  onChange={handleChange}
                  className="form-control"
                  id="etd"
                  name="etd"
                  aria-describedby="etd"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="eta" className="form-label">
                  ETA
                </label>
                <input
                  type="date"
                  value={formData.eta}
                  onChange={handleChange}
                  className="form-control"
                  id="eta"
                  name="eta"
                  aria-describedby="eta"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="proforma" className="form-label">
                  Proforma
                </label>
                <input
                  type="text"
                  value={formData.proforma}
                  onChange={handleChange}
                  className="form-control"
                  id="proforma"
                  name="proforma"
                  aria-describedby="proforma"
                  placeholder="Ingresa proforma"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="reference" className="form-label">
                  Referencia del cliente
                </label>
                <input
                  type="text"
                  value={formData.reference}
                  onChange={handleChange}
                  className="form-control"
                  id="reference"
                  name="reference"
                  aria-describedby="reference"
                  placeholder="Ingresa referencia"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="delivery_date" className="form-label">
                  Fecha de entrega
                </label>
                <input
                  type="date"
                  value={formData.delivery_date}
                  onChange={handleChange}
                  className="form-control"
                  id="delivery_date"
                  name="delivery_date"
                  aria-describedby="delivery_date"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="invoice" className="form-label">
                  Factura del cliente
                </label>
                <input
                  type="text"
                  value={formData.invoice}
                  onChange={handleChange}
                  className="form-control"
                  id="invoice"
                  name="invoice"
                  aria-describedby="invoice"
                  placeholder="Ingresa factura"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="cost" className="form-label">
                  Costo
                </label>
                <input
                  type="text"
                  value={formData.cost ? numberFormat(formData.cost) : ''}
                  onChange={handleChange}
                  className="form-control"
                  id="cost"
                  name="cost"
                  aria-describedby="cost"
                  placeholder="0.00"
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="sale_price" className="form-label">
                  Precio de Venta
                </label>
                <input
                  type="text"
                  value={formData.sale_price ? numberFormat(formData.sale_price) : ''}
                  onChange={handleChange}
                  className="form-control"
                  id="sale_price"
                  name="sale_price"
                  aria-describedby="sale_price"
                  placeholder="0.00"
                />
              </div>
            </div>

            <BoxOrder value={formData} setValue={setFormData} />

            <section className="section-step">
              <h3>Documentos</h3>
            </section>
            <div className="section row m-0">
              <div className="mb-3 col-md-6">
                <label htmlFor="date_shipment" className="form-label">
                  Facturas
                </label>
                <FileInput
                  value={formData}
                  setValue={setFormData}
                  name="invoice_photo"
                />
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="Courier" className="form-label">
                  Otros documentos
                </label>
                <FileInput
                  value={formData}
                  setValue={setFormData}
                  name="other_documents"
                />
              </div>
            </div>

            <div className="section-button">
              <a onClick={prevStep}>VOLVER</a>
              <button type="button" className="btn-next" onClick={handleSubmit}>
                Crear Orden
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="">
        {loader && <Spinner />}
        <CancelModal
          changeTap={changeTap}
          openModalCancel={openModalCancel}
          setOpenModalCancel={setOpenModalCancel}
        />
        <SaveModal
          fetchCountries={fetchOrders}
          changeTap={changeTap}
          openModalSave={openModalSave}
          setOpenModalSave={setOpenModalSave}
        />
        <form className="form-dashboard">{renderForm()}</form>
      </div>
    </>
  );
};

export default Create;
