import React from "react";
import Layout from "../../../../Components/Dashboard/Layout/Layout";
import { useState } from "react";

import { useEffect } from "react";

import { useNavigate, useParams } from "react-router";
import UpdateModal from "../../Modales/UpdateModal";
import { filesToBase64Array } from "../../../../utils/utils";
import authServiceInstance from "../../../../Services/authService";
import Spinner from "../../../../Components/loaders/Spinner";

import ImageUploader from "../../../../Components/Dashboard/Inputs/Image/ImageUploader";
import {
  getAdminById,
  updateAdmin,
  updateAdminstatus,
} from "../../../../Services/admin";

import "./clientes.css";
import {
  getClientById,
  updateClient,
} from "../../../../Services/clientsService";
import DeleteAddress from "../../Modales/DeleteAddress";

const EditClient = ({ toggleStatus }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [adminData, setAdminData] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    position: "",
    email: "",
    phone: "",
    cell: "",
    password: "",
    profile_picture_url: "",
    //company
    company: {
      companyName: "",
      companyRFC: "",
      companyFiscalAddress: "",
      companyEconomicActivity: "",
      companyTaxRegime: "",
      companyObservations: "",
    },
    addresses: [],
  });
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [cellError, setCellError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [positionError, setPositionError] = useState(null);
  const [companyNameError, setCompanyNameError] = useState(null);
  const [companyRFCError, setCompanyRFCError] = useState(null);
  const [companyFiscalAddressError, setCompanyFiscalAddressError] =
    useState(null);
  const [companyEconomicActivityError, setCompanyEconomicActivityError] =
    useState(null);
  const [companyTaxRegime, setCompanyTaxRegimeError] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);

  const { idclient } = useParams();
  const [data, setData] = useState();

  const token = authServiceInstance.getSessionToken();

  const [loader, setLoader] = useState(false);
  const [statusData, setStatusData] = useState({
    status: 0,
    commentary: "",
    flag: 0,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Verificar si el nombre contiene la palabra 'company'
    if (name.includes("company")) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        company: {
          ...prevFormData.company,
          [name]: value,
        },
      }));
    } else {
      // Actualizar en el nivel superior de formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const returnList = () => {
    navigate("/admin-itc/clientes");
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminsData = await getClientById(idclient, token);

        setImageProfile(adminsData.data?.user?.profile_picture_url);
        setAdminData(adminsData.data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          name: adminsData.data?.name,
          email: adminsData.data?.email,
          //status: adminsData.data?.user.status,
          password: "",
          last_name: adminsData.data?.last_name,
          position: adminsData.data?.position,
          phone: adminsData.data?.number_phone_office,
          cell: adminsData.data?.number_phone,
          //role_id:adminsData.data?.user.role_id,
          profile_picture_url: adminsData.data?.user?.profile_picture_url,
          company: {
            companyName: adminsData.data?.user?.companies?.[0]?.company_name,
            companyRFC: adminsData.data?.user?.companies?.[0]?.rfc,
            companyFiscalAddress:
              adminsData.data?.user?.companies?.[0]?.fiscal_address,
            companyEconomicActivity:
              adminsData.data?.user?.companies?.[0]?.economic_activity,
            companyTaxRegime: adminsData.data?.user?.companies?.[0]?.tax_regime,
            companyObservations:
              adminsData.data?.user?.companies?.[0]?.observations,
          },
          addresses: adminsData?.data?.user?.addresses,
        }));
      } catch (error) {
        console.error("Error fetching order by ID:", error.message);
        // Puedes manejar el error de alguna manera, por ejemplo, mostrar un mensaje al usuario
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (adminData && formData) {
      const fetchData = async () => {
        if (adminData && formData)
          try {
            // const documentsBase64 =
            //   formData?.profile_picture_url?.length > 0
            //     ? await new Promise((resolve) =>
            //         filesToBase64Array(formData.profile_picture_url, resolve)
            //       )
            //     : [];

            setData({
              name: formData.name || adminData.name,
              lastName: formData.last_name || adminData.lastName,
              email: formData.email || adminData.email,
              position: formData.position || adminData.position,
              phone: formData.phone || adminData.number_phone_office,
              cell: formData.cell || adminData.number_phone,
              //role_id:formData.role_id || adminData.role_id,
              profile_picture_url: formData.profile_picture_url || null,
              company: {
                companyName:
                  formData.company?.companyName ||
                  adminData.data?.user?.companies?.[0].company_name,
                companyRFC:
                  formData.company?.companyRFC ||
                  adminData.data?.user?.companies?.[0].rfc,
                companyFiscalAddress:
                  formData.company?.companyFiscalAddress ||
                  adminData.data?.user?.companies?.[0].fiscal_address,
                companyEconomicActivity:
                  formData.company?.companyEconomicActivity ||
                  adminData.data?.user?.companies?.[0].economic_activity,
                companyTaxRegime:
                  formData.company?.companyTaxRegime ||
                  adminData.data?.user?.companies?.[0].tax_regime,
                companyObservations:
                  formData.company?.companyObservations ||
                  adminData.data?.user?.companies?.[0].observations,
              },
              addresses: formData.addresses || adminData.user.addresses,
              ...(formData.password && {
                password: formData.password || adminData.password,
              }),
            });
          } catch (error) {
            console.error(
              "Ocurrió un error al convertir archivos a base64:",
              error
            );
          }
      };
      fetchData();
    }
  }, [adminData, formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{}[\]:;<>,.?/\\])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    if (formData?.name === "" || formData?.name?.length < 2) {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }
    if (formData.last_name === "" || formData?.last_name?.length < 2) {
      setLastNameError(true);
      return;
    } else {
      setLastNameError(false);
    }
    if (formData.position === "" || formData?.position?.length < 2) {
      setPositionError(true);
      return;
    } else {
      setPositionError(false);
    }
    if (!emailRegex.test(formData.email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }
    if (formData.phone === "" || formData?.phone?.length < 2) {
      setPhoneError(true);
      return;
    } else {
      setPhoneError(false);
    }
    if (formData.cell === "" || formData?.cell?.length < 2) {
      setCellError(true);
      return;
    } else {
      setCellError(false);
    }
    if (!passwordRegex.test(formData.password) && formData.password !== "") {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    if (
      formData.company.companyName === "" ||
      formData?.company.companyName?.length < 2
    ) {
      setCompanyNameError(true);
      return;
    } else {
      setCompanyNameError(false);
    }
    if (
      formData.company.companyRFC === "" ||
      formData?.company.companyRFC?.length < 2
    ) {
      setCompanyRFCError(true);
      return;
    } else {
      setCompanyRFCError(false);
    }
    if (
      formData.company.companyFiscalAddress === "" ||
      formData?.company.companyFiscalAddress?.length < 2
    ) {
      setCompanyFiscalAddressError(true);
      return;
    } else {
      setCompanyFiscalAddressError(false);
    }
    if (
      formData.company.companyEconomicActivity === "" ||
      formData?.company.companyEconomicActivity?.length < 2
    ) {
      setCompanyEconomicActivityError(true);
      return;
    } else {
      setCompanyEconomicActivityError(false);
    }
    if (
      formData.company.companyTaxRegime === "" ||
      formData?.company.companyTaxRegime?.length < 2
    ) {
      setCompanyTaxRegimeError(true);
      return;
    } else {
      setCompanyTaxRegimeError(false);
    }

    actualizarCliente();
  };

  const actualizarCliente = async () => {
    const nuevosDatosDeCliente = { ...data, profile_picture_url: imageProfile };

    try {
      setLoader(true);
      await updateClient(idclient, nuevosDatosDeCliente, token);

      setOpenModalUpdate(true);
    } catch (error) {
      console.error("Error al actualizar el cliente:", error.message);
    }
    setLoader(false);
  };
  const handleAddressChange = (index, fieldName, value) => {
    setFormData((prevFormData) => {
      const updatedAddresses = [...prevFormData.addresses];
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        [fieldName]: value,
      };
      return {
        ...prevFormData,
        addresses: updatedAddresses,
      };
    });
  };
  const handleDeleteAddress = (index) => {
    setIsLoading(true);
    setFormData((prevFormData) => {
      const updatedAddresses = [...prevFormData.addresses];
      updatedAddresses.splice(index, 1);
      return {
        ...prevFormData,
        addresses: updatedAddresses,
      };
    });
    setOpenModalDelete(false);
    setIsLoading(false);
  };
  const handleAddAddress = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      addresses: [
        {
          city: "",
          postal_code: "",
          address_name: "",
        },
        ...prevFormData.addresses,
      ],
    }));
  };

  const handleChangeImageProfile = (img) => {
    if (img) {
      setImageProfile(img);
    } else {
      setImageProfile(null);
    }
  };

  return (
    <>
      {loader && <Spinner />}
      <>
        <div
          className="container-report"
          style={{ backgroundColor: "#F4F6F8" }}
        >
          <div className={toggleStatus ? "orders " : "orders active"}>
            <Layout>
              <div className="container-step">
                <form className="p-0 m-0 w-100">
                  <section className="section-step">
                    <h3>Datos personales y de contacto</h3>
                  </section>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-5 d-flex row m-0 p-0">
                      <div className="col-md-4 col-lg-3">
                        <ImageUploader
                          setValor={handleChangeImageProfile}
                          dataImg={imageProfile}
                        />
                      </div>
                      <div className="col-md-8 col-lg-9 col-12">
                        <label htmlFor="name" className="form-label">
                          Nombre
                        </label>
                        <input
                          required
                          type="text"
                          value={formData.name}
                          onChange={handleChange}
                          className={`form-control ${
                            nameError ? "invalid-input" : ""
                          }`}
                          id="name"
                          name="name"
                          aria-describedby="name"
                          placeholder="Ingresa nombre"
                        />
                      </div>
                    </div>
                    <div className="mb-3 col-md-4 ">
                      <label htmlFor="last_name" className="form-label">
                        Apellido
                      </label>
                      <input
                        required
                        type="text"
                        value={formData.last_name}
                        onChange={handleChange}
                        className={`form-control ${
                          lastNameError ? "invalid-input" : ""
                        }`}
                        id="last_name"
                        name="last_name"
                        aria-describedby="last_name"
                        placeholder="Ingresa apellido"
                      />
                    </div>
                    <div className="mb-3 col-md-3 ">
                      <label htmlFor="position" className="form-label">
                        Puesto
                      </label>
                      <input
                        required
                        type="text"
                        value={formData.position}
                        onChange={handleChange}
                        className={`form-control ${
                          positionError ? "invalid-input" : ""
                        }`}
                        id="position"
                        name="position"
                        aria-describedby="position"
                        placeholder="puesto en la empresa"
                      />
                    </div>

                    <div className="mb-3 col-md-4">
                      <label htmlFor="email" className="form-label">
                        Correo electrónico
                      </label>
                      <input
                        required
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`form-control ${
                          emailError ? "invalid-input" : ""
                        }`}
                        id="email"
                        name="email"
                        aria-describedby="email"
                        placeholder="Ingresa correo"
                      />
                      {emailError && <small>Formato de correo inválido </small>}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="phone" className="form-label">
                        Tel. Oficina
                      </label>
                      <input
                        type="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className={`form-control ${
                          phoneError ? "invalid-input" : ""
                        }`}
                        id="phone"
                        name="phone"
                        aria-describedby="phone"
                        placeholder="Teléfono de oficina"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="cell" className="form-label">
                        Tel. Celular
                      </label>
                      <input
                        type="phone"
                        value={formData.cell}
                        onChange={handleChange}
                        className={`form-control ${
                          cellError ? "invalid-input" : ""
                        }`}
                        id="cell"
                        name="cell"
                        aria-describedby="cell"
                        placeholder="Teléfono de celular"
                      />
                    </div>
                  </div>

                  <section className="section-step">
                    <h3>Acceso</h3>
                  </section>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-6">
                      <label htmlFor="status" className="form-label">
                        Estado Usuario
                      </label>
                      <select
                        value={formData.status}
                        onChange={handleChange}
                        className="form-select"
                        id="status"
                        name="status"
                        aria-describedby="status"
                      >
                        <option value="1">Activo</option>
                        <option value="2">Suspendido</option>
                      </select>
                    </div>
                    <div className=" mb-3 col-md-6 form-group form-group-password password-admin">
                      <label htmlFor="password" className="form-label">
                        Asignar nueva contraseña
                      </label>
                      <input
                        required
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${
                          passwordError ? "invalid-input" : ""
                        }`}
                        id="password"
                        onChange={handleChange}
                        name="password"
                        value={formData.password}
                        placeholder="Contraseña"
                        maxLength={100}
                      />
                      {showPassword ? (
                        <i
                          className="fa-regular fa-eye-slash password-icon"
                          onClick={togglePasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye password-icon"
                          onClick={togglePasswordVisibility}
                        ></i>
                      )}
                      {passwordError && (
                        <small>
                          Formato inválido, mínimo 8 caracteres, una mayúscula,
                          un numero y un carácter especial
                        </small>
                      )}
                    </div>
                  </div>

                  <section className="section-step">
                    <h3>Compañía</h3>
                  </section>
                  <div className="section row m-0">
                    <div className="mb-3 col-md-4">
                      <label htmlFor="companyName" className="form-label">
                        Compañía
                      </label>
                      <input
                        type="text"
                        value={formData.company.companyName}
                        onChange={handleChange}
                        className={`form-control ${
                          companyNameError ? "invalid-input" : ""
                        }`}
                        id="companyName"
                        name="companyName"
                        aria-describedby="companyName"
                        placeholder="Ingresa nombre de la compañía"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="companyRFC" className="form-label">
                        RFC
                      </label>
                      <input
                        type="text"
                        value={formData.company.companyRFC}
                        onChange={handleChange}
                        className={`form-control ${
                          companyRFCError ? "invalid-input" : ""
                        }`}
                        id="companyRFC"
                        name="companyRFC"
                        aria-describedby="companyRFC"
                        placeholder="Ingresa RFC"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label
                        htmlFor="companyFiscalAddress"
                        className="form-label"
                      >
                        Dirección Fiscal
                      </label>
                      <input
                        type="text"
                        value={formData.company.companyFiscalAddress}
                        onChange={handleChange}
                        className={`form-control ${
                          companyFiscalAddressError ? "invalid-input" : ""
                        }`}
                        id="companyFiscalAddress"
                        name="companyFiscalAddress"
                        aria-describedby="companyFiscalAddress"
                        placeholder="Dirección"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label
                        htmlFor="companyEconomicActivity"
                        className="form-label"
                      >
                        Actividad económica
                      </label>
                      <input
                        type="text"
                        value={formData.company.companyEconomicActivity}
                        onChange={handleChange}
                        className={`form-control ${
                          companyEconomicActivityError ? "invalid-input" : ""
                        }`}
                        id="companyEconomicActivity"
                        name="companyEconomicActivity"
                        aria-describedby="companyEconomicActivity"
                        placeholder="Actividad"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="companyTaxRegime" className="form-label">
                        Régimen fiscal
                      </label>
                      <input
                        type="text"
                        value={formData.company.companyTaxRegime}
                        onChange={handleChange}
                        className={`form-control ${
                          companyTaxRegime ? "invalid-input" : ""
                        }`}
                        id="companyTaxRegime"
                        name="companyTaxRegime"
                        aria-describedby="companyTaxRegime"
                        placeholder="Régimen fiscal"
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label
                        htmlFor="companyObservations"
                        className="form-label"
                      >
                        Observaciones
                      </label>
                      <input
                        type="text"
                        value={formData.company.companyObservations}
                        onChange={handleChange}
                        className={`form-control `}
                        id="companyObservations"
                        name="companyObservations"
                        aria-describedby="companyObservations"
                        placeholder="Observaciones"
                      />
                    </div>
                  </div>

                  <section className="section-step">
                    <h3>Direcciones</h3>
                  </section>
                  <div className="btn-add" onClick={handleAddAddress}>
                    <span>Añadir dirección</span>
                  </div>
                  {formData.addresses.length > 0 ? (
                    formData.addresses?.map((address, index) => (
                      <div className="section row my-2" key={index}>
                        <div className="mb-3 col-md-4">
                          <label
                            htmlFor={`city-${index}`}
                            className="form-label"
                          >
                            Ciudad
                          </label>
                          <input
                            type="text"
                            value={address.city ?? ""}
                            onChange={(e) =>
                              handleAddressChange(index, "city", e.target.value)
                            }
                            className={`form-control ${
                              companyNameError ? "invalid-input" : ""
                            }`}
                            id={`city-${index}`}
                            name={`city-${index}`}
                            aria-describedby="city"
                            placeholder="Ingresa nombre de la ciudad"
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label
                            htmlFor={`postalCode-${index}`}
                            className="form-label"
                          >
                            Código Postal
                          </label>
                          <input
                            type="text"
                            value={address.postal_code ?? ""}
                            onChange={(e) =>
                              handleAddressChange(
                                index,
                                "postal_code",
                                e.target.value
                              )
                            }
                            className={`form-control ${
                              companyRFCError ? "invalid-input" : ""
                            }`}
                            id={`postalCode-${index}`}
                            name={`postalCode-${index}`}
                            aria-describedby="postalCode"
                            placeholder="Ingresa el código postal"
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label
                            htmlFor={`addressName-${index}`}
                            className="form-label"
                          >
                            Dirección
                          </label>
                          <input
                            type="text"
                            value={address.address_name ?? ""}
                            onChange={(e) =>
                              handleAddressChange(
                                index,
                                "address_name",
                                e.target.value
                              )
                            }
                            className={`form-control ${
                              companyFiscalAddressError ? "invalid-input" : ""
                            }`}
                            id={`addressName-${index}`}
                            name={`addressName-${index}`}
                            aria-describedby="addressName"
                            placeholder="Escribe tu dirección"
                          />
                        </div>
                        <div className="mb-3 col-md-2">
                          <button
                            type="button"
                            onClick={() => {
                              setOpenModalDelete(true);
                              setCurrentIndex(index);
                            }}
                            className="btn btn-danger"
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-muted text-sm section row my-2">
                      <span>
                        Todavía no se han agregado direcciones para este usuario
                      </span>
                    </div>
                  )}

                  <div className="section-button pb-2 gap-2">
                    <button
                      type="button"
                      className=" btn-cancelar ms-auto"
                      onClick={returnList}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="btn-next mr-1"
                      onClick={handleSubmit}
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              </div>
            </Layout>
          </div>
        </div>
        <DeleteAddress
          openModalDelete={openModalDelete}
          setOpenModalDelete={setOpenModalDelete}
          action={handleDeleteAddress}
          currentIndex={currentIndex}
          isLoading={isLoading}
        />
        <UpdateModal
          openModalUpdate={openModalUpdate}
          setOpenModalUpdate={setOpenModalUpdate}
          type={"client"}
          returnList={returnList}
        />
      </>
    </>
  );
};

export default EditClient;
