import authService from "../../Services/authService";
import * as types from "./types"; // Importar los tipos de acción

export const loginSuccess = (userData) => ({
  type: types.LOGIN_SUCCESS,
  payload: userData,
});

export const loginFailure = (error) => ({
  type: types.LOGIN_FAILURE,
  payload: error,
});

export const logoutSuccess = () => ({
  type: types.LOGOUT,
});

export const login = (credentials) => async (dispatch) => {
  try {
    const response = await authService.login(credentials);

    if (response.success) {
      dispatch(loginSuccess(response.userData));
      return response.success
    } else {
      dispatch(loginFailure(response.message));
    }
  } catch (error) {
    console.error("Error al procesar la solicitud:", error);
    dispatch(loginFailure(error));
  }
};

export const loginAdmin = (credentials, navigate) => async (dispatch) => {
  try {
    const response = await authService.loginAdmin(credentials);

    if (response.success) {
      dispatch(loginSuccess(response.userData));
      navigate("/admin-itc");
    } else {
      dispatch(loginFailure(response.message));
    }
  } catch (error) {
    console.error("Error al procesar la solicitud:", error);
    dispatch(loginFailure(error));
  }
};

export const logout = () => async (dispatch) => {
  try {
    const response = await authService.logout();
    if (response.success) {
      localStorage.removeItem("user");
      dispatch(logoutSuccess());
    } else {
      console.error("Error al cerrar sesión");
    }
  } catch (error) {
    console.error("Error al procesar la solicitud:", error);
  }
};

export const checkAuthentication = () => {
  return {
    type: types.CHECK_AUTHENTICATION,
  };
};
export const checkAuthenticationAdmin = () => {
  return {
    type: types.CHECK_AUTHENTICATION_ADMIN,
  };
};
