import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Dashboard/Layout/Layout";
import authServiceInstance from "../../../../Services/authService";
import List from "./List";
import Create from "./Create";

import { getListAdmin } from "../../../../Services/admin";

const Administracion = ({ toggleStatus }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [admin, setAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); 
  const [pageSize, setPageSize] = useState(10);
  const token = authServiceInstance.getSessionToken();

  const changeStatus = () => {
    setIsCreate((prev) => !prev);
  };
  const fetchAdminList = async () => {
    setIsLoading(true);
    try {
      const adminData = await getListAdmin(token, currentPage, pageSize);
      setAdmin(adminData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching countries:", error);
    }
  };

  useEffect(() => {
    fetchAdminList();
  }, [currentPage, pageSize]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); 
  };

  return (
    <div className="container-report" style={{ backgroundColor: "#F4F6F8" }}>
      <div className={toggleStatus ? "orders " : "orders active"}>
        <Layout>
          {!isCreate ? (
            <List
              {...{ changeStatus }}
              adminsData={admin}
              fetchAdminList={fetchAdminList}
              isLoading={isLoading}
              currentPage={currentPage}
              pageSize={pageSize}
              setPageSize = {setPageSize}
              setCurrentPage= {setCurrentPage}
              onPageChange={handlePageChange}
            />
          ) : (
            <Create changeTap={changeStatus} fetchAdminList={fetchAdminList} />
          )}
        </Layout>
      </div>
    </div>
  );
};

export default Administracion;
